/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/auth.context';

const PublicRoute = ({ element: Element }) => {
  const { authUser } = useAuthContext();

  if (!Element) return null;

  return !authUser.token ? <Element /> : <Navigate to="/" replace />;
};

export default PublicRoute;

PublicRoute.propTypes = {
  element: PropTypes.func.isRequired
};

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { shipperSignUpService } from '../../services/shipper.service';
import { ReactComponent as LeftArrowIcon } from '../../assets/images/left_arrow.svg';
import { useAuthContext } from '../../contexts/auth.context';
import { VENDORS } from '../../constants/carrier.constants';

const AddCustomerPage = () => {
  const navigate = useNavigate();
  const { authUser } = useAuthContext();

  const [customer, setCustomer] = useState(null);

  const validationSchema = yup.object({
    name: yup.string().required('Shipper name is required'),
    email: yup
      .string()
      .required('Email is required!')
      .email('Please enter a valid email.'),
    phone: yup
      .number()
      .typeError('Mobile number must be a number')
      .integer('Mobile number must be an integer')
      .positive('Mobile number must be a positive number')
      .required('Mobile number is required')
      .min(1000000000, 'Mobile number must be at least 10 digits')
      .max(9999999999, 'Mobile number can be at most 10 digits')
      .test('is-num', 'Mobile number must be numeric', (value) => {
        return /^[0-9]*$/.test(value.toString());
      }),
    company: yup.object({
      name: yup.string().required('Company name is required'),
      address: yup.object({
        address1: yup.string().required('Address Line 1 is required'),
        address2: yup.string(),
        city: yup.string().required('City is required'),
        state: yup.string().required('State is required'),
        country: yup.string().required('Country is required').default('US'),
        zipcode: yup.string().required('Zip code is required')
      }),
      commission: yup.number().default(18),
      minCommission: yup.number().default(0),
      maxCommission: yup.number().default(18),
      ltlFreight: yup
        .number()
        .positive()
        .min(1)
        .max(100)
        .required('Commission is required.')
        .default(18),
      minLTLFreight: yup
        .number()
        .positive()
        .required('Commission is required.')
        .default(45),
      maxLTLFreight: yup
        .number()
        .positive()
        .required('Commission is required.')
        .default(500),
      blanketWrap: yup
        .number()
        .positive()
        .min(1)
        .max(100)
        .required('Commission is required.')
        .default(18),
      minBlanketWrap: yup
        .number()
        .positive()
        .required('Commission is required.')
        .default(45),
      maxBlanketWrap: yup
        .number()
        .positive()
        .required('Commission is required.')
        .default(500),
      truckLoadFlat: yup
        .number()
        .positive()
        .min(1)
        .max(100)
        .required('Commission is required.')
        .default(18),
      minTruckloadFlat: yup
        .number()
        .positive()
        .required('Commission is required.')
        .default(100),
      maxTruckloadFlat: yup
        .number()
        .positive()
        .required('Commission is required.')
        .default(500),
      truckLoadVan: yup
        .number()
        .positive()
        .min(1)
        .max(100)
        .required('Commission is required.')
        .default(18),
      minTruckloadVan: yup
        .number()
        .positive()
        .required('Commission is required.')
        .default(100),
      maxTruckloadVan: yup
        .number()
        .positive()
        .required('Commission is required.')
        .default(500),
      partialFlat: yup
        .number()
        .positive()
        .min(1)
        .max(100)
        .required('Commission is required.')
        .default(18),
      minPartialFlat: yup
        .number()
        .positive()
        .required('Commission is required.')
        .default(100),
      maxPartialFlat: yup
        .number()
        .positive()
        .required('Commission is required.')
        .default(500),
      partialVan: yup
        .number()
        .positive()
        .min(1)
        .max(100)
        .required('Commission is required.')
        .default(18),
      minPartialVan: yup
        .number()
        .positive()
        .required('Commission is required.')
        .default(100),
      maxPartialVan: yup
        .number()
        .positive()
        .required('Commission is required.')
        .default(500)
    }),
    newQuoteNotify: yup.bool().default(true),
    shipmentBookNotify: yup.bool().default(true),
    shipmentDeliverNotify: yup.bool().default(true),
    shipperNewQuoteNotify: yup.bool().default(true),
    shipperShipmentBookNotify: yup.bool().default(true),
    shipperShipmentDeliverNotify: yup.bool().default(true)
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: validationSchema.default()
  });

  const onBackArrowClick = () => {
    navigate(-1);
  };

  function carrierChangeHandler(e, carrier) {
    let carrierList = customer?.disabledCarrier || [];
    if (e.target.checked) {
      carrierList = carrierList.filter((car) => car !== carrier);
    } else {
      carrierList.push(carrier);
    }
    setCustomer({ ...customer, disabledCarrier: carrierList });
  }
  const onSubmitForm = async (data) => {
    try {
      if (isValid) {
        const response = await shipperSignUpService(
          {
            ...data,
            referralCode: authUser?.user?.referralCode,
            version: process.env.REACT_APP_APP_VERSION,
            usertype: authUser?.user?.usertype,
            salesRepId:
              authUser?.user?.usertype === 'salesRep'
                ? authUser?.user?.id
                : authUser?.user?.salesRepId,
            salesRepEmailPreferences: {
              newQuoteNotify: data?.newQuoteNotify,
              shipmentBookNotify: data?.shipmentBookNotify,
              shipmentDeliverNotify: data?.shipmentDeliverNotify
            },
            userEmailPreferences: {
              newQuoteNotify: data?.shipperNewQuoteNotify,
              shipmentBookNotify: data?.shipperShipmentBookNotify,
              shipmentDeliverNotify: data?.shipperShipmentDeliverNotify
            },
            disabledCarrier: customer?.disabledCarrier
          },
          true,
          true
        );

        if (response.status === 200) {
          toast.success(response?.data?.message);
          navigate('/customers');
        }
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  return (
    <section>
      <div className="py-20 bg-gray-50 radius-for-skewed">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full px-3 mb-8">
              <div className="relative text-navy-500 pl-3 mb-5">
                <div
                  tabIndex="-1"
                  role="button"
                  onKeyPress={() => {}}
                  onClick={onBackArrowClick}
                  className="inline-flex items-center"
                >
                  <LeftArrowIcon className="w-6 h-6" />
                  <p className="ml-3 text-xl">Back to the list</p>
                </div>
                <h3 className="text-3xl font-bold text-left">
                  Customer Details
                </h3>
              </div>

              <form onSubmit={handleSubmit(onSubmitForm)}>
                <div className="w-full px-3 pb-6 mb-6 md:w-1/2 md:pr-12">
                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Company Name
                    </label>
                    <input
                      {...register('company.name')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="text"
                    />
                    {errors.company && (
                      <span className="text-red-700 text-md">
                        {errors.company.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Address line 1
                    </label>
                    <input
                      {...register('company.address.address1')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="text"
                    />
                    {errors.address?.address1 && (
                      <span className="text-red-700 text-md">
                        {errors.address.address1.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Address line 2
                    </label>
                    <input
                      {...register('company.address.address2')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="text"
                    />
                    {errors.address?.address2 && (
                      <span className="text-red-700 text-md">
                        {errors.address.address2.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">City</label>
                    <input
                      {...register('company.address.city')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="text"
                    />
                    {errors.address?.city && (
                      <span className="text-red-700 text-md">
                        {errors.address.city.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide" htmlFor="state">
                      State
                    </label>
                    <input
                      {...register('company.address.state')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="text"
                    />
                    {errors.pickup_address?.state && (
                      <span className="text-red-700 text-md">
                        {errors.address.state.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">Zip code</label>
                    <input
                      {...register('company.address.zipcode')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="text"
                    />
                    {errors.address?.zipcode && (
                      <span className="text-red-700 text-md">
                        {errors.address.zipcode.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">Name</label>
                    <input
                      {...register('name')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="text"
                    />
                    {errors.name && (
                      <span className="text-red-700 text-md">
                        {errors.name.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">Email</label>
                    <input
                      {...register('email')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="email"
                    />
                    {errors.email && (
                      <span className="text-red-700 text-md">
                        {errors.email.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Phone Number
                    </label>
                    <input
                      {...register('phone')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="text"
                    />
                    {errors.phone && (
                      <span className="text-red-700 text-md">
                        {errors.phone.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Minimum LTL Freight Commission: ($)
                    </label>
                    <input
                      {...register('company.minLTLFreight')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                    />
                    {errors.minLTLFreight && (
                      <span className="text-red-700 text-md">
                        {errors.minLTLFreight.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Maximum LTL Freight Commission: ($)
                    </label>
                    <input
                      {...register('company.maxLTLFreight')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                    />
                    {errors.maxLTLFreight && (
                      <span className="text-red-700 text-md">
                        {errors.maxLTLFreight.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      LTL Freight Commission: (%)
                    </label>
                    <input
                      {...register('company.ltlFreight')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                      max="100"
                    />
                    {errors.ltlFreight && (
                      <span className="text-red-700 text-md">
                        {errors.ltlFreight.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Minimum Blanket Wrap Commission: ($)
                    </label>
                    <input
                      {...register('company.minBlanketWrap')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                    />
                    {errors.minBlanketWrap && (
                      <span className="text-red-700 text-md">
                        {errors.minBlanketWrap.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Maximum Blanket Wrap Commission: ($)
                    </label>
                    <input
                      {...register('company.maxBlanketWrap')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                    />
                    {errors.maxBlanketWrap && (
                      <span className="text-red-700 text-md">
                        {errors.maxBlanketWrap.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Blanket Wrap Commission: (%)
                    </label>
                    <input
                      {...register('company.blanketWrap')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                      max="100"
                    />
                    {errors.blanketWrap && (
                      <span className="text-red-700 text-md">
                        {errors.blanketWrap.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Minimum Truckload - Flatbed Commission: ($)
                    </label>
                    <input
                      {...register('company.minTruckloadFlat')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                    />
                    {errors.minTruckloadFlat && (
                      <span className="text-red-700 text-md">
                        {errors.minTruckloadFlat.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Maximum Truckload - Flatbed Commission: ($)
                    </label>
                    <input
                      {...register('company.maxTruckloadFlat')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                    />
                    {errors.maxTruckloadFlat && (
                      <span className="text-red-700 text-md">
                        {errors.maxTruckloadFlat.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Truckload - Flatbed Commission: (%)
                    </label>
                    <input
                      {...register('company.truckLoadFlat')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                      max="100"
                    />
                    {errors.truckLoadFlat && (
                      <span className="text-red-700 text-md">
                        {errors.truckLoadFlat.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Minimum Truckload - Van Commission: ($)
                    </label>
                    <input
                      {...register('company.minTruckloadVan')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                    />
                    {errors.minTruckloadVan && (
                      <span className="text-red-700 text-md">
                        {errors.minTruckloadVan.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Maximum Truckload - Van Commission: ($)
                    </label>
                    <input
                      {...register('company.maxTruckloadVan')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                    />
                    {errors.maxTruckloadVan && (
                      <span className="text-red-700 text-md">
                        {errors.maxTruckloadVan.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Truckload - Van Commission: (%)
                    </label>
                    <input
                      {...register('company.truckLoadVan')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                      max="100"
                    />
                    {errors.truckLoadVan && (
                      <span className="text-red-700 text-md">
                        {errors.truckLoadVan.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Minimum Partial Direct Flatbed Commission: ($)
                    </label>
                    <input
                      {...register('company.minPartialFlat')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                    />
                    {errors.minPartialFlat && (
                      <span className="text-red-700 text-md">
                        {errors.minPartialFlat.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Maximum Partial Direct Flatbed Commission: ($)
                    </label>
                    <input
                      {...register('company.maxPartialFlat')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                    />
                    {errors.maxPartialFlat && (
                      <span className="text-red-700 text-md">
                        {errors.maxPartialFlat.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Partial Direct Flatbed Commission: (%)
                    </label>
                    <input
                      {...register('company.partialFlat')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                      max="100"
                    />
                    {errors.partialFlat && (
                      <span className="text-red-700 text-md">
                        {errors.partialFlat.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Minimum Partial Direct Van Commission: ($)
                    </label>
                    <input
                      {...register('company.minPartialVan')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                    />
                    {errors.minPartialVan && (
                      <span className="text-red-700 text-md">
                        {errors.minPartialVan.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Maximum Partial Direct Van Commission: ($)
                    </label>
                    <input
                      {...register('company.maxPartialVan')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                    />
                    {errors.maxPartialVan && (
                      <span className="text-red-700 text-md">
                        {errors.maxPartialVan.message}
                      </span>
                    )}
                  </div>

                  <div className="w-full md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      Partial Direct Van Commission: (%)
                    </label>
                    <input
                      {...register('company.partialVan')}
                      className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      min="0"
                      max="100"
                    />
                    {errors.partialVan && (
                      <span className="text-red-700 text-md">
                        {errors.partialVan.message}
                      </span>
                    )}
                  </div>

                  <div className="flex flex-col my-8">
                    <div className="font-bold text-2xl text-navy-500 underline">
                      Email Preferences
                    </div>

                    <div className="flex flex-col">
                      <div className="mt-4 w-full">
                        <div className="font-bold text-lg mb-4 text-navy-500">
                          SalesRep
                        </div>
                        <div className="w-full mt-4 pb-4">
                          <label className="block mb-2 tracking-wide">
                            <input
                              type="checkbox"
                              {...register('newQuoteNotify')}
                              className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                            />
                            <span className="ml-1">
                              Receive new quote emails
                            </span>
                          </label>
                          {errors.newQuoteNotify && (
                            <span className="text-red-700 text-md">
                              {errors.newQuoteNotify.message}
                            </span>
                          )}
                        </div>
                        <div className="w-full pb-4">
                          <label className="block mb-2 tracking-wide">
                            <input
                              type="checkbox"
                              {...register('shipmentBookNotify')}
                              className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                            />
                            <span className="ml-1">
                              Receive shipment book emails
                            </span>
                          </label>
                          {errors.shipmentBookNotify && (
                            <span className="text-red-700 text-md">
                              {errors.shipmentBookNotify.message}
                            </span>
                          )}
                        </div>
                        <div className="w-full pb-4">
                          <label className="block mb-2 tracking-wide">
                            <input
                              type="checkbox"
                              {...register('shipmentDeliverNotify')}
                              className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                            />
                            <span className="ml-1">
                              Receive shipment delivery emails
                            </span>
                          </label>
                          {errors.shipmentDeliverNotify && (
                            <span className="text-red-700 text-md">
                              {errors.shipmentDeliverNotify.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="mt-4 w-full">
                        <div className="font-bold text-navy-500 text-lg">
                          Shipper
                        </div>
                        <div className="w-full mt-4 pb-4">
                          <label className="block mb-2 tracking-wide">
                            <input
                              type="checkbox"
                              {...register('shipperNewQuoteNotify')}
                              className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                            />
                            <span className="ml-1">
                              Receive new quote emails
                            </span>
                          </label>
                          {errors.shipperNewQuoteNotify && (
                            <span className="text-red-700 text-md">
                              {errors.shipperNewQuoteNotify.message}
                            </span>
                          )}
                        </div>
                        <div className="w-full pb-4">
                          <label className="block mb-2 tracking-wide">
                            <input
                              type="checkbox"
                              {...register('shipperShipmentBookNotify')}
                              className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                            />
                            <span className="ml-1">
                              Receive shipment book emails
                            </span>
                          </label>
                          {errors.shipperShipmentBookNotify && (
                            <span className="text-red-700 text-md">
                              {errors.shipperShipmentBookNotify.message}
                            </span>
                          )}
                        </div>
                        <div className="w-full pb-8">
                          <label className="block mb-2 tracking-wide">
                            <input
                              type="checkbox"
                              {...register('shipperShipmentDeliverNotify')}
                              className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                            />
                            <span className="ml-1">
                              Receive shipment delivery emails
                            </span>
                          </label>
                          {errors.shipperShipmentDeliverNotify && (
                            <span className="text-red-700 text-md">
                              {errors.shipperShipmentDeliverNotify.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full pb-8 md:w-9/12">
                    <h3 className="mb-4 font-bold">Select LTL carriers</h3>
                    {VENDORS.map((carrier) => (
                      <label className="block mb-2 tracking-wide">
                        <input
                          type="checkbox"
                          value={carrier.name}
                          onChange={(e) =>
                            carrierChangeHandler(e, carrier.name)
                          }
                          defaultChecked={
                            !customer?.disabledCarrier?.includes(carrier.name)
                          }
                          className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                        />
                        <span className="ml-1">
                          {`${carrier.display_name} (${carrier.service})`}
                        </span>
                      </label>
                    ))}

                    {errors.notificationEnabled && (
                      <span className="text-red-700 text-md">
                        {errors.notificationEnabled.message}
                      </span>
                    )}
                  </div>
                  <div className="w-full text-left md:w-9/12 ">
                    <button
                      type="submit"
                      className="relative inline-block w-full py-4 pl-4 mb-2 mr-12 text-xl font-bold text-left text-white transition duration-75 border bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700"
                    >
                      Add Customer
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddCustomerPage;

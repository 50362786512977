import React from 'react';
import toast, { Toaster, ToastBar } from 'react-hot-toast';

const Toast = () => {
  return (
    <Toaster position="top-right" toastOptions={{ duration: 7000 }}>
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== 'loading' && (
                <button
                  type="button"
                  className="text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={() => toast.dismiss(t.id)}
                >
                  Dismiss
                </button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default Toast;

import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { ReactComponent as RightArrow } from '../../assets/images/right_arrow.svg';
import useGenerateInvoice from '../../customHooks/useGenerateInvoice';

const GenerateInvoiceButton = ({
  quoteId,
  fetchQuoteDetails,
  fetchOpenInvoices,
  styling
}) => {
  const handleGenerateInvoice = useGenerateInvoice();
  const handleOnClick = async (e) => {
    try {
      e.preventDefault();
      await handleGenerateInvoice([quoteId]);
      if (fetchQuoteDetails) {
        fetchQuoteDetails(quoteId);
      }
      if (fetchOpenInvoices) {
        fetchOpenInvoices(true, 1);
      }
    } catch (error) {
      console.log({ error });
      toast.error(error.message ?? 'Something went wrong!');
    }
  };
  return (
    <button type="button" onClick={handleOnClick} className={styling}>
      Generate Invoice
      {fetchQuoteDetails && <RightArrow className="w-5 h-5 font-bold" />}
    </button>
  );
};

export default GenerateInvoiceButton;

GenerateInvoiceButton.propTypes = {
  quoteId: PropTypes.string.isRequired,
  fetchQuoteDetails: PropTypes.func,
  fetchOpenInvoices: PropTypes.func,
  styling: PropTypes.string
};

GenerateInvoiceButton.defaultProps = {
  fetchQuoteDetails: null,
  fetchOpenInvoices: null,
  styling: ''
};

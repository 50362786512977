import { useIsInvoiceAuthorizedUser } from '../invoice.constants';

function useGetCustomerRepSidebarRoutes() {
  const isInvoiceAuthorizedUser = useIsInvoiceAuthorizedUser();
  const customerRepSidebarRoutes = [
    {
      route: '/dashboard',
      label: 'Dashboard'
    },
    {
      route: '/customers',
      label: 'Associated Customers'
    },
    {
      route: '/salesRep/carriers',
      label: 'Carriers'
    },
    {
      route: '/new-quote',
      label: 'New Quote'
    },
    {
      route: '/salesRep/recentQuotes',
      label: 'Recent Quotes'
    },
    {
      route: '/salesRep/pending-quotes',
      label: 'Pending'
    },
    {
      route: '/salesRep/active-shipment',
      label: 'Assigned'
    },
    {
      route: '/salesRep/enroute-quotes',
      label: 'Enroute'
    },
    {
      route: '/salesRep/completed-quotes',
      label: 'Delivered'
    }
  ];

  if (isInvoiceAuthorizedUser) {
    customerRepSidebarRoutes.push({
      route: '/salesRep/open-invoices',
      label: 'Invoice'
    });
  }
  return customerRepSidebarRoutes;
}

export default useGetCustomerRepSidebarRoutes;

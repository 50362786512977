import React from 'react';
import PropTypes from 'prop-types';

import { calculateDensity, toCamelCase } from '../../utils/quote.util';

import CargoDefault from '../../assets/images/cargo_default.png';

const CargoDetailsCard = ({ cargo, shipFrom, shipTo }) => {
  return (
    <div className="p-4 flex flex-wrap bg-gray-100">
      <div>
        <span className="mb-4 lg:mb-0 mr-6 inline-block p-1 md:p-1 ">
          {cargo?.files?.fd ? (
            <img
              className="object-contain h-20 w-20"
              src={`${process.env.REACT_APP_API_URL}/${cargo.files.fd}`}
              alt="cargo img"
              onError={(e) => {
                e.target.src = CargoDefault;
              }}
            />
          ) : (
            <img
              className="object-contain h-20 w-20"
              src={CargoDefault}
              alt="cargo img"
              onError={(e) => {
                e.target.src = CargoDefault;
              }}
            />
          )}
        </span>
      </div>

      <div className="w-full lg:w-2/3">
        <h3 className="mb-2 text-2xl font-bold font-heading">
          {cargo?.quantity} -{' '}
          {toCamelCase(cargo?.package_type[0]?.display_name)}
        </h3>
        <p className="text-gray-500">
          {cargo?.length} in x {cargo?.width} in x {cargo?.height} in x{' '}
          {cargo?.weight}lbs
        </p>
        {cargo.length && cargo.height && cargo.width ? (
          <p className="text-gray-500 space-x-2 mt-2">
            <span>
              Density: {calculateDensity(cargo)?.density} lb/ft<sup>3</sup>
            </span>
            <span>|</span>
            <span>
              Cubic FT: {calculateDensity(cargo)?.cubitFeet} ft<sup>3</sup>
            </span>
          </p>
        ) : null}

        {shipFrom ? (
          <>
            {' '}
            <p className="text-gray-500 space-x-2 mt-2">
              <span>Ship From: {shipFrom?.pickup_zip_code ?? shipFrom}</span>
              <span>|</span>
              <span>Ship To: {shipTo?.delivery_zip_code ?? shipTo}</span>
            </p>
          </>
        ) : null}
        {cargo?.tarps ? (
          <p className="text-md mt-1 font-bold text-gray-600 ">
            <span />
            Note: Tarps required for this cargo.
          </p>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default CargoDetailsCard;

CargoDetailsCard.defaultProps = {
  shipFrom: null,
  shipTo: null
};

CargoDetailsCard.propTypes = {
  cargo: PropTypes.object.isRequired,
  shipFrom: PropTypes.object,
  shipTo: PropTypes.object
};

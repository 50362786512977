import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { ReactComponent as LeftArrowIcon } from '../../assets/images/left_arrow.svg';
import { carrierInvitationService } from '../../services/salesrep.service';
import {
  refreshMCPDataService,
  updateCarrierService,
  getCarrierMCPDataService
} from '../../services/carrier.service';

const CarrierDetailsPage = () => {
  const navigate = useNavigate();
  const { type, carrierId } = useParams();
  const inputClass =
    'appearance-none block border border-navy-400 py-2 px-1 leading-tight focus:outline-none';
  const [registeredCarrierId, setRegisteredCarrierId] = useState(0);
  const [accord, setAccord] = useState('');
  const [isRegistered, setIsRegistered] = useState(false);
  const [mcpData, setMCPData] = useState({});
  const [carrierEmail, setCarrierEmail] = useState('');
  const {
    register,
    getValues,
    // control,
    // watch,
    // setValue,
    // formState: { errors },
    reset,
    handleSubmit
  } = useForm({});

  const onBackArrowClick = () => {
    navigate(-1);
  };

  const accordChange = (current) => {
    if (current === accord) return setAccord('');
    setAccord(current);
  };

  const onCarrierSendInvite = async () => {
    try {
      const response = await carrierInvitationService({
        type: 'dot',
        number: mcpData.dot,
        usertype: 'carrier',
        email: getValues('email')
      });

      return toast.success(response?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const entityTypeStr = () => {
    const str = [];
    if (mcpData?.entityType?.entityCarrier === 'Yes') str.push('Carrier');
    if (mcpData?.entityType?.entityShipper === 'Yes') str.push('Shipper');
    if (mcpData?.entityType?.entityBroker === 'Yes') str.push('Broker');
    return str.join(', ');
  };

  const onSubmitForm = async (data) => {
    try {
      const response = await updateCarrierService(registeredCarrierId, {
        mcpData: data
      });
      setMCPData(response?.data?.mcpData);
      toast.success('Changes updated successfully');
      navigate(-1);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const onRefreshData = async () => {
    try {
      const response = await refreshMCPDataService(registeredCarrierId);
      setMCPData(response?.data?.data?.mcpData);
      reset({
        ...response?.data?.data?.mcpData
      });
      toast.success('Data refreshed successfully!');
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const loadInitialMCPData = async () => {
    try {
      const response = await getCarrierMCPDataService({
        type,
        number: carrierId
      });
      if (response.status === 200) {
        if (response?.data?.data?.id) {
          setRegisteredCarrierId(response?.data?.data?.id);
          setIsRegistered(true);
          console.log(response?.data?.data?.email);
        }
        const regEmail =
          !isRegistered && response?.data?.data?.mcpData?.email !== ''
            ? response?.data?.data?.mcpData?.email
            : response?.data?.data?.email;
        setCarrierEmail(regEmail);
        setMCPData(response?.data?.data?.mcpData);
      }
    } catch (error) {
      navigate('/salesRep/carriers');
      return toast.error(
        error?.response?.data.message ?? 'Something went wrong!'
      );
    }
  };

  // Considered that the system is always sending DOT number in the URL
  useEffect(() => {
    loadInitialMCPData();
  }, [carrierId]);

  return (
    <section>
      <div className="py-20 bg-gray-50 radius-for-skewed">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full px-3 mb-8">
              <div className="relative mb-6 text-navy-500">
                <div
                  tabIndex="-1"
                  role="button"
                  onKeyPress={() => {}}
                  onClick={onBackArrowClick}
                  className="inline-flex items-center"
                >
                  <LeftArrowIcon className="left-0 flex items-center w-6 h-6 " />
                  <p className="ml-4">Back to the list</p>
                </div>
                <h3 className="mb-4 text-3xl font-bold text-left">
                  Carrier Details
                </h3>
              </div>
              <div className="bg-white border border-gray-300">
                <div className="m-4 ml-6">
                  <div>
                    <form
                      onSubmit={handleSubmit(onSubmitForm)}
                      className="flex flex-col items-center justify-center"
                    >
                      <dl className="grid grid-cols-4 break-words gap-y-4">
                        <dt className="font-bold">Risk Assesment Status</dt>
                        <dd>
                          <span
                            className={`${
                              ['Acceptable', 'Moderate'].includes(
                                mcpData?.riskAssesment
                              )
                                ? 'text-green-600'
                                : 'text-red-500'
                            } font-bold`}
                          >
                            {mcpData?.riskAssesment
                              ?.replace('UnacceptableFail', 'Unacceptable')
                              ?.replace(
                                'UnacceptableReview',
                                'Review Required'
                              )}
                          </span>
                        </dd>
                        <dt className="font-bold">Operating Status</dt>
                        <dd>
                          <span
                            className={`${
                              mcpData?.operatingStatus ===
                              'AUTHORIZED FOR Property'
                                ? 'text-green-600'
                                : 'text-red-500'
                            } font-bold`}
                          >
                            {mcpData?.operatingStatus}
                          </span>
                        </dd>
                        <dt className="font-bold">Packet Status</dt>
                        <dd
                          className={`${
                            mcpData?.carrierPacketStatus === 'Complete'
                              ? 'text-green-600'
                              : 'text-red-500'
                          } font-bold`}
                        >
                          {mcpData?.carrierPacketStatus || 'Incomplete'}
                        </dd>
                        <dt className="font-bold">Entity Type</dt>
                        <dd>{entityTypeStr()}</dd>
                        <dt className="font-bold">DOT Status</dt>
                        <dd
                          className={`font-bold ${
                            mcpData?.dotStatus?.toLowerCase() === 'active'
                              ? 'text-green-600'
                              : ''
                          }  `}
                        >
                          {mcpData?.dotStatus}
                        </dd>
                        <dt className="font-bold">Active Since</dt>
                        <dd>{mcpData?.activeSince}</dd>
                        <dt className="font-bold">DOT Number</dt>
                        <dd>{mcpData?.dot}</dd>
                        <dt className="font-bold">Docket Number</dt>
                        {isRegistered ? (
                          <input
                            type="text"
                            className={`${inputClass}`}
                            {...register('mc', { value: mcpData?.mc })}
                          />
                        ) : (
                          <dd>{mcpData?.mc}</dd>
                        )}

                        <dt className="font-bold ">Legal Name</dt>
                        {isRegistered ? (
                          <input
                            type="text"
                            className={`${inputClass} w-2/3`}
                            {...register('companyName', {
                              value: mcpData?.companyName
                            })}
                          />
                        ) : (
                          <dd>{mcpData?.companyName}</dd>
                        )}
                        <dt className="font-bold">DBA Name</dt>
                        {isRegistered ? (
                          <input
                            type="text"
                            className={`${inputClass}  max-h-10`}
                            {...register('DBAName', {
                              value: mcpData?.DBAName
                            })}
                          />
                        ) : (
                          <dd>{mcpData?.DBAName}</dd>
                        )}
                        <dt className="font-bold">
                          SCAC (Standard Carrier Alpha Code)
                        </dt>
                        {isRegistered ? (
                          <input
                            type="text"
                            className={`${inputClass} w-2/3`}
                            {...register('scas', { value: mcpData?.scas })}
                          />
                        ) : (
                          <dd>{mcpData?.scas}</dd>
                        )}
                        <dt className="font-bold">Contact</dt>
                        {isRegistered ? (
                          <input
                            type="text"
                            className={`${inputClass}`}
                            {...register('contact', {
                              value: mcpData?.contact
                            })}
                            disabled={!isRegistered}
                          />
                        ) : (
                          <dd>{mcpData?.contact}</dd>
                        )}

                        <dt className="font-bold">Main Phone Number</dt>
                        {isRegistered ? (
                          <input
                            type="text"
                            className={`${inputClass} w-2/3`}
                            {...register('phone', { value: mcpData?.phone })}
                            disabled={!isRegistered}
                          />
                        ) : (
                          <dd>{mcpData?.phone}</dd>
                        )}

                        <dt className="font-bold">Cell Phone Number</dt>
                        {isRegistered ? (
                          <input
                            type="text"
                            className={`${inputClass}`}
                            {...register('cellPhone', {
                              value: mcpData?.cellPhone
                            })}
                            disabled={!isRegistered}
                          />
                        ) : (
                          <dd>{mcpData?.cellPhone}</dd>
                        )}
                        <dt className="font-bold">Emergency Number</dt>
                        {isRegistered ? (
                          <input
                            type="text"
                            className={`${inputClass} w-2/3`}
                            {...register('emergency', {
                              value: mcpData?.emergency
                            })}
                            disabled={!isRegistered}
                          />
                        ) : (
                          <dd>{mcpData?.emergency}</dd>
                        )}

                        <dt className="font-bold">Fax Number</dt>
                        {isRegistered ? (
                          <input
                            type="text"
                            className={`${inputClass}`}
                            {...register('fax', { value: mcpData?.fax })}
                            disabled={!isRegistered}
                          />
                        ) : (
                          <dd>{mcpData?.fax}</dd>
                        )}

                        <dt className="font-bold">Company Main Email</dt>

                        {isRegistered ? (
                          <input
                            type="email"
                            className={`${inputClass} w-2/3`}
                            {...register('email', { value: carrierEmail })}
                            disabled={!isRegistered}
                          />
                        ) : (
                          <dd>{carrierEmail}</dd>
                        )}

                        <dt className="font-bold">Website</dt>
                        {isRegistered ? (
                          <input
                            type="text"
                            className={`${inputClass} max-h-10`}
                            {...register('website', {
                              value: mcpData?.website
                            })}
                            disabled={!isRegistered}
                          />
                        ) : (
                          <dd>{mcpData?.website}</dd>
                        )}
                        <dt className="font-bold">Address</dt>
                        <dd className="mr-4">
                          {isRegistered ? (
                            <>
                              <p className="grid grid-cols-2 gap-2">
                                <input
                                  type="text"
                                  className={`${inputClass}`}
                                  {...register('address.address1', {
                                    value: mcpData?.address?.address1
                                  })}
                                  disabled={!isRegistered}
                                />
                                <input
                                  type="text"
                                  className={`${inputClass}`}
                                  {...register('address.address2', {
                                    value: mcpData?.address?.address2
                                  })}
                                  disabled={!isRegistered}
                                />
                              </p>
                              <p className="grid w-full grid-cols-3 gap-2 mt-4">
                                <input
                                  type="text"
                                  className={`${inputClass}`}
                                  {...register('address.city', {
                                    value: mcpData?.address?.city
                                  })}
                                  disabled={!isRegistered}
                                />
                                <input
                                  type="text"
                                  className={`${inputClass}`}
                                  {...register('address.state', {
                                    value: mcpData?.address?.state
                                  })}
                                  disabled={!isRegistered}
                                />
                                <input
                                  type="text"
                                  className={`${inputClass}`}
                                  {...register('address.zip', {
                                    value: mcpData?.address?.zip
                                  })}
                                  disabled={!isRegistered}
                                />
                              </p>
                            </>
                          ) : (
                            <>
                              <p>
                                {mcpData?.address?.address1}
                                {', '}
                                {mcpData?.address?.address2}
                              </p>
                              <p>
                                {mcpData?.address?.city}
                                {', '}
                                {mcpData?.address?.state}
                                {', '}
                                {mcpData?.address?.zip}
                              </p>
                            </>
                          )}
                        </dd>
                        <dt className="font-bold">Mailing Address</dt>
                        <dd className="mr-4">
                          {isRegistered ? (
                            <>
                              <p className="grid grid-cols-2 gap-2">
                                <input
                                  type="text"
                                  className={`${inputClass}`}
                                  {...register('mailingAddress.address1', {
                                    value: mcpData?.mailingAddress?.address1
                                  })}
                                  disabled={!isRegistered}
                                />
                                <input
                                  type="text"
                                  className={`${inputClass}`}
                                  {...register('mailingAddress.address2', {
                                    value: mcpData?.mailingAddress?.address2
                                  })}
                                  disabled={!isRegistered}
                                />
                              </p>
                              <p className="grid w-full grid-cols-3 gap-2 mt-4">
                                <input
                                  type="text"
                                  className={`${inputClass}`}
                                  {...register('mailingAddress.city', {
                                    value: mcpData?.mailingAddress?.city
                                  })}
                                  disabled={!isRegistered}
                                />
                                <input
                                  type="text"
                                  className={`${inputClass}`}
                                  {...register('mailingAddress.state', {
                                    value: mcpData?.mailingAddress?.state
                                  })}
                                  disabled={!isRegistered}
                                />
                                <input
                                  type="text"
                                  className={`${inputClass}`}
                                  {...register('mailingAddress.zip', {
                                    value: mcpData?.mailingAddress?.zip
                                  })}
                                  disabled={!isRegistered}
                                />
                              </p>
                            </>
                          ) : (
                            <>
                              <p>
                                {mcpData?.mailingAddress?.address1 ||
                                  mcpData?.address?.address1}
                                {', '}
                                {mcpData?.mailingAddress?.address2 ||
                                  mcpData?.address?.address2}
                              </p>
                              <p>
                                {mcpData?.mailingAddress?.city ||
                                  mcpData?.address?.city}
                                {', '}
                                {mcpData?.mailingAddress?.state ||
                                  mcpData?.address?.state}
                                {', '}
                                {mcpData?.mailingAddress?.zip ||
                                  mcpData?.address?.zip}
                              </p>
                            </>
                          )}
                        </dd>
                        {mcpData?.message && (
                          <>
                            <dt className="font-bold">Message</dt>
                            <dd className="font-bold">{mcpData?.message}</dd>
                          </>
                        )}
                      </dl>
                      {isRegistered && (
                        <button
                          type="submit"
                          className="px-4 py-2 mt-8 text-white border-l rounded bg-navy-500 hover:bg-navy-600 active:bg-navy-700"
                        >
                          Save Changes
                        </button>
                      )}
                    </form>
                  </div>
                  <div className="mt-10 space-y-6">
                    <div>
                      <h2>
                        <button
                          type="button"
                          className="flex items-center justify-between w-full p-4 font-medium text-left text-white border border-gray-200 rounded-lg bg-navy-500 hover:bg-navy-600"
                          onClick={() => accordChange(1)}
                        >
                          <span className="text-xl">Authority</span>
                          {accord === 1 ? (
                            <AiOutlineMinusCircle className="text-3xl" />
                          ) : (
                            <AiOutlinePlusCircle className="text-3xl" />
                          )}
                        </button>
                      </h2>
                      {accord === 1 && (
                        <div className="p-5 text-gray-700 border border-gray-200 rounded-lg">
                          <div className="grid grid-cols-3 p-4 py-6 text-sm font-bold text-left border border-gray-300 rounded-lg ">
                            <p>
                              Common Authority:
                              <span
                                className={
                                  mcpData?.authority?.commonAuthority ===
                                  'Active'
                                    ? 'text-green-600'
                                    : 'text-red-500'
                                }
                              >
                                {' '}
                                {mcpData?.authority?.commonAuthority}
                              </span>
                            </p>
                            <p>
                              Contract Authority:{' '}
                              <span
                                className={
                                  mcpData?.authority?.contractAuthority ===
                                  'Active'
                                    ? 'text-green-600'
                                    : 'text-red-500'
                                }
                              >
                                {mcpData?.authority?.contractAuthority}
                              </span>
                            </p>
                            <p>
                              Broker Authority:{' '}
                              <span
                                className={
                                  mcpData?.authority?.brokerAuthority ===
                                  'Active'
                                    ? 'text-green-600'
                                    : 'text-red-500'
                                }
                              >
                                {mcpData?.authority?.brokerAuthority}
                              </span>
                            </p>
                            <p>
                              Common Authority Application Pending:{' '}
                              <span
                                className={
                                  mcpData?.authority?.commonAuthorityPending ===
                                  'No'
                                    ? 'text-red-500'
                                    : 'text-green-600'
                                }
                              >
                                {mcpData?.authority?.commonAuthorityPending}
                              </span>
                            </p>
                            <p>
                              Contract Authority Application Pending:{' '}
                              <span
                                className={
                                  mcpData?.authority
                                    ?.contractAuthorityPending === 'No'
                                    ? 'text-red-500'
                                    : 'text-green-600'
                                }
                              >
                                {mcpData?.authority?.contractAuthorityPending}
                              </span>
                            </p>
                            <p>
                              Broker Authority Application Pending:{' '}
                              <span
                                className={
                                  mcpData?.authority?.brokerAuthorityPending ===
                                  'No'
                                    ? 'text-red-500'
                                    : 'text-green-600'
                                }
                              >
                                {mcpData?.authority?.brokerAuthorityPending}
                              </span>
                            </p>
                            <p>
                              Common Authority Pending Revocation:{' '}
                              <span
                                className={
                                  mcpData?.authority
                                    ?.commonAuthorityRevocation === 'No'
                                    ? 'text-red-500'
                                    : 'text-green-600'
                                }
                              >
                                {mcpData?.authority?.commonAuthorityRevocation}
                              </span>
                            </p>
                            <p>
                              Contract Authority Pending Revocation:{' '}
                              <span
                                className={
                                  mcpData?.authority
                                    ?.contractAuthorityRevocation === 'No'
                                    ? 'text-red-500'
                                    : 'text-green-600'
                                }
                              >
                                {
                                  mcpData?.authority
                                    ?.contractAuthorityRevocation
                                }
                              </span>
                            </p>
                            <p>
                              Broker Authority Pending Revocation:{' '}
                              <span
                                className={
                                  mcpData?.authority
                                    ?.brokerAuthorityRevocation === 'No'
                                    ? 'text-red-500'
                                    : 'text-green-600'
                                }
                              >
                                {mcpData?.authority?.brokerAuthorityRevocation}
                              </span>
                            </p>
                          </div>
                          <div className="flex gap-4 mt-6">
                            <div className="w-1/3 p-4 border border-gray-300 rounded-lg">
                              <h2 className="mb-4 text-lg font-bold">
                                Cargo Authorization
                              </h2>
                              <p className="font-bold">
                                Freight:{' '}
                                <span
                                  className={
                                    mcpData?.authority?.freight === 'Yes'
                                      ? 'text-green-600'
                                      : 'text-red-500'
                                  }
                                >
                                  {mcpData?.authority?.freight}
                                </span>
                              </p>
                              <p className="font-bold">
                                Passenger:{' '}
                                <span
                                  className={
                                    mcpData?.authority?.passenger === 'Yes'
                                      ? 'text-green-600'
                                      : 'text-red-500'
                                  }
                                >
                                  {mcpData?.authority?.passenger}
                                </span>
                              </p>
                              <p className="font-bold">
                                Household Goods:{' '}
                                <span
                                  className={
                                    mcpData?.authority?.householdGoods === 'Yes'
                                      ? 'text-green-600'
                                      : 'text-red-500'
                                  }
                                >
                                  {mcpData?.authority?.householdGoods}
                                </span>
                              </p>
                            </div>
                            <div className="w-1/3 h-20 p-4 border border-gray-300 rounded-lg">
                              <p className="font-bold">
                                Authority Granted On:{' '}
                                <p className="font-medium">
                                  {mcpData?.authority?.authGrantDate}
                                </p>
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <h2>
                        <button
                          type="button"
                          className="flex items-center justify-between w-full p-4 font-medium text-left text-white border border-gray-200 rounded-lg bg-navy-500 hover:bg-navy-600"
                          onClick={() => accordChange(2)}
                        >
                          <span className="text-xl">Insurance</span>
                          {accord === 2 ? (
                            <AiOutlineMinusCircle className="text-3xl" />
                          ) : (
                            <AiOutlinePlusCircle className="text-3xl" />
                          )}
                        </button>
                      </h2>
                      {accord === 2 && (
                        <div className="text-gray-700">
                          <div className="grid grid-cols-2 gap-4 p-5 border border-gray-200 rounded-lg">
                            {mcpData?.certificates?.map((certificate) => {
                              return certificate?.Coverage?.map((cvg) => {
                                return (
                                  <div className="p-4 font-bold border border-gray-200 rounded-lg">
                                    <h3 className="mb-4 text-xl">{cvg.type}</h3>
                                    <p>
                                      Agent:{' '}
                                      <span className="font-normal">
                                        {certificate?.producerName}
                                      </span>
                                    </p>
                                    <p>
                                      Underwriter:{' '}
                                      <span className="font-normal">
                                        {cvg?.insurerName}
                                      </span>
                                    </p>{' '}
                                    <p>
                                      A.M. Best Rating:{' '}
                                      <span className="font-normal">
                                        {cvg?.insurerAMBestRating}
                                      </span>
                                    </p>
                                    <p>
                                      Policy Number:{' '}
                                      <span className="text-sm font-normal">
                                        {cvg?.policyNumber}
                                      </span>
                                    </p>{' '}
                                    <p>
                                      Expiration Date:{' '}
                                      <span className="text-sm font-normal">
                                        {cvg?.expirationDate}
                                      </span>
                                    </p>{' '}
                                    <p>
                                      Coverage Limit:{' '}
                                      <span className="text-sm font-normal">
                                        {cvg?.coverageLimit}
                                      </span>
                                    </p>{' '}
                                    <p>
                                      Deductible:{' '}
                                      <span className="text-sm font-normal">
                                        {cvg?.deductable}
                                      </span>
                                    </p>{' '}
                                  </div>
                                );
                              });
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {!isRegistered &&
                mcpData?.carrierPacketStatus === 'Complete' &&
                ['Acceptable', 'Moderate', 'UnacceptableReview'].includes(
                  mcpData?.riskAssesment
                ) &&
                !mcpData?.isBlocked && (
                  <button
                    type="button"
                    className="px-4 py-2 mt-6 mr-4 text-white border-l rounded bg-navy-500 hover:bg-navy-600 active:bg-navy-700"
                    onClick={() => onCarrierSendInvite()}
                  >
                    Send Invite
                  </button>
                )}
              {isRegistered && (
                <button
                  type="button"
                  className="px-4 py-2 mt-6 text-white border-l rounded bg-navy-500 hover:bg-navy-600 active:bg-navy-700"
                  onClick={() => onRefreshData()}
                >
                  Refresh Data
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CarrierDetailsPage;

import React, { useState, memo, useEffect, useContext } from 'react';
import '../../styles/custom.css';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useAuthContext } from '../../contexts/auth.context';
import { salesRepLogout, userLogout } from '../../services/auth.service';
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg';
import Roles from '../../constants/roles.constants';
import packageJson from '../../../package.json';
import NETFooterLogo from '../../assets/images/Nationwide-Logo.svg';
import GlobalSearchBar from '../GlobalSearchBar/GlobalSearchBar';
import { currentYear } from '../../constants/generalConstants';
import useGetSalesRepSidebarRoutes from '../../constants/sidebarRoutes/salesRep.routes';
import useGetCarrierSidebarRoutes from '../../constants/sidebarRoutes/carrier.routes';
import shipperSidebarRoutes from '../../constants/sidebarRoutes/shipper.routes';
import { GlobalFormContext } from '../../contexts/QuickQuoteContext';
import useGetCustomerRepSidebarRoutes from '../../constants/sidebarRoutes/customerRep.routes';

const CloseIcon = memo(() => {
  return (
    <svg
      className="h-6 w-6  cursor-pointer"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
});

const Sidebar = memo(() => {
  const { authUser, setAuthUser } = useAuthContext();
  const { clearQuickQuoteForm } = useContext(GlobalFormContext);
  const [isMobile, setIsMobile] = useState(false);
  const [showInputInMobile, setShowInputInMobile] = useState(false);
  const salesRepSidebarRoutes = useGetSalesRepSidebarRoutes();
  const customerRepSidebarRoutes = useGetCustomerRepSidebarRoutes();
  const carrierSidebarRoutes = useGetCarrierSidebarRoutes();

  const sidebarData = (type) => {
    switch (type) {
      case 'carrier':
        return carrierSidebarRoutes;

      case 'user':
        return shipperSidebarRoutes;

      case 'salesRep':
        return salesRepSidebarRoutes;

      case 'customerRep':
        return customerRepSidebarRoutes;

      default:
        return [];
    }
  };

  const [sidebar, setsidebar] = useState(true);

  const sidebarMenu = authUser.user ? sidebarData(authUser.user.usertype) : [];

  const toggleSidebar = () => {
    setsidebar((prevSidebar) => !prevSidebar);
  };

  const handleClick = (label) => {
    if (label === 'New Quote') {
      clearQuickQuoteForm();
    }

    toggleSidebar(label);
  };

  const onLogout = async () => {
    try {
      switch (authUser.user.usertype) {
        case Roles.SALES_REP:
        case Roles.CUSTOMER_REP: {
          const response = await salesRepLogout({ token: authUser.token });
          if (response.data.flag) {
            setAuthUser({});
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('link');
            toast.success(response.data.message);
          }
          break;
        }

        default: {
          const responseUser = await userLogout({ token: authUser.token });
          if (responseUser.data.flag) {
            setAuthUser({});
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('link');
            toast.success(responseUser.data.message);
          }
          break;
        }
      }
    } catch (error) {
      // console.log(error.response);
      setAuthUser({});
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <section>
      <nav className="relative py-6 bg-navy-500">
        <div className="container mx-auto px-4 flex justify-between items-center">
          {!isMobile || (isMobile && !showInputInMobile) ? (
            <Link className="text-3xl font-bold leading-none bg-white" to="/">
              <img src={NETFooterLogo} alt="" width="150" />
            </Link>
          ) : null}

          <div
            className={`flex flex-row items-center gap-3 ${
              isMobile && 'w-full'
            }`}
          >
            <GlobalSearchBar
              isMobile={isMobile}
              showInputInMobile={showInputInMobile}
              setIsMobile={setIsMobile}
              setShowInputInMobile={setShowInputInMobile}
            />
            {!isMobile || (isMobile && !showInputInMobile) ? (
              <button
                type="button"
                onClick={toggleSidebar}
                className="navbar-burger bg-white flex items-center p-2"
              >
                <MenuIcon />
              </button>
            ) : null}
          </div>
        </div>
      </nav>
      <div
        className={`${
          sidebar
            ? 'hidden navbar-menu relative z-50'
            : 'navbar-menu relative z-50'
        }`}
      >
        <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25" />
        <nav className="fixed top-0 right-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto">
          <div className="flex items-center mb-8">
            <span className="mr-auto text-3xl font-bold leading-none">
              <img src={NETFooterLogo} alt="" width="NaN" />
            </span>
            <button
              type="button"
              onClick={toggleSidebar}
              className="navbar-close text-navy-500"
            >
              <CloseIcon />
            </button>
          </div>
          <div>
            <p className="text-center font-bold py-2 text-xl text-navy-500">
              Hello,{' '}
              {[Roles.SALES_REP, Roles.CUSTOMER_REP].includes(
                authUser?.user?.usertype
              )
                ? authUser?.user?.person
                : authUser?.user?.name}
            </p>
            {authUser?.user?.usertype === Roles.SHIPPER && (
              <p className="text-center text-navy-500 text-base font-bold">
                {authUser?.user?.companyId?.name}
              </p>
            )}
            <p className="text-center text-gray-600 text-sm mb-5">
              {authUser?.user?.email}
            </p>
            <div>
              {sidebarMenu?.map((options) => {
                return (
                  <Link
                    key={options.route}
                    className="pl-2 py-4 block shadow hover:bg-gray-200"
                    to={options.route}
                    onClick={() => handleClick(options.label)}
                  >
                    {options.label}
                  </Link>
                );
              })}
              {/* {authUser?.user?.usertype === Roles.SHIPPER &&
                authUser?.user?.stripeCustomerId ? (
                  <>
                    <div
                      role="button"
                      tabIndex="0"
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          onCustomerPortal();
                        }
                      }}
                      onClick={onCustomerPortal}
                      className="pl-2 py-4 block shadow hover:bg-gray-200"
                    >
                      <span className="text-md">Manage Payment methods</span>
                    </div>
                  </>
                ) : (
                  ''
                )} */}

              <div
                role="button"
                tabIndex="0"
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    onLogout();
                  }
                }}
                onClick={onLogout}
                className="pl-2 py-4 block shadow bg-red-500 hover:bg-red-600 text-white"
              >
                <span className="text-md">Logout</span>
              </div>
            </div>

            <p className="my-4 text-md text-center text-navy-400">
              <span>Version {packageJson.version}</span>
            </p>
            <p className="my-4 text-xs text-center text-gray-400">
              <span>
                Copyright © {currentYear} Nationwide Equipment Transportation.{' '}
                <br /> All rights reserved.
              </span>
            </p>
          </div>
        </nav>
      </div>
    </section>
  );
});

export default Sidebar;

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import toast from 'react-hot-toast';
import momBusinessDays from 'moment-business-days';
import { useAuthContext } from '../../contexts/auth.context';
import CargoDetailsCard from '../../components/CargoDetailsCard/CargoDetailsCard';
import GoogleMapComponent from '../../components/GoogleMapComponent/GoogleMapComponent';
import {
  SERVICE_TYPES,
  PICKUP_SERVICE_OPTION,
  DELIVERY_SERVICE_OPTION
} from '../../constants/quote.constants';
import {
  getQuoteDetailsService,
  markAsCompletedService,
  refreshLtlRatesService,
  updateCarrierType
} from '../../services/quote.service';
import { getBookingStatus, getQuotePrice } from '../../utils/quote.util';
import { ReactComponent as RightArrow } from '../../assets/images/right_arrow.svg';
import { ReactComponent as LeftArrowIcon } from '../../assets/images/left_arrow.svg';
import {
  BLANKET_WRAP,
  LTL_FREIGHT
} from '../../constants/service_type.constants';
import LTLRateTable from '../../components/LTLRateTable/LTLRateTable';
import BOLButton from '../../components/BOLButton/BOLButton';
import { BID_STATUS } from '../../constants/bids.constants';

const LoadSummaryPage = () => {
  const navigate = useNavigate();
  const { quoteId } = useParams();
  const { authUser } = useAuthContext();

  const [isAPICalled, setisAPICalled] = useState(false);
  const [quote, setQuote] = useState(null);
  const [showTracking, setshowTracking] = useState(false);
  const [value, setValue] = useState('');
  const [isOldQuote, setIsOldQuote] = useState(false);
  const [cargoId, setCargoId] = useState();
  const [isBookingAllowed, setIsBookingAllowed] = useState(false);

  const { RECENT_QUOTE, ENROUTE_QUOTE, DELIVERED_QUOTE } = BID_STATUS;

  useEffect(() => {
    if (quote && quote?.service_type === LTL_FREIGHT && !quote?.booked) {
      const diff = momBusinessDays().businessDiff(
        momBusinessDays(quote?.ltlRateDate || quote?.createdAt)
      );
      if (diff >= 5) setIsOldQuote(true);
    }
  }, [quote]);

  async function fetchQuoteDetails() {
    try {
      // Preventing Stale Data Rendering
      setisAPICalled(false);
      const response = await getQuoteDetailsService(quoteId);

      setQuote(response.data.quote);
      setCargoId(response.data.quote?.cargos?.[0]?.id);
      setisAPICalled(true);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    fetchQuoteDetails();
  }, [quoteId]);

  useEffect(() => {
    if (
      [
        'shipper-active-shipment',
        'shipper-enroute-loads',
        'shipper-completed-loads'
      ].includes(quote?.loadType)
    ) {
      setshowTracking(true);
    } else {
      setshowTracking(false);
    }
  }, [quote]);

  const onChangeHandler = (e) => {
    setValue(e.target.value);
  };

  const onBookNowClick = async () => {
    if (quote) {
      try {
        if (quote?.service_type !== LTL_FREIGHT) {
          return navigate(`/book-now-quote/${quoteId}`);
        }
        if (quote?.service_type === LTL_FREIGHT && !value) {
          toast.error('Select carrier');
        } else {
          if (!quote[value] || !quote[value][`${value}_estimate`]) {
            return toast.error('Please select another carrier');
          }
          const response = await updateCarrierType({
            carrier_type: value,
            quoteId
          });
          if (response.data.flag) {
            navigate(`/book-now-quote/${quoteId}`);
          }
        }
      } catch (error) {
        toast.error(error?.response?.data.message ?? 'Something went wrong!');
      }
    }
  };

  const onBackArrowClick = () => {
    navigate(-1);
  };

  const onTrackShipmentClick = () => {
    navigate(`/shipper-enroute-load-summary/tracking/${quoteId}`);
  };

  const onMarkAsCompleted = async () => {
    try {
      const response = await markAsCompletedService({
        quoteId,
        deliveryDateTime: moment().format('MM/DD/YYYY HH:mm')
      });

      if (response.data.flag) {
        toast.success(response.data.message);
        navigate('/shipper-completed');
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const refreshQuote = async () => {
    if (!isOldQuote) return navigate(`/new-quote?quoteId=${quoteId}`);
    try {
      const response = await refreshLtlRatesService(quoteId);
      setQuote(response?.data?.data);
      setIsOldQuote(false);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  useEffect(() => {
    if (quote && Object.keys(quote).length) {
      const bookingStatus = getBookingStatus(quote, value);
      setIsBookingAllowed(bookingStatus);
    }
  }, [value, quote]);

  return (
    <section className="py-20">
      <div className="container px-4 mx-auto">
        {isAPICalled ? (
          <div className="flex flex-wrap -mx-4 text-center">
            <div className="w-full px-3 mb-8 lg:w-1/2">
              <div className="flex flex-col gap-3 items-start mb-4 text-navy-500">
                <div
                  tabIndex="-1"
                  role="button"
                  onKeyPress={() => {}}
                  onClick={onBackArrowClick}
                  className="inline-flex items-center"
                >
                  <LeftArrowIcon className="inline-flex items-center w-6 h-6 " />
                  <p className="ml-4 text-xl">Back to the list</p>
                </div>
                <div className="flex flex-row justify-between w-full items-center">
                  <h4 className="text-3xl font-bold text-left text-navy-500">
                    Summary
                  </h4>
                  {quote?.internalTrackingNumber && (
                    <div className="text-black">
                      Load #{quote?.internalTrackingNumber}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col items-start gap-1 mb-5">
                <h4 className="font-bold text-xl">Service Type</h4>
                <span>
                  {
                    SERVICE_TYPES.find(
                      (service) => service.value === quote?.service_type
                    )?.label
                  }
                </span>
              </div>
              {quote?.equipmentType && (
                <div className="mb-5">
                  <p className="text-left font-bold text-lg">
                    Equipment Type:{' '}
                    <span className="font-normal text-lg">
                      {quote?.equipmentType?.join(', ')}
                    </span>
                  </p>
                </div>
              )}
              <hr />
              <div className="flex flex-wrap p-4 -mx-3 ">
                {quote.cargos.map((cg) => {
                  const pickupDataIdx =
                    quote?.pickupInfo?.findIndex(
                      (pick) => pick.pickup_id === cg.ship_from
                    ) || 0;
                  const deliveryDataIdx =
                    quote?.deliveryInfo?.findIndex(
                      (del) => del.delivery_id === cg.ship_to
                    ) || 0;
                  const pickupData = quote?.pickupInfo?.[pickupDataIdx];
                  const deliveryData = quote?.deliveryInfo?.[deliveryDataIdx];

                  const getShipFrom = () => {
                    if (quote?.isMultipleQuote) {
                      if (pickupData?.pickup_address) {
                        return (
                          <>
                            <p className="flex">{`${pickupData?.pickup_address?.company}`}</p>
                            <p className="flex">{`${
                              pickupData?.pickup_address?.address1
                            }, ${
                              pickupData?.pickup_address?.address2
                                ? `${pickupData?.pickup_address?.address2},`
                                : ''
                            } ${pickupData?.pickup_address?.city}, ${
                              pickupData?.pickup_address?.state
                            }, ${pickupData?.pickup_address?.country}, ${
                              pickupData?.pickup_zip_code
                            }`}</p>
                            <p className="flex">{`${pickupData?.pickup_address?.contactperson}, ${pickupData?.pickup_address?.contactnumber}`}</p>
                          </>
                        );
                      }
                      return (
                        <p className="flex">{pickupData?.pickup_zip_code}</p>
                      );
                    }
                    if (quote?.pickup_address) {
                      return (
                        <>
                          <p className="flex">{`${quote?.pickup_address?.company}`}</p>
                          <p className="flex">{`${
                            quote?.pickup_address?.address1
                          }, ${
                            quote?.pickup_address?.address2
                              ? `${quote?.pickup_address?.address2},`
                              : ''
                          } ${quote?.pickup_address?.city}, ${
                            quote?.pickup_address?.state
                          }, ${quote?.pickup_address?.country}, ${
                            quote?.pickup_zip_code
                          }`}</p>
                          <p className="flex">{`${quote?.pickup_address?.contactperson}, ${quote?.pickup_address?.contactnumber}`}</p>
                        </>
                      );
                    }
                    return <p className="flex">{quote?.pickup_zip_code}</p>;
                  };

                  const pickupDate = quote?.isMultipleQuote
                    ? pickupData?.pickup_date
                    : quote?.pickup_date;
                  const pickupTime = quote?.isMultipleQuote
                    ? pickupData?.pickup_time
                    : quote?.pickup_time;
                  const pickupCloseTime = quote?.isMultipleQuote
                    ? pickupData?.pickup_close_time
                    : quote?.pickup_close_time;
                  const pickupServiceOptions = () => {
                    let options;
                    if (quote?.isMultipleQuote) {
                      options = pickupData?.pickup_service_options
                        ?.map(
                          (service) =>
                            PICKUP_SERVICE_OPTION.find(
                              (ser) => ser.value === service
                            )?.label
                        )
                        .join(', ');
                    } else {
                      options = quote?.pickup_service_options
                        ?.map(
                          (service) =>
                            PICKUP_SERVICE_OPTION.find(
                              (ser) => ser.value === service.id
                            )?.label
                        )
                        .join(', ');
                    }

                    return options || 'N/A';
                  };
                  const pickupInstructions = quote?.isMultipleQuote
                    ? pickupData?.pickup_special_instructions
                    : quote?.pickup_special_instructions;
                  const shipperRef = quote?.isMultipleQuote
                    ? pickupData?.pickup_address?.reference
                    : quote?.pickup_address?.reference;

                  const getShipTo = () => {
                    if (quote?.isMultipleQuote) {
                      if (deliveryData?.delivery_address) {
                        return (
                          <>
                            <p className="flex">{`${deliveryData?.delivery_address?.company}`}</p>
                            <p className="flex">{`${
                              deliveryData?.delivery_address?.address1
                            }, ${
                              deliveryData?.delivery_address?.address2
                                ? `${deliveryData?.delivery_address?.address2},`
                                : ''
                            } ${deliveryData?.delivery_address?.city}, ${
                              deliveryData?.delivery_address?.state
                            }, ${
                              deliveryData?.delivery_address?.country
                            }, ${deliveryData?.delivery_zip_code}`}</p>
                            <p className="flex">{`${deliveryData?.delivery_address?.contactperson}, ${deliveryData?.delivery_address?.contactnumber}`}</p>
                          </>
                        );
                      }
                      return (
                        <p className="flex">
                          {deliveryData?.delivery_zip_code}
                        </p>
                      );
                    }
                    if (quote?.delivery_address) {
                      return (
                        <>
                          <p className="flex">{`${quote?.delivery_address?.company}`}</p>
                          <p className="flex">{`${
                            quote?.delivery_address?.address1
                          }, ${
                            quote?.delivery_address?.address2
                              ? `${quote?.delivery_address?.address2},`
                              : ''
                          } ${quote?.delivery_address?.city}, ${
                            quote?.delivery_address?.state
                          }, ${quote?.delivery_address?.country}, ${
                            quote?.delivery_zip_code
                          }`}</p>
                          <p className="flex">{`${quote?.delivery_address?.contactperson}, ${quote?.delivery_address?.contactnumber}`}</p>
                        </>
                      );
                    }
                    return <p className="flex">{quote?.delivery_zip_code}</p>;
                  };
                  const deliveryDate = quote?.isMultipleQuote
                    ? deliveryData?.delivery_date
                    : quote?.delivery_date;
                  const deliveryTime = quote?.isMultipleQuote
                    ? deliveryData?.delivery_time
                    : quote?.delivery_time;
                  const deliveryCloseTime = quote?.isMultipleQuote
                    ? deliveryData?.delivery_close_time
                    : quote?.delivery_close_time;
                  const deliveryServiceOptions = () => {
                    let options;
                    if (quote?.isMultipleQuote) {
                      options = deliveryData?.delivery_service_options
                        ?.map(
                          (service) =>
                            DELIVERY_SERVICE_OPTION.find(
                              (ser) => ser.value === service
                            )?.label
                        )
                        .join(', ');
                    } else {
                      options = quote?.delivery_service_options
                        ?.map(
                          (service) =>
                            DELIVERY_SERVICE_OPTION.find(
                              (ser) => ser.value === service.id
                            )?.label
                        )
                        .join(', ');
                    }

                    return options || 'N/A';
                  };
                  const deliveryInstructions = quote?.isMultipleQuote
                    ? deliveryData?.delivery_special_instructions
                    : quote?.delivery_special_instructions;
                  const consigneeRef = quote?.isMultipleQuote
                    ? deliveryData?.delivery_address?.reference
                    : quote?.delivery_address?.reference;
                  return (
                    <React.Fragment key={cg.id}>
                      <div className="flex flex-wrap items-center w-full p-3 my-3 bg-gray-100">
                        <div>
                          <span className="inline-block p-2 mr-6 border lg:mb-0 md:p-4 bg-navy-50 border-navy-50">
                            {cg?.files?.fd ? (
                              <img
                                className="object-contain w-4 h-4 md:w-6 md:h-6"
                                src={`${process.env.REACT_APP_API_URL}/${cg?.files?.fd}`}
                                alt="cargo img"
                              />
                            ) : (
                              <svg
                                className="w-4 h-4 md:w-6 md:h-6"
                                xmlns="http://www.w3.org/2000/svg"
                                width="27"
                                height="27"
                                viewBox="0 0 27 27"
                              >
                                <path
                                  id="Icon_material-image"
                                  data-name="Icon material-image"
                                  d="M31.5,28.5V7.5a3.009,3.009,0,0,0-3-3H7.5a3.009,3.009,0,0,0-3,3v21a3.009,3.009,0,0,0,3,3h21A3.009,3.009,0,0,0,31.5,28.5ZM12.75,20.25l3.75,4.515L21.75,18l6.75,9H7.5Z"
                                  transform="translate(-4.5 -4.5)"
                                  fill="#1d4690"
                                />
                              </svg>
                            )}
                          </span>
                        </div>
                        <div className="text-left">
                          <h2>
                            {cg.quantity} - {cg?.package_type[0]?.display_name}
                          </h2>
                          <h2>
                            {cg.length} in x {cg.width} in x {cg.height} in x{' '}
                            {cg.weight} lbs
                          </h2>
                        </div>
                        <div className="mt-auto mb-auto ml-auto">
                          <button
                            type="button"
                            onClick={() => {
                              setCargoId(cargoId === cg.id ? null : cg.id);
                            }}
                          >
                            {cargoId === cg.id ? (
                              <span className="text-2xl">⌃</span>
                            ) : (
                              <span className="text-4xl">⌄</span>
                            )}
                          </button>
                        </div>
                      </div>
                      {cg.id === cargoId && (
                        <div className="flex flex-col w-full">
                          <div className="flex flex-col mt-5 mb-5 gap-3">
                            <div className="flex justify-between">
                              <h4 className="text-xl font-bold">
                                Pickup information
                              </h4>
                            </div>
                            <div>
                              <div className="flex flex-col justify-start space-y-1">
                                <h2 className="flex font-semibold">
                                  Ship from
                                </h2>
                                {getShipFrom()}
                              </div>

                              <div className="flex flex-col justify-start">
                                <h2 className="flex font-semibold">
                                  Pickup date
                                </h2>
                                <p className="flex ">
                                  {pickupDate || 'flexible date'}
                                </p>
                              </div>
                              <div className="flex flex-col justify-start">
                                <h2 className="flex font-semibold">
                                  Pickup time
                                </h2>
                                <p className="flex ">
                                  {pickupTime || 'flexible time'}
                                </p>
                              </div>
                              <div className="flex flex-col justify-start">
                                <h2 className="flex font-semibold">
                                  Pickup close time
                                </h2>
                                <p className="flex">
                                  {pickupCloseTime || 'N/A'}
                                </p>
                              </div>
                              <div className="flex flex-col justify-start">
                                <h2 className="flex font-semibold">
                                  Pickup service
                                </h2>
                                <p className="flex ">
                                  {pickupServiceOptions()}
                                </p>
                              </div>
                              <div className="flex flex-col justify-start">
                                <h2 className="flex font-semibold">
                                  Special pickup instruction
                                </h2>
                                <p className="flex">
                                  {pickupInstructions || 'N/A'}
                                </p>
                              </div>
                              <div className="flex flex-col justify-start">
                                <h2 className="flex font-semibold">
                                  Shipper Ref#
                                </h2>
                                <p className="flex">{shipperRef || 'N/A'}</p>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="flex flex-col mt-5 mb-5 gap-3">
                            <div className="flex justify-between">
                              <h4 className="text-xl font-bold">
                                Delivery information
                              </h4>
                            </div>
                            <div>
                              <div className="flex flex-col justify-start space-y-1">
                                <h2 className="flex font-semibold">Ship to</h2>
                                {getShipTo()}
                              </div>
                              <div className="flex flex-col justify-start">
                                <h2 className="flex font-semibold">
                                  Delivery date
                                </h2>
                                <p className="flex ">
                                  {deliveryDate || 'flexible time'}
                                </p>
                              </div>
                              <div className="flex flex-col justify-start">
                                <h2 className="flex font-semibold">
                                  Delivery time
                                </h2>
                                <p className="flex ">
                                  {deliveryTime || 'flexible time'}
                                </p>
                              </div>
                              <div className="flex flex-col justify-start">
                                <h2 className="flex font-semibold">
                                  Delivery close time
                                </h2>
                                <p className="flex ">
                                  {deliveryCloseTime || 'N/A'}
                                </p>
                              </div>
                              <div className="flex flex-col justify-start">
                                <h2 className="flex font-semibold">
                                  Delivery service
                                </h2>
                                <p className="flex">
                                  {deliveryServiceOptions()}
                                </p>
                              </div>
                              <div className="flex flex-col justify-start">
                                <h2 className="flex font-semibold">
                                  Special delivery instruction
                                </h2>
                                <p className="flex">
                                  {deliveryInstructions || 'N/A'}
                                </p>
                              </div>
                              <div className="flex flex-col justify-start">
                                <h2 className="flex font-semibold">
                                  Consignee Ref#
                                </h2>
                                <p className="flex">{consigneeRef || 'N/A'}</p>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="flex flex-col mt-5 mb-5">
                            <div className="flex justify-between">
                              <h4 className="text-xl font-bold">
                                Cargo details
                              </h4>
                            </div>
                            <div>
                              <div className="pb-5 mt-1 border border-t-0 border-l-0 border-r-0 border-gray-200">
                                <CargoDetailsCard cargo={cg} />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>

              {[ENROUTE_QUOTE, DELIVERED_QUOTE].includes(quote?.bidStatus) &&
              ![LTL_FREIGHT, BLANKET_WRAP].includes(quote?.service_type) ? (
                <>
                  <hr />
                  <div className="pt-5 text-left">
                    <p className="text-xl text-left text-black font-bold my-4">
                      Driver Information
                    </p>
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-row gap-2">
                        <h2 className="flex font-semibold">Driver Name:</h2>
                        <p className="flex">{quote?.driverName || 'N/A'}</p>
                      </div>
                      <div className="flex flex-row gap-2">
                        <h2 className="flex font-semibold">Driver Phone:</h2>
                        <p>
                          <a
                            href={
                              quote?.driverPhone
                                ? `tel:${quote.driverPhone}`
                                : undefined
                            }
                          >
                            {quote?.driverPhone || 'N/A'}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              {quote?.service_type === LTL_FREIGHT &&
              quote?.bidStatus === RECENT_QUOTE ? (
                <LTLRateTable
                  quote={quote}
                  authUser={authUser}
                  isOldQuote={isOldQuote}
                  carrierValue={value}
                  setCarrierValueChange={onChangeHandler}
                />
              ) : null}

              {!quote?.booked && (
                <>
                  <div className="flex col-span-2">
                    <div className="relative flex-1 p-4 pl-0 text-left">
                      <LeftArrowIcon className="absolute inset-y-0 left-0 flex items-center w-10 h-10 px-2 mt-5 mr-5 text-white pointer-events-none font-boldx" />

                      <button
                        type="button"
                        onClick={() => refreshQuote()}
                        className="order-1 inline-block w-full py-3 mb-2 mr-12 text-sm font-bold text-center text-white transition duration-200 border xl:order-0 bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700"
                      >
                        {isOldQuote ? 'REFRESH NOW' : 'REFRESH QUOTE'}
                      </button>
                    </div>
                    <div className="relative flex-1 p-4 pl-0 text-left">
                      <button
                        type="button"
                        disabled={isOldQuote || !isBookingAllowed}
                        onClick={onBookNowClick}
                        className={`order-1 inline-block text-white w-full py-3 mb-2 mr-12 text-sm font-bold text-center transition duration-200 border xl:order-0 ${
                          isOldQuote || !isBookingAllowed
                            ? ' bg-gray-400 border-gray-400 cursor-not-allowed'
                            : ' bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700'
                        }   `}
                      >
                        <RightArrow className="absolute inset-y-0 right-0 flex items-center w-10 h-10 px-2 mt-5 mr-5 font-bold pointer-events-none" />
                        Book Now
                      </button>
                    </div>
                  </div>
                  {isOldQuote && quote?.service_type === LTL_FREIGHT && (
                    <div className="w-full border rounded bg-red-100 border-red-300 flex items-center p-2">
                      <p className="bg-red-500 rounded-full h-2 w-2 p-1" />
                      <p className="text-md">
                        {`LTL quote rates seems to be older than 5 business days.
                        Please tap on 'Refresh Now' to fetch latest rates.`}
                      </p>
                    </div>
                  )}
                </>
              )}

              <div className="relative p-4 pl-0 text-left">
                {quote?.booked && (
                  <div className="relative mt-5 border-t-0 border-l-0 border-r-0 border-gray-200">
                    <button
                      type="button"
                      className="order-1 inline-block w-full py-3 pl-4 mb-2 mr-12 text-sm font-bold text-left text-white transition duration-200 border xl:order-0 bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700"
                      onClick={() =>
                        navigate(`/new-quote?quoteId=${quote?.id}`)
                      }
                    >
                      Duplicate
                      <RightArrow className="absolute inset-y-0 right-0 flex items-center w-10 h-10 px-2 mt-1 mr-4 font-bold pointer-events-none" />
                    </button>
                    {[
                      'shipper-active-shipment',
                      'shipper-enroute-loads',
                      'shipper-completed-loads'
                    ].includes(quote?.loadType) && (
                      <BOLButton quoteId={quote?.id} />
                    )}
                  </div>
                )}
              </div>

              <h2 className="text-3xl font-bold text-center text-green-700">
                ${getQuotePrice(quote)}
              </h2>

              {showTracking && (
                <>
                  {quote?.loadType === 'shipper-enroute-loads' &&
                    ![LTL_FREIGHT, BLANKET_WRAP].includes(
                      quote?.service_type
                    ) && (
                      <div className="relative p-4 pl-0 text-left">
                        <h4 className="text-xl font-bold text-left text-green-700">
                          Your shipment has been picked up by Carrier at{' '}
                          {quote?.carrierPickupDate} {quote?.carrierPickUpTime}.
                        </h4>
                      </div>
                    )}
                  {!quote?.isPickupByCarrier &&
                    ![LTL_FREIGHT, BLANKET_WRAP].includes(
                      quote?.service_type
                    ) && (
                      <div className="relative p-4 pl-0 text-left">
                        <h4 className="mb-4 text-xl font-bold text-left text-red-600">
                          Your shipment is not picked up by carrier yet.
                        </h4>
                      </div>
                    )}
                  <div className="relative p-4 pl-0 text-left">
                    <button
                      type="button"
                      onClick={onTrackShipmentClick}
                      className="order-1 inline-block w-full py-3 pl-4 mb-2 mr-12 text-sm font-bold text-left text-white transition duration-200 border xl:order-0 bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700"
                    >
                      Track Shipment
                      <RightArrow className="absolute inset-y-0 right-0 flex items-center w-10 h-10 px-2 mt-5 mr-5 font-bold pointer-events-none" />
                    </button>
                  </div>
                </>
              )}

              {quote?.bidStatus === ENROUTE_QUOTE &&
                quote?.isDeliveredByCarrier && (
                  <div className="relative p-4 pl-0 text-left">
                    <button
                      type="button"
                      onClick={onMarkAsCompleted}
                      className="order-1 inline-block w-full py-3 pl-4 mb-2 mr-12 text-sm font-bold text-left text-white transition duration-200 border xl:order-0 bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700"
                    >
                      Mark as Completed
                      <span className="absolute inset-y-0 right-0 px-2 mt-4 mr-4 text-3xl pointer-events-none">
                        &#10003;
                      </span>
                    </button>
                  </div>
                )}
            </div>
            <div className="w-full px-3 mb-8 -mt-4 lg:w-1/2">
              <GoogleMapComponent
                pickupLatLong={
                  quote?.isMultipleQuote
                    ? quote?.pickupInfo?.map((pick) => {
                        return {
                          lat: pick.pickupLatitude,
                          lng: pick.pickupLongitude
                        };
                      })
                    : [
                        {
                          lat: quote?.pickupLatitude,
                          lng: quote?.pickupLongitude
                        }
                      ]
                }
                deliveryLatLong={
                  quote?.isMultipleQuote
                    ? quote?.deliveryInfo?.map((del) => {
                        return {
                          lat: del.deliveryLatitude,
                          lng: del.deliveryLongitude
                        };
                      })
                    : [
                        {
                          lat: quote?.deliveryLatitude,
                          lng: quote?.deliveryLongitude
                        }
                      ]
                }
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </section>
  );
};

export default LoadSummaryPage;

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Paginate from 'react-js-pagination';

const Pagination = memo(({ perPage, total, activePage, handlePageChange }) => {
  return (
    <Paginate
      itemClass="relative z-0 inline-flex rounded-md shadow-sm -space-x-px mx-1 text-navy-500"
      itemClassFirst="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
      linkClass="border-navy-500 h-10 w-10 leading-10 inline-block text-center border text-sm font-medium"
      activeLinkClass="z-10 bg-navy-500 text-white "
      activePage={activePage}
      itemsCountPerPage={perPage}
      totalItemsCount={total}
      pageRangeDisplayed={2}
      onChange={(page) => handlePageChange(page)}
      linkClassPrev="text-md font-bold mr-4"
      linkClassNext="text-md font-bold ml-4"
      linkClassFirst="text-md font-bold"
      linkClassLast="text-md font-bold"
      hideFirstLastPages
    />
  );
});

Pagination.propTypes = {
  perPage: PropTypes.number,
  total: PropTypes.number,
  activePage: PropTypes.number,
  handlePageChange: PropTypes.func
};

Pagination.defaultProps = {
  perPage: 10,
  total: 10,
  activePage: 1,
  handlePageChange: () => {}
};

export default Pagination;

import API from './api.services';
import API_ENDPOINTS from '../constants/api-endpoints.constants';

const createCustomerRepService = (body) => {
  return API.post(`${API_ENDPOINTS.CUSTOMER_REP.Profile}`, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const customerRepProfileService = (userId) => {
  return API.get(`${API_ENDPOINTS.CUSTOMER_REP.Profile}/${userId}`, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const updateCustomerRepService = (userId, body) => {
  return API.put(`${API_ENDPOINTS.CUSTOMER_REP.Profile}/${userId}`, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const getAllCustomerRepsBySalesRep = (limit = 0, skip = 0, query = '') => {
  return API.get(API_ENDPOINTS.SALES_REP.GetCustomerReps, {
    params: {
      token: localStorage.getItem('token'),
      limit,
      skip,
      searchText: query
    }
  });
};

export {
  createCustomerRepService,
  customerRepProfileService,
  updateCustomerRepService,
  getAllCustomerRepsBySalesRep
};

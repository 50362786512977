import moment from 'moment';

const convertTimeStamp = (date) => {
  return moment(date).format('MM/DD/YYYY');
};

// function pad(s) {
//   return s < 10 ? `0${s}` : s;
// }

const formatPickupDeliveryDate = (date, isFlexible) => {
  if (isFlexible) {
    return 'flexible date';
  }
  // const d = new Date(date);
  return moment(date).format('MM/DD/YYYY');
};

const formatPickupDeliveryTime = (time, isFlexible) => {
  if (isFlexible) {
    return 'flexible time';
  }
  const d = new Date(time);
  return d.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
};

export { convertTimeStamp, formatPickupDeliveryDate, formatPickupDeliveryTime };

import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';

const LayoutAuthenticated = () => {
  return (
    <body className="antialiased bg-body text-body font-body overflow-x-hidden">
      <Sidebar />
      <Outlet />
      <Footer />
    </body>
  );
};

export default LayoutAuthenticated;

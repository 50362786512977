import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from './contexts/auth.context';
import getHomePageOfUser from './utils/routes.util';

function HomePageComponent() {
  const { authUser } = useAuthContext();
  const path = getHomePageOfUser(authUser);
  return <Navigate to={path} replace />;
}

export default HomePageComponent;

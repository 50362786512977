import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { v4 as uuidv4 } from 'uuid';
import { IoLocationSharp } from 'react-icons/io5';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId
} from 'react-places-autocomplete';
import moment from 'moment';
import Roles from '../../../constants/roles.constants';
import DatePickerInput from '../../../components/DatePickerInput/DatePickerInput';
import TimePickerInput from '../../../components/TimePickerInput/TimePickerInput';
import { DELIVERY_SERVICE_OPTION } from '../../../constants/quote.constants';
import {
  LTL_FREIGHT,
  DELIVERY_BUSSINESS_WITH_DOCK_FORKLIFT,
  DELIVERY_RESIDENTIAL
} from '../../../constants/service_type.constants';

import { loadAddressService } from '../../../services/quote.service';
import { useAuthContext } from '../../../contexts/auth.context';
import { getAddressFromLatLong } from '../../../services/geo.service';
import { ReactComponent as RightArrowIcon } from '../../../assets/images/right_arrow.svg';
import { addLocationService } from '../../../services/shipper.service';
import { formatPickupDeliveryTime } from '../../../utils/date_conversion.util';
import { deliveryValidationSchema } from '../../../utils/quote.util';

const AddDelivery = ({
  addDelivery,
  updateDelivery,
  isAddMode,
  initialValues,
  serviceType,
  nextClick,
  showDeliveryForm,
  setShowDeliveryForm,
  changedDeliveries,
  setChangedDeliveries
}) => {
  const validationSchema = deliveryValidationSchema;

  const [isDateDisable, setDateDisable] = useState(false);
  const [isTimeDisable, setTimeDisable] = useState(false);
  const [address, setAddress] = React.useState('');
  const [userAddress, setUserAddress] = useState([]);
  const [IsChangeAdd, setIsChangeAdd] = useState(false);
  const [IsFromList, setIsFromList] = useState(false);

  const { authUser } = useAuthContext();

  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
    trigger,
    reset
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const { getValues: getContextVal } = useFormContext({});

  const deliveryInfo = watch();
  const isDeliveryFlexible = watch('is_delivery_flexible');

  const isDeliveryTimeFlexible = watch('is_delivery_time_flexible');

  const deliveryServices = watch('delivery_service_options');

  const handleSelect = async (value, placeId) => {
    try {
      let id = placeId;
      if (!id) {
        const result = await geocodeByAddress(value);
        id = result[0].place_id;
      }
      const [place] = await geocodeByPlaceId(id.trim());
      const { addr, postcode, city, state, country } =
        await getAddressFromLatLong(
          place.geometry.location.lat(),
          place.geometry.location.lng(),
          value,
          place.postcode_localities
        );

      setAddress(value);
      setValue(
        'delivery_to',
        `${addr} | ${city}, ${state}, ${postcode}, ${country}`
      );
      setValue('delivery_formatted_address', `${value} | ${placeId}`);
      setValue('delivery_zip_code', postcode);
      setValue('delivery_state', state);
    } catch (error) {
      console.log(error);
    }
  };

  async function loadAddresses() {
    try {
      const companyId =
        authUser?.user?.usertype === Roles.SHIPPER
          ? authUser?.user?.companyId?.id
          : getContextVal('company')?.id;
      const response = await loadAddressService(companyId, 'delivery');

      setUserAddress(response.data.data);
      const defaultAddress = response.data.data?.filter?.(
        (addr) => addr.isDefault
      )?.[0];
      const delAdd = getContextVal('deliveryInfo')?.length;
      if (!delAdd)
        handleSelect(
          defaultAddress?.name,
          defaultAddress?.locationId,
          defaultAddress
        );
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    if (IsChangeAdd) {
      setChangedDeliveries([...changedDeliveries, deliveryInfo.delivery_id]);
    }
  }, [IsChangeAdd]);

  useEffect(() => {
    if (isAddMode) {
      reset({
        is_delivery_flexible: true,
        is_delivery_time_flexible: true
      });
    } else {
      reset(initialValues);
    }

    loadAddresses();
    setAddress(getValues('delivery_formatted_address')?.split('|')?.[0]);
  }, [isAddMode, initialValues]);

  useEffect(() => {
    const addrData = userAddress?.find(
      (del) => del?.delivery_formatted_address?.trim() === address
    );

    if (addrData) {
      setValue(
        'delivery_time',
        addrData?.delivery_time !== 'flexible time'
          ? new Date(moment(addrData.delivery_time, 'h:mm a').format())
          : ''
      );
      setValue(
        'delivery_close_time',
        addrData?.delivery_close_time
          ? new Date(moment(addrData.delivery_close_time, 'h:mm a').format())
          : ''
      );
      setValue(
        'is_delivery_time_flexible',
        addrData?.is_delivery_time_flexible
      );
      setValue('delivery_service_options', addrData?.delivery_service_options);
      setValue(
        'delivery_special_instructions',
        addrData?.delivery_special_instructions
      );
    }

    if (userAddress?.length && addrData) {
      setIsFromList(true);
      setValue('deliveryLocation', addrData?.id);
    } else {
      setIsFromList(false);
      setValue('deliveryLocation', null);
    }
  }, [address]);

  useEffect(() => {
    if (isDeliveryFlexible) {
      setValue('delivery_date', '', { shouldValidate: true });
      setDateDisable(true);
    } else {
      setDateDisable(false);
    }
  }, [isDeliveryFlexible]);

  useEffect(() => {
    if (isDeliveryTimeFlexible) {
      setValue('delivery_time', '', { shouldValidate: true });
      setValue('delivery_close_time', '', { shouldValidate: true });
      setTimeDisable(true);
    } else {
      setTimeDisable(false);
    }
  }, [isDeliveryTimeFlexible]);

  useEffect(() => {
    if (typeof deliveryServices === 'string' && deliveryServices.length) {
      setValue('delivery_service_options', [deliveryServices]);
    }
    if (deliveryServices && deliveryServices.length) {
      if (
        serviceType === LTL_FREIGHT &&
        deliveryServices.includes(DELIVERY_BUSSINESS_WITH_DOCK_FORKLIFT) &&
        deliveryServices.includes(DELIVERY_RESIDENTIAL)
      ) {
        setValue(
          'delivery_service_options',
          deliveryServices.filter(
            (opt) =>
              opt !== DELIVERY_BUSSINESS_WITH_DOCK_FORKLIFT &&
              opt !== DELIVERY_RESIDENTIAL
          ),
          {
            shouldValidate: true
          }
        );
        toast.error(
          'You can select either Business with a Dock or Forklift or Residential at a time!'
        );
      }
    }
  }, [deliveryServices]);

  const onAddDelivery = async () => {
    if (!showDeliveryForm) {
      setShowDeliveryForm(true);
      return;
    }
    await trigger([
      'delivery_zip_code',
      'delivery_state',
      'delivery_to',
      'delivery_formatted_address',
      'delivery_date',
      'is_delivery_flexible',
      'delivery_time',
      'delivery_close_time',
      'is_delivery_time_flexible',
      'delivery_service_options',
      'delivery_special_instructions',
      'delivery_limited_access_type',
      'delivery_tradeshow_type',
      'delivery_id',
      'deliveryLocation'
    ]);

    if (!Object.keys(errors).length) {
      const newData = {
        delivery_zip_code: deliveryInfo.delivery_zip_code,
        delivery_state: deliveryInfo.delivery_state,
        delivery_to: deliveryInfo.delivery_to,
        delivery_formatted_address: deliveryInfo.delivery_formatted_address,
        delivery_date: deliveryInfo.delivery_date || '',
        is_delivery_flexible: deliveryInfo.is_delivery_flexible,
        delivery_time: deliveryInfo.delivery_time || '',
        delivery_close_time: deliveryInfo.delivery_close_time || '',
        is_delivery_time_flexible: deliveryInfo.is_delivery_time_flexible,
        delivery_service_options: deliveryInfo.delivery_service_options,
        delivery_special_instructions:
          deliveryInfo.delivery_special_instructions,
        delivery_limited_access_type:
          deliveryInfo.delivery_zipdelivery_limited_access_type_code,
        delivery_id: deliveryInfo.delivery_id || uuidv4(),
        deliveryLocation: deliveryInfo?.deliveryLocation,
        ...(deliveryInfo.delivery_address &&
          !IsChangeAdd && {
            delivery_address: deliveryInfo.delivery_address
          })
      };

      if (deliveryInfo.id) newData.id = deliveryInfo.id;
      if (isAddMode) {
        addDelivery(newData);
      } else {
        updateDelivery(newData);
      }

      window.scrollTo({
        top: 0,
        behavior: 'auto'
      });
      setShowDeliveryForm(false);
      setIsChangeAdd(false);
    }
  };

  const saveNewAddress = async () => {
    try {
      const companyId =
        authUser?.user?.usertype === Roles.SHIPPER
          ? authUser?.user?.companyId?.id
          : getContextVal('company')?.id;
      const deliveryData = {
        locationId: getValues('delivery_formatted_address')
          ?.split('|')?.[1]
          ?.trim(),
        name: getValues('delivery_formatted_address')?.split('|')?.[0]?.trim(),
        delivery_formatted_address: getValues('delivery_formatted_address')
          ?.split('|')?.[0]
          ?.trim(),
        delivery_to: getValues('delivery_to'),
        delivery_zip_code: getValues('delivery_zip_code'),
        delivery_state: getValues('delivery_state'),
        type: 'delivery',
        companyId,
        delivery_time: formatPickupDeliveryTime(
          getValues('delivery_time'),
          getValues('is_delivery_time_flexible')
        ),
        delivery_close_time: !getValues('delivery_close_time')
          ? ''
          : formatPickupDeliveryTime(getValues('delivery_close_time'), false),
        delivery_service_options: getValues('delivery_service_options'),
        service_options_type: [getValues('delivery_limited_access_type')]
          ?.filter((type) => type)
          .map((option) => option.value),
        is_delivery_time_flexible: getValues('is_delivery_time_flexible'),
        delivery_special_instructions: getValues(
          'delivery_special_instructions'
        )
      };

      validationSchema
        .validate(deliveryData, { abortEarly: false })
        .then(async () => {
          const response = await addLocationService(deliveryData);
          toast.success('Delivery address saved successfully!');
          setUserAddress([...userAddress, response?.data?.data]);
          setIsFromList(true);
        })
        .catch((error) => {
          console.log(error);
          return toast.error('Invalid data');
        });
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const suggestionsDivRef = useRef(null);

  const nextButtonClick = async () => {
    await onAddDelivery();
    if (getContextVal('deliveryInfo')?.length) {
      nextClick();
    }
  };

  const onAddressChange = (e) => {
    suggestionsDivRef.current.scrollTop = 0;
    setIsChangeAdd(true);
    setAddress(e);
  };

  return (
    <div>
      {showDeliveryForm && (
        <div>
          <div className="mb-3  p-4">
            <label
              className="w-full block text-black text-sm font-bold mb-2"
              htmlFor="ship-from"
            >
              Ship To
            </label>
            <PlacesAutocomplete
              value={address ?? ''}
              onChange={(e) => onAddressChange(e)}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <div>
                  <div className="flex gap-3 items-center">
                    <input
                      className="leading-tight shadow appearance-none w-full text-xs bg-gray-100 border-gray-200 border-solid border py-3 px-2"
                      {...getInputProps({ placeholder: 'Type address' })}
                    />
                    {!IsChangeAdd && (
                      <div className="inline-flex justify-end w-1/5">
                        <button
                          type="button"
                          onClick={() => setIsChangeAdd(true)}
                          className="flex py-2 px-2 gap-1 justify-center items-center h-10 whitespace-normal max-w-max border rounded-sm border-navy-500 hover:bg-navy-600 hover:text-white active:bg-navy-700 text-navy-500 
    hover:border-navy-600 active:border-navy-700 text-sm break-words"
                        >
                          <IoLocationSharp size={20} />
                          Change Address
                        </button>
                      </div>
                    )}
                  </div>
                  <div
                    className="overflow-scroll max-h-48"
                    ref={suggestionsDivRef}
                  >
                    {IsChangeAdd && (
                      <div className="mt-2 space-y-3 bg-gray-100 px-2 py-2 border-gray-200 border-solid border shadow-lg">
                        <div className="bg-gray-100 mt-3">
                          {suggestions.length > 0 && (
                            <>
                              <div className="flex flex-col gap-2">
                                <p className="text-gray-400">Other Address</p>
                                {loading ? <div>...loading</div> : null}
                                {suggestions.map((suggestion) => {
                                  return (
                                    <label
                                      key={suggestion.placeId}
                                      {...getSuggestionItemProps(suggestion)}
                                    >
                                      <input
                                        {...register(`selectedAddress`)}
                                        type="radio"
                                        value={suggestion.id}
                                      />

                                      <span className="">
                                        {suggestion.description}
                                      </span>
                                    </label>
                                  );
                                })}
                              </div>
                              <hr className="mt-3" />
                            </>
                          )}
                        </div>
                        <p className="text-gray-400">Default Address</p>
                        <div className="flex flex-col mb-4 mt-4">
                          {userAddress
                            ?.filter((addr) => addr.isDefault)
                            ?.map((addr) => {
                              return (
                                <label>
                                  <input
                                    {...register(`selectedAddress`)}
                                    type="radio"
                                    value={addr.id}
                                    checked={addr.name === address}
                                    onChange={() =>
                                      handleSelect(
                                        addr.name,
                                        addr.locationId,
                                        addr
                                      )
                                    }
                                  />
                                  <span>{addr.name}</span>
                                </label>
                              );
                            })}
                        </div>
                        <hr />
                        <div>
                          <p className="text-gray-400">Saved Address</p>
                          <div className="mb-4 mt-4 flex flex-col gap-2 ">
                            {userAddress
                              ?.filter((addr) => !addr.isDefault)
                              ?.map((addr) => {
                                return (
                                  <label key={addr.id}>
                                    <input
                                      {...register(`selectedAddress`)}
                                      type="radio"
                                      value={addr.id}
                                      onChange={() =>
                                        handleSelect(
                                          addr.name,
                                          addr.locationId,
                                          addr
                                        )
                                      }
                                      checked={addr.name === address}
                                    />
                                    <span className="">{addr.name}</span>
                                  </label>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            {errors?.delivery_zip_code && (
              <span className="text-xs text-red-700">
                {errors?.delivery_zip_code.message}
              </span>
            )}
          </div>
          <hr className="mx-3" />
          <div className="flex">
            <div className="flex-col w-full">
              <div className="pb-0 p-4">
                <label
                  className="w-full block text-black text-sm font-bold mb-2"
                  htmlFor="delivery-date"
                >
                  Delivery Date
                </label>
                <Controller
                  render={({ field: { ref, name, value, onChange } }) => (
                    <DatePickerInput
                      name={name}
                      ref={ref}
                      handleDateChange={(e) => onChange(e)}
                      selectedDate={value ?? ''}
                      isDisabled={isDateDisable ?? false}
                      classStyle="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-gray-100 py-3 px-2"
                    />
                  )}
                  name="delivery_date"
                  control={control}
                />
                {errors.pickup_date && (
                  <span className="text-xs text-red-700">
                    {errors.pickup_date.message}
                  </span>
                )}
              </div>
              <div className="mb-3 p-4">
                <label>
                  <input
                    {...register('is_delivery_flexible')}
                    type="checkbox"
                    defaultChecked={
                      !isDeliveryFlexible && !getValues('delivery_date')
                    }
                  />
                  <span className="ml-1 text-sm">
                    Flexible (within next week)
                  </span>
                </label>
                {errors.is_delivery_flexible && (
                  <span className="text-xs text-red-700">
                    {errors.is_delivery_flexible.message}
                  </span>
                )}
              </div>
            </div>
            <div className="flex-col w-full">
              <div className="pb-0 p-4">
                <label
                  className="w-full block text-black text-sm font-bold mb-2"
                  htmlFor="delivery-time"
                >
                  Delivery Time
                </label>
                <Controller
                  render={({ field: { ref, name, value, onChange } }) => (
                    <TimePickerInput
                      name={name}
                      ref={ref}
                      handleDateChange={(e) => onChange(e)}
                      selectedDate={value ?? ''}
                      isDisabled={isTimeDisable ?? false}
                      classStyle="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-gray-100 py-3 px-2"
                    />
                  )}
                  name="delivery_time"
                  control={control}
                />
                {errors.delivery_time && (
                  <span className="text-xs text-red-700">
                    {errors.delivery_time.message}
                  </span>
                )}
              </div>

              <div className="mb-3 p-4">
                <label>
                  <input
                    {...register('is_delivery_time_flexible')}
                    type="checkbox"
                    defaultChecked={
                      !isDeliveryTimeFlexible && !getValues('delivery_time')
                    }
                  />
                  <span className="ml-1 text-sm">Flexible (8am-5pm)</span>
                </label>
                {errors.is_delivery_time_flexible && (
                  <span className="text-xs text-red-700">
                    {errors.is_delivery_time_flexible.message}
                  </span>
                )}
              </div>
            </div>
            <div className="mb-3 pb-0 p-4 w-full">
              <label
                className="w-full block text-black text-sm font-bold mb-2"
                htmlFor="pickup-time"
              >
                Delivery Close Time
              </label>
              <Controller
                render={({ field: { ref, name, value, onChange } }) => (
                  <TimePickerInput
                    name={name}
                    ref={ref}
                    handleDateChange={(e) => onChange(e)}
                    selectedDate={value ?? ''}
                    classStyle="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-gray-100 py-3 px-2"
                    isDisabled={isTimeDisable}
                  />
                )}
                name="delivery_close_time"
                control={control}
              />
              {errors.delivery_close_time && (
                <span className="text-xs text-red-700">
                  {errors.delivery_close_time.message}
                </span>
              )}
            </div>
          </div>
          <hr className="mx-3" />

          <div className=" p-4">
            <label
              className="w-full block text-black text-sm font-bold mb-2"
              htmlFor="delivery-date"
            >
              Select Delivery Services
            </label>
            <div className="grid grid-cols-4 mt-6">
              {DELIVERY_SERVICE_OPTION.map((deliveryOption) => {
                return (
                  <div className="mb-4" key={deliveryOption.value}>
                    <label>
                      <input
                        {...register('delivery_service_options')}
                        type="checkbox"
                        value={deliveryOption.value}
                        defaultChecked={
                          isAddMode &&
                          deliveryOption.value === '57fb6370b13762ee6a3f94d0'
                        }
                      />
                      <span className="ml-1">{deliveryOption.label}</span>
                    </label>
                  </div>
                );
              })}
            </div>
            {errors.delivery_service_options && (
              <span className="text-xs text-red-700">
                {errors.delivery_service_options.message}
              </span>
            )}
            {errors?.delivery_limited_access_type && (
              <span className="text-xs text-red-700 block">
                {errors?.delivery_limited_access_type?.message}
              </span>
            )}
            {errors?.delivery_tradeshow_type && (
              <span className="text-xs text-red-700 block">
                {errors?.delivery_tradeshow_type?.message}
              </span>
            )}
          </div>

          <div className="mb-3  p-4">
            <label
              className="w-full block text-black text-sm font-bold mb-2"
              htmlFor="delivery-date"
            >
              Special delivery instructions
            </label>
            <textarea
              {...register('delivery_special_instructions')}
              className="leading-tight shadow appearance-none w-full bg-gray-100 border-gray-200 border-solid border py-2 pb-10 px-2"
              placeholder="Write your instructions here"
            />
          </div>
          {errors.delivery_special_instructions && (
            <span className="text-xs text-red-700">
              {errors.delivery_special_instructions.message}
            </span>
          )}
          {!IsFromList && (
            <div className="p-4 mt-10">
              <button
                type="button"
                onClick={() => saveNewAddress()}
                className="bg-navy-500 text-white px-4 py-2 text-sm font-semibold"
              >
                Save Delivery Address
              </button>
            </div>
          )}
        </div>
      )}
      <div className="flex flex-col xl:flex-row w-full">
        <div className="text-left p-4 relative xl:w-1/2">
          <button
            type="button"
            onClick={() => onAddDelivery(deliveryInfo.delivery_id)}
            className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-4
                        bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                      hover:border-navy-600 active:border-navy-700  text-sm font-bold transition duration-200 text-left"
          >
            Add Delivery
            <RightArrowIcon className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-6 font-bold" />
          </button>
        </div>
        <div className="text-left p-4 relative xl:w-1/2">
          <button
            type="submit"
            // disabled={isSubmitting}
            onClick={nextButtonClick}
            className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-4 
                        bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700
                     text-sm font-bold transition duration-200 text-left"
          >
            {/* {buttonLabels[step - 1]} */}
            Proceed
            <RightArrowIcon className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-6 font-bold" />
          </button>
        </div>
      </div>
    </div>
  );
};

AddDelivery.propTypes = {
  isAddMode: PropTypes.bool.isRequired,
  addDelivery: PropTypes.func.isRequired,
  updateDelivery: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  serviceType: PropTypes.string.isRequired,
  nextClick: PropTypes.any.isRequired,
  showDeliveryForm: PropTypes.bool.isRequired,
  setShowDeliveryForm: PropTypes.func.isRequired,
  changedDeliveries: PropTypes.array.isRequired,
  setChangedDeliveries: PropTypes.func.isRequired
};

export default AddDelivery;

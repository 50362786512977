/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import * as yup from 'yup';
import Shipments from '../../components/Shipments';
import DatePickerInput from '../../components/DatePickerInput/DatePickerInput';
import {
  carrierInvitationService,
  dashboardService,
  shipperInvitationService
} from '../../services/salesrep.service';

const DashboardPage = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const navigate = useNavigate();
  const [isAPICalled, setisAPICalled] = useState(false);
  const [chartData, setChartData] = useState();
  const [completedTotalAmt, setcompletedTotal] = useState(0);
  const [inviteUser, setInviteUser] = useState('shipper');
  const [inputValue, setinputValue] = useState('');
  const [emails, setEmails] = useState([]);
  const [phones, setPhones] = useState([]);
  const [salesReps, setAllSalesRep] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [DOTNumber, setDOTNumber] = useState();
  const [MCNumber, setMCNumber] = useState();

  const emailSchema = yup.string().email('Please enter a valid email.');
  const phoneSchema = yup
    .string()
    .matches(
      /^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/gm,
      'Mobile number must be only digits'
    )
    .required('Mobile number is required');
  const fetchDashboardData = debounce(async (month) => {
    try {
      const response = await dashboardService(month ?? selectedMonth, null);
      // completedTotal,
      const { bidStatuses, recentQuotes, completedTotal, allSalesRep } =
        response.data;
      setcompletedTotal(completedTotal);
      setAllSalesRep(allSalesRep);
      setChartData({
        labels: ['Recent', 'Pending', 'Assigned', 'En-route', 'Delivered'],
        datasets: [
          {
            label: 'Number of Quotes',
            data: [
              recentQuotes,
              bidStatuses.bidAdded,
              bidStatuses.bidAccepted,
              bidStatuses.bidEnroute,
              bidStatuses.bidCompleted
            ],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)'
              // 'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)'
              // 'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 0
          }
        ]
      });
      setisAPICalled(true);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }, 1);

  const fetchData = async () => {
    const link = localStorage.getItem('link');
    if (link) {
      localStorage.removeItem('link');
      return navigate(link);
    }

    try {
      await fetchDashboardData();
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchData();

    return () => {
      setChartData();
    };
  }, []);

  const options = {
    scales: {
      y: {
        ticks: {
          beginAtZero: true
        }
      }
    },
    // maintainAspectRatio: false,
    legend: {
      position: 'bottom',
      display: true,
      onClick: (e) => {
        if (e.event) {
          e.event.preventDefault();
        }
      },
      labels: {
        fontColor: 'rgb(35, 31, 32)',
        usePointStyle: true,
        boxWidth: 9,
        fontSize: 16
      }
    },
    // maintainAspectRatio: false,
    responsive: true,
    cutoutPercentage: 50
  };

  const isEmail = (email) => {
    return emailSchema.isValidSync(email);
  };

  const isPhone = (phone) => {
    const cleanedPhone = phone.replace(/\D/g, '');
    // Ensure the original input contains only digits, or return false
    if (!/^\d+$/.test(phone)) {
      return false;
    }
    return phoneSchema.isValidSync(cleanedPhone);
  };

  const isValid = (email) => {
    let error = null;
    let type = null;
    if (
      !emails.length &&
      !phones.length &&
      !isEmail(email) &&
      !isPhone(email)
    ) {
      error = 'Enter valid email address/ phone number.';
    } else {
      if (isEmail(email)) {
        type = 'email';
      }
      if (isPhone(email)) {
        type = 'phone';
      }
      if (!isEmail(email) && !isPhone(email)) {
        error = `${email} is not a valid email address/phone number.`;
      }
    }
    if (error) {
      setErrorMessage(error);

      return { status: false };
    }
    setErrorMessage(null);
    return { status: true, type };
  };

  const emailHandler = (val) => {
    setinputValue(val);
    const emailArray = val.split(',').map((email) => email.trim());
    const emailArr = [];
    const phoneArr = [];
    emailArray.map((input, index) => {
      const checkValid = isValid(input);
      if (checkValid.status && input !== '') {
        if (
          emailArr.some((i) => i === input || phoneArr.some((e) => e === input))
        ) {
          setErrorMessage(`${input} has already been added.`);
        }
        if (checkValid.type === 'email') {
          emailArr[index] = emailArray[index];
          setEmails(emailArr);
        }
        if (checkValid.type === 'phone') {
          phoneArr[index] = emailArray[index];
          setPhones(phoneArr);
        }
      }
      return input;
    });
  };

  const onSendInvite = async () => {
    if (errorMessage) {
      return toast.error(errorMessage);
    }
    if (!emails.length && !phones.length) {
      return toast.error('Enter atleast one email/phone before invite.');
    }
    try {
      const emailList = emails.filter((e) => e);
      const phoneList = phones.filter((v) => v).map((p) => Number(p));
      const response = await shipperInvitationService({
        to: { emails: emailList, phones: phoneList },
        usertype: inviteUser
      });
      if (response.data.flag) {
        toast.success(response.data.message);
        setEmails([]);
        setPhones([]);
        setinputValue('');
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const monthStatChange = (val) => {
    setSelectedMonth(val);
    fetchDashboardData(val);
  };

  const inviteChangeHandler = (val) => {
    setInviteUser(val);
    setDOTNumber('');
    setMCNumber('');
  };

  const onCarrierSendInvite = async (type) => {
    try {
      if (!DOTNumber && !MCNumber)
        return toast.error('Please enter DOT or MC number to invite carrier');
      const mcNumberReplaced = !MCNumber?.includes('MC')
        ? `MC${MCNumber}`
        : MCNumber;

      const response = await carrierInvitationService({
        type,
        number: type === 'dot' ? DOTNumber : mcNumberReplaced,
        usertype: inviteUser
      });

      if (type === 'dot') {
        setDOTNumber('');
      } else {
        setMCNumber('');
      }
      return toast.success(response?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  return (
    <section>
      <div className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="w-full md:flex flex-wrap">
            <div className="w-full md:w-1/2 inline-block">
              <div className="mb-6 p-4 relative pl-0">
                <h3 className="text-3xl font-bold mb-2 text-navy-500">
                  This month&apos;s stats
                </h3>
                {isAPICalled && chartData && (
                  <>
                    <div className="mb-4 w-1/2">
                      <DatePickerInput
                        name=""
                        handleDateChange={monthStatChange}
                        selectedDate={selectedMonth}
                        placeholderText="Select month"
                        monthPicker
                        minDate=""
                        dateFormat="MM/yyyy"
                        isDisabled={false}
                      />
                    </div>
                    <div className="p-4 border border-gray-300">
                      <Bar data={chartData} options={options} />
                      <h3 className="mt-3 ">
                        <span className="text-gray-500 font-bold">
                          Total completed quote amount:
                        </span>{' '}
                        <span className="font-bold">${completedTotalAmt}</span>
                      </h3>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="w-full md:w-1/2 inline-block">
              <div className="mb-6 p-4 relative pl-0">
                <h3 className="text-3xl font-bold mb-2 text-navy-500">
                  Invite
                  <span className="ml-2 font-bold">
                    <select
                      className="font-bold pr-2 bg-gray-50"
                      value={inviteUser}
                      onChange={(e) => inviteChangeHandler(e.target.value)}
                    >
                      <option value="shipper">Shipper</option>
                      <option value="carrier">Carrier</option>
                    </select>
                  </span>
                </h3>
                <div className="p-4 border border-gray-300 inline-block w-full">
                  {inviteUser === 'carrier' ? (
                    <div className="flex justify-between items-center">
                      <div>
                        <label className="text-sm ">Enter DOT number</label>
                        <input
                          type="text"
                          className="block w-full my-2 px-4 py-2 bg-white border rounded-md focus:border-navy-500 focus:ring-navy-500 focus:outline-none focus:ring focus:ring-opacity-40"
                          placeholder="Enter DOT number"
                          onChange={(e) => setDOTNumber(e.target.value)}
                          value={DOTNumber}
                        />

                        <button
                          className="px-4 py-2 text-white bg-navy-500 border-l rounded hover:bg-navy-600 active:bg-navy-700"
                          type="button"
                          onClick={() => onCarrierSendInvite('dot')}
                        >
                          Send Invite
                        </button>
                      </div>
                      <p>OR</p>
                      <div>
                        <label className="text-sm ">Enter MC number</label>
                        <input
                          type="text"
                          className="block w-full my-2 px-4 py-2 bg-white border rounded-md focus:border-navy-500 focus:ring-navy-500 focus:outline-none focus:ring focus:ring-opacity-40"
                          placeholder="Enter MC number"
                          onChange={(e) => setMCNumber(e.target.value)}
                          value={MCNumber}
                        />
                        <button
                          className="px-4 py-2 text-white bg-navy-500 border-l rounded hover:bg-navy-600 active:bg-navy-700"
                          type="button"
                          onClick={() => onCarrierSendInvite('mc')}
                        >
                          Send Invite
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <p className="mb-3 mt-1">Enter your email/phone number</p>
                      <textarea
                        onInput={(event) => emailHandler(event.target.value)}
                        // {...register('emails')}
                        value={inputValue}
                        placeholder="Enter Comma seperated valid emails. Example: steve@example.com, vest@example.com"
                        className="appearance-none w-full resize-none h-44 p-4 text-xm font-semibold leading-none bg-white  outline-none border border-gray-200"
                      />
                      {errorMessage && (
                        <span className="text-md text-red-700">
                          {errorMessage}
                        </span>
                      )}
                      <button
                        type="button"
                        onClick={onSendInvite}
                        className="my-2 border-2 bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700
                                hover:border-navy-600 active:border-navy-700
                      text-white text-sm font-bold transition duration-200 text-left relative px-4 py-3 md:float-right"
                      >
                        Send Invite{' '}
                      </button>
                    </>
                  )}
                  {/* <textarea className="w-full resize-none h-44 border border-gray-300" /> */}
                </div>
              </div>
            </div>
          </div>
          <Shipments allSalesRep={salesReps ?? []} />
        </div>
      </div>
    </section>
  );
};

export default DashboardPage;

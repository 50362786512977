import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import Footer from '../../components/Footer';
import ProtectedHeader from '../../components/ProtectedHeader';
import RightArrowIcon from '../../components/RightArrowIcon';

// import NETLogo from '../assets/images/Screen-Shot-2021-08-12-at-1-03-20-PM.png';

const PageNotFound = memo(() => {
  return (
    <>
      <ProtectedHeader />
      <section>
        <div className="py-20 bg-gray-50">
          <div className="container mx-auto px-4">
            <div className="mb-6  p-4 relative pl-0 text-center">
              <h2 className="text-9xl font-bold text-navy-500">404</h2>
              <h3 className="text-4xl font-bold ">Page not found.</h3>
              <p className="text-gray-500 text-2xl">
                Sorry, couldn&apos;t find the page you&apos;re looking for.
              </p>
              <p className="text-2xl text-navy-500 hover:text-navy-600 active:text-navy-600">
                <Link to="/">
                  Go back home <RightArrowIcon />
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
});

export default PageNotFound;

import React from 'react';

import CarrierTableView from '../../components/CarrierTableView/CarrierTableView';

const CarrierListPage = () => {
  return (
    <section>
      <div className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <CarrierTableView />
        </div>
      </div>
    </section>
  );
};

export default CarrierListPage;

import React, { useEffect, useState } from 'react';

import toast from 'react-hot-toast';

import QuoteDetailsCard from '../../components/QuoteDetailsCard/QuoteDetailsCard';

import { getAllActiveQuotesService } from '../../services/quote.service';

const ActiveShipmentPage = () => {
  const [quotes, setQuotes] = useState([]);
  const [isAPICalled, setisAPICalled] = useState(false);
  const [page, setPage] = useState(1);
  const [isEnd, setIsEnd] = useState(false);

  async function fetchAvailableLoads(isReset, pageNo) {
    try {
      const response = await getAllActiveQuotesService(
        encodeURIComponent(pageNo || 1),
        encodeURIComponent(10)
      );

      if (!response?.data?.quotes?.length) setIsEnd(true);

      const finalRes = isReset
        ? response?.data?.quotes || []
        : [...quotes, ...(response?.data?.quotes || [])];

      if (response.data.flag) {
        setQuotes(finalRes);
        setisAPICalled(true);
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    fetchAvailableLoads(true);
  }, []);

  const handleLoadMore = () => {
    fetchAvailableLoads(undefined, page + 1);
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <section>
      <div className="skew skew-top mr-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 10 0 10" />
        </svg>
      </div>
      <div className="skew skew-top ml-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 10 10 0 10 10" />
        </svg>
      </div>
      <div className="py-20 bg-gray-50 radius-for-skewed">
        <div className="container mx-auto px-4">
          <div className="mb-6  p-4 relative pl-0">
            <h3 className="text-3xl font-bold text-navy-500">Assigned</h3>
          </div>
          <div className="flex flex-wrap -mx-3">
            {isAPICalled && quotes.length > 0
              ? quotes.map((quote) => (
                  <QuoteDetailsCard
                    key={quote.id}
                    quote={quote}
                    loadType="shipper-active-shipment"
                  />
                ))
              : 'No bids to display'}
          </div>
          {!isEnd ? (
            <button
              type="submit"
              className="bg-navy-500 text-white px-10 py-2 text-sm font-semibold float-right mt-4"
              onClick={handleLoadMore}
            >
              Load More
            </button>
          ) : (
            <p className="text-center">No more data</p>
          )}
        </div>
      </div>
      <div className="skew skew-bottom mr-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 0 0 10" />
        </svg>
      </div>
      <div className="skew skew-bottom ml-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 0 10 10" />
        </svg>
      </div>
    </section>
  );
};

export default ActiveShipmentPage;

import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { ReactComponent as RightArrow } from '../../assets/images/right_arrow.svg';
import useSendInvoice from '../../customHooks/useSendInvoice';

const SendInvoiceButton = ({
  quoteId,
  title,
  showArrow,
  styling,
  fetchOpenInvoices
}) => {
  const handleSendInvoice = useSendInvoice();
  const handleSend = async (e) => {
    try {
      e.preventDefault();
      await handleSendInvoice([quoteId]);
      if (!showArrow) {
        fetchOpenInvoices(true, 1);
      }
    } catch (error) {
      console.log({ error });
      toast.error(error.message ?? 'Something went wrong!');
    }
  };
  return (
    <button type="button" onClick={handleSend} className={styling}>
      {showArrow ? `${title} Invoice` : title}
      {showArrow && <RightArrow className="w-5 h-5 font-bold" />}
    </button>
  );
};

export default SendInvoiceButton;

SendInvoiceButton.propTypes = {
  quoteId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  styling: PropTypes.string,
  showArrow: PropTypes.bool,
  fetchOpenInvoices: PropTypes.func.isRequired
};

SendInvoiceButton.defaultProps = {
  showArrow: false,
  styling: ''
};

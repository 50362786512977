import { useIsInvoiceAuthorizedUser } from '../invoice.constants';

// NOTE: defining constant functions as custom hooks because React Hooks(in this case useAuthContext) must be called in a React function component or a custom React Hook function

const useGetSalesRepSidebarRoutes = () => {
  const isInvoiceAuthorizedUser = useIsInvoiceAuthorizedUser();
  const routes = [
    {
      route: '/dashboard',
      label: 'Dashboard'
    },
    {
      route: '/customers',
      label: 'Associated Customers'
    },
    {
      route: '/customerReps',
      label: 'Associated Customer Representatives'
    },
    {
      route: '/salesRep/carriers',
      label: 'Carriers'
    },
    {
      route: '/new-quote',
      label: 'New Quote'
    },
    {
      route: '/salesRep/recentQuotes',
      label: 'Recent Quotes'
    },
    {
      route: '/salesRep/pending-quotes',
      label: 'Pending'
    },
    {
      route: '/salesRep/active-shipment',
      label: 'Assigned'
    },
    {
      route: '/salesRep/enroute-quotes',
      label: 'Enroute'
    },
    {
      route: '/salesRep/completed-quotes',
      label: 'Delivered'
    }
  ];

  if (isInvoiceAuthorizedUser) {
    routes.push({
      route: '/salesRep/open-invoices',
      label: 'Invoice'
    });
  }
  routes.push({
    route: '/salesRep/general-settings',
    label: 'General Settings'
  });

  return routes;
};

export default useGetSalesRepSidebarRoutes;

import API from './api.services';
import API_ENDPOINTS from '../constants/api-endpoints.constants';
import Roles from '../constants/roles.constants';

const addQuoteService = (body) => {
  return API.post(API_ENDPOINTS.QUOTE.AddQuote, body, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    }
  });
};

const updateShipmentService = (body) => {
  return API.post(API_ENDPOINTS.QUOTE.UpdateShipment, body, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    }
  });
};

const deleteShipmentService = (body) => {
  return API.post(API_ENDPOINTS.QUOTE.DeleteShipment, body, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    }
  });
};

const getQuoteDetailsService = (quoteId) => {
  return API.get(`${API_ENDPOINTS.QUOTE.GetQuote}/${quoteId}`, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const updateCarrierType = (body) => {
  return API.post(API_ENDPOINTS.QUOTE.updateCarrierType, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const sendEmails = (body) => {
  return API.post(API_ENDPOINTS.QUOTE.sendEmails, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const getAllRecentQuotes = (page, limit, body = {}, searchText = '') => {
  return API.post(API_ENDPOINTS.QUOTE.RecentQuotes, body, {
    params: {
      token: localStorage.getItem('token'),
      searchText,
      page,
      limit
    }
  });
};

const getAllActiveQuotesService = (page, limit, body = {}, searchText = '') => {
  return API.post(`${API_ENDPOINTS.QUOTE.GetAllQuotes}`, body, {
    params: {
      token: localStorage.getItem('token'),
      status: JSON.stringify([6]),
      isPickupByCarrier: false,
      searchText,
      platform: 'web',
      page,
      limit
    }
  });
};

const getAllPendingQuotesService = (
  page,
  limit,
  body = {},
  searchText = ''
) => {
  return API.post(`${API_ENDPOINTS.QUOTE.GetAllQuotes}`, body, {
    params: {
      token: localStorage.getItem('token'),
      status: JSON.stringify([1, 2]),
      searchText,
      platform: 'web',
      page,
      limit
    }
  });
};

const getAllEnrouteQuotesService = (
  page,
  limit,
  body = {},
  searchText = ''
) => {
  return API.post(`${API_ENDPOINTS.QUOTE.GetAllQuotes}`, body, {
    params: {
      token: localStorage.getItem('token'),
      status: JSON.stringify([6]),
      isPickupByCarrier: true,
      searchText,
      platform: 'web',
      page,
      limit
    }
  });
};

const getAllCompletedQuotesService = (
  page,
  limit,
  body = {},
  searchText = ''
) => {
  return API.post(`${API_ENDPOINTS.QUOTE.GetAllQuotes}`, body, {
    params: {
      token: localStorage.getItem('token'),
      status: JSON.stringify([3]),
      searchText,
      platform: 'web',
      page,
      limit
    }
  });
};

const markAsCompletedService = (body) => {
  return API.post(API_ENDPOINTS.QUOTE.MarkAsCompleted, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const uploadCargoFileService = (formData) => {
  return API.post(API_ENDPOINTS.FILES.AddFile, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const bookNowQuoteService = (body) => {
  return API.post(API_ENDPOINTS.QUOTE.BookNowQuote, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const updateAddressService = (formData) => {
  return API.post(API_ENDPOINTS.QUOTE.Address, formData, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    },
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const trackShipmentService = (quoteId) => {
  return API.post(
    API_ENDPOINTS.QUOTE.TrackShipment,
    { quoteId },
    {
      params: {
        token: localStorage.getItem('token')
      }
    }
  );
};

const trackingService = (quoteId) => {
  return API.post(
    API_ENDPOINTS.QUOTE.Tracking,
    { quoteId },
    {
      params: {
        token: localStorage.getItem('token')
      }
    }
  );
};

const filterQuotesService = (body) => {
  return API.post(API_ENDPOINTS.QUOTE.FilterQuotes, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const generateBOLService = (body) => {
  return API.post(API_ENDPOINTS.QUOTE.DownloadBOL, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const generateROCService = (body) => {
  return API.post(API_ENDPOINTS.QUOTE.DownloadROC, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const guestTrackingDataService = (number) => {
  return API.get(API_ENDPOINTS.QUOTE.GuestTrackingData, {
    params: {
      internalTrackingNumber: number
    }
  });
};

const loadAddressService = (companyId, type) => {
  return API.get(`${API_ENDPOINTS.QUOTE.LoadAddress}/${companyId}`, {
    params: {
      type,
      token: localStorage.getItem('token')
    }
  });
};

const refreshLtlRatesService = (quoteId) => {
  return API.get(API_ENDPOINTS.QUOTE.RefreshLtlRates, {
    params: {
      quoteId,
      token: localStorage.getItem('token')
    }
  });
};

const addCargoService = (body) => {
  return API.post(API_ENDPOINTS.QUOTE.AddCargo, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const addAdditionalChargesService = (body) => {
  return API.post(API_ENDPOINTS.QUOTE.AddAdditionalCharges, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};
const searchQuotes = (body, authUser) => {
  return API.post(
    authUser?.user?.usertype === Roles.CARRIER
      ? API_ENDPOINTS.CARRIER.SearchQuotes
      : API_ENDPOINTS.QUOTE.SearchQuotes,
    body,
    {
      params: {
        token: localStorage.getItem('token')
      }
    }
  );
};
const getDashboardTableData = (
  limit,
  skip,
  body,
  sorting,
  isNotGeneratedQuote,
  exportPDF
) => {
  const tempParams = {
    params: {
      token: localStorage.getItem('token'),
      limit,
      skip,
      sorting,
      exportPDF,
      isNotGeneratedQuote
    }
  };

  if (exportPDF) {
    tempParams.responseType = 'blob';
  }
  return API.post(API_ENDPOINTS.QUOTE.DashboardTable, body, tempParams);
};

export {
  addQuoteService,
  updateShipmentService,
  deleteShipmentService,
  getAllRecentQuotes,
  getQuoteDetailsService,
  getAllActiveQuotesService,
  getAllPendingQuotesService,
  getAllEnrouteQuotesService,
  getAllCompletedQuotesService,
  markAsCompletedService,
  uploadCargoFileService,
  bookNowQuoteService,
  updateAddressService,
  trackShipmentService,
  trackingService,
  filterQuotesService,
  generateBOLService,
  generateROCService,
  updateCarrierType,
  sendEmails,
  guestTrackingDataService,
  loadAddressService,
  refreshLtlRatesService,
  addCargoService,
  addAdditionalChargesService,
  searchQuotes,
  getDashboardTableData
};

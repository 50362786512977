import React, { memo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { ReactComponent as PackageSVG } from '../../assets/images/package.svg';

const ShipperSuccessPage = memo(() => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const tracking = new URLSearchParams(search).get('tracking');
  const pro = new URLSearchParams(search).get('pro');
  const quote = new URLSearchParams(search).get('quote');
  const pickup = new URLSearchParams(search).get('confirmation');

  const onAddQuote = () => {
    navigate('/new-quote', { replace: true });
  };

  return (
    <section>
      <div className="skew skew-top mr-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 10 0 10" />
        </svg>
      </div>
      <div className="skew skew-top ml-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 10 10 0 10 10" />
        </svg>
      </div>
      <div className="py-20 bg-gray-50 radius-for-skewed">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap -mx-3 items-center justify-center">
            <div className="w-full lg:w-auto mb-10 lg:mb-0 text-center lg:text-center p-4">
              <div className="max-w-xl mx-auto lg:mx-0 text-navy-500 leading-loose mt-20">
                <PackageSVG className="mx-auto" />
              </div>
              <div className="max-w-xl mx-auto lg:mx-0 text-black  mt-5 text-xl">
                {pathname === '/shipper-thank-you' && (
                  <>
                    <p className="text-navy-500 font-bold">
                      Your load has been posted and you will get notified when
                      your load has been accepted.{' '}
                    </p>
                    {tracking ? (
                      <p className="mt-6">
                        Your tracking # is:
                        <span className="ml-2">{tracking}</span>
                      </p>
                    ) : (
                      ''
                    )}
                  </>
                )}

                {pathname === '/thank-you' && (
                  <>
                    <p className="text-navy-500 font-bold">
                      {' '}
                      Your shipment has been booked.
                    </p>
                    {quote ? (
                      <p className="mt-6">
                        Your Quote # is:
                        <span className="ml-2">{quote}</span>
                      </p>
                    ) : (
                      ''
                    )}
                    {pro ? (
                      <p className="mt-6">
                        Your PRO # is:
                        <span className="ml-2">{pro}</span>
                      </p>
                    ) : (
                      ''
                    )}
                    {pickup ? (
                      <p className="mt-6">
                        Your PICKUP # is:
                        <span className="ml-2">{pickup}</span>
                      </p>
                    ) : (
                      ''
                    )}
                    {tracking ? (
                      <p className="mt-6">
                        Your tracking # is:
                        <span className="ml-2">{tracking}</span>
                      </p>
                    ) : (
                      ''
                    )}
                    <p className="mt-6">
                      We will email you the Bill of Lading along with tracking
                      information.
                    </p>
                  </>
                )}

                <p className="mt-6">
                  Have an immediate question? Please{' '}
                  <a
                    href="mailto:tracking@nationwidetrans.com"
                    className=" font-bold"
                  >
                    Email
                  </a>{' '}
                  or{' '}
                  <a href="tel:+18669228855" className="font-bold">
                    Call
                  </a>{' '}
                  us.
                </p>
              </div>
              <div className="my-20">
                <button
                  type="button"
                  onClick={onAddQuote}
                  className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-4 
                    bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700 
                    text-md font-bold transition duration-200 text-center"
                >
                  Add New Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="skew skew-bottom mr-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 0 0 10" />
        </svg>
      </div>
      <div className="skew skew-bottom ml-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 0 10 10" />
        </svg>
      </div>
    </section>
  );
});

export default ShipperSuccessPage;

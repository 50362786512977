import React from 'react';

const ChevronRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g id="Down_arrow" transform="translate(0 36) rotate(-90)">
        <g
          id="Rectangle_3"
          data-name="Rectangle 3"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="36" height="36" stroke="none" />
          <rect x="0.5" y="0.5" width="35" height="35" fill="none" />
        </g>
        <path
          id="Path_4"
          data-name="Path 4"
          d="M2.5,24.409,4.42,22.5,14.5,32.656,24.58,22.5l1.92,1.909L14.5,36.5Z"
          transform="translate(3 -11.064)"
          fill="#1d4690"
        />
      </g>
    </svg>
  );
};

export default ChevronRightIcon;

const CarrierTypes = [
  { label: 'Dispatcher', value: 1 },
  { label: 'Owner Operator', value: 2 },
  { label: 'Company Driver', value: 3 }
];

const CarrierTrucks = [
  { label: '1 - Owner Op', value: 1 },
  { label: '2-5 Small', value: 2 },
  { label: '6-15 Small/Medium', value: 3 },
  {
    label: '16-50 Medium',
    value: 4
  },
  {
    label: '50 + Large',
    value: 5
  }
];

const AreasOfOperation = [
  { label: '50 Miles', value: '50 Miles' },
  { label: '100 Miles', value: '100 Miles' },
  { label: '200 Miles', value: '200 Miles' }
];

const EquipmentTypes = [
  { label: 'Lowboy', value: 'Lowboy' },
  { label: 'RGN-  Removable Gooseneck', value: 'RGN-  Removable Gooseneck' },
  { label: "26' Box Truck", value: "26' Box Truck" },
  { label: "40'Hotshot", value: "40'Hotshot" },
  { label: "48'Flatbed", value: "48'Flatbed" },
  { label: "48'Stepdeck", value: "48'Stepdeck" },
  { label: "48' Air ride flatbed", value: "48' Air ride flatbed" },
  { label: "48' Air ride stepdeck", value: "48' Air ride stepdeck" },
  { label: "48-53' Conestoga", value: "48-53' Conestoga" },
  { label: "53'Dryvan", value: "53'Dryvan" },
  { label: "52'Flatbed", value: "52'Flatbed" },
  { label: "53' Reefer", value: "53' Reefer" },
  { label: "53'Stepdeck", value: "53'Stepdeck" },
  { label: "53'Air ride Flatbed", value: "53'Air ride Flatbed" },
  { label: "53' Air ride StepDeck", value: "53' Air ride StepDeck" },
  { label: "53'Air ride Dry Van", value: "53'Air ride Dry Van" },
  { label: 'Sprinter Van', value: 'Sprinter Van' }
];

const VENDORS = [
  { display_name: 'ABF', name: 'abf', service: 'standard' },
  { display_name: 'XPO', name: 'xpo', service: 'standard' },
  { display_name: 'FAC', name: 'forwardair', service: 'standard' },
  { display_name: 'FEDEX', name: 'fedex', service: 'fedex freight economy' }
];

const States = [
  { label: 'AL', value: 'AL' },
  { label: 'AK', value: 'AK' },
  { label: 'AS', value: 'AS' },
  { label: 'AZ', value: 'AZ' },
  { label: 'AR', value: 'AR' },
  { label: 'BI', value: 'BI' },
  { label: 'CA', value: 'CA' },
  { label: 'CO', value: 'CO' },
  { label: 'CT', value: 'CT' },
  { label: 'DE', value: 'DE' },
  { label: 'DC', value: 'DC' },
  { label: 'FL', value: 'FL' },
  { label: 'GA', value: 'GA' },
  { label: 'GU', value: 'GU' },
  { label: 'HI', value: 'HI' },
  { label: 'ID', value: 'ID' },
  { label: 'IL', value: 'IL' },
  { label: 'IN', value: 'IN' },
  { label: 'IA', value: 'IA' },
  { label: 'JI', value: 'JI' },
  { label: 'JA', value: 'JA' },
  { label: 'KS', value: 'KS' },
  { label: 'KY', value: 'KY' },
  { label: 'KR', value: 'KR' },
  { label: 'LA', value: 'LA' },
  { label: 'ME', value: 'ME' },
  { label: 'MD', value: 'MD' },
  { label: 'MA', value: 'MA' },
  { label: 'MI', value: 'MI' },
  { label: 'MA', value: 'MA' },
  { label: 'MN', value: 'MN' },
  { label: 'MS', value: 'MS' },
  { label: 'MO', value: 'MO' },
  { label: 'MT', value: 'MT' },
  { label: 'NI', value: 'NI' },
  { label: 'NE', value: 'NE' },
  { label: 'NV', value: 'NV' },
  { label: 'NH', value: 'NH' },
  { label: 'NJ', value: 'NJ' },
  { label: 'NM', value: 'NM' },
  { label: 'NY', value: 'NY' },
  { label: 'NC', value: 'NC' },
  { label: 'ND', value: 'ND' },
  { label: 'MP', value: 'MP' },
  { label: 'OH', value: 'OH' },
  { label: 'OK', value: 'OK' },
  { label: 'OR', value: 'OR' },
  { label: 'PA', value: 'PA' },
  { label: 'PA', value: 'PA' },
  { label: 'PR', value: 'PR' },
  { label: 'RI', value: 'RI' },
  { label: 'SC', value: 'SC' },
  { label: 'SD', value: 'SD' },
  { label: 'TN', value: 'TN' },
  { label: 'TX', value: 'TX' },
  { label: 'UM', value: 'UM' },
  { label: 'VI', value: 'VI' },
  { label: 'UT', value: 'UT' },
  { label: 'VT', value: 'VT' },
  { label: 'VA', value: 'VA' },
  { label: 'WA', value: 'WA' },
  { label: 'WA', value: 'WA' },
  { label: 'WV', value: 'WV' },
  { label: 'WI', value: 'WI' },
  { label: 'WY', value: 'WY' }
];

const options = [
  { value: 100, label: '100' },
  { value: 200, label: '200' },
  { value: 300, label: '300' }
];
const serviceTypeOptions = [
  { value: 'Full & Partial', label: 'Full & Partial' },
  { value: 'Full', label: 'Full' },
  { value: 'Partial', label: 'Partial' }
];
export {
  CarrierTrucks,
  CarrierTypes,
  AreasOfOperation,
  EquipmentTypes,
  VENDORS,
  States,
  options,
  serviceTypeOptions
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Controller, useFormContext } from 'react-hook-form';

import Select from 'react-select';
import {
  CarrierTrucks,
  CarrierTypes
} from '../../../constants/carrier.constants';

import { ReactComponent as LeftArrowIcon } from '../../../assets/images/left_arrow.svg';

const BasicDetailsForm = () => {
  const navigate = useNavigate();

  const [isDispatcherUser, setisDispatcherUser] = useState(false);

  const {
    register,
    control,
    formState: { errors },
    watch
  } = useFormContext();

  const carrierType = watch('carrierType');

  const onBackArrowClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (carrierType && carrierType.label === 'Dispatcher') {
      setisDispatcherUser(true);
    } else if (carrierType) {
      setisDispatcherUser(false);
    }
  }, [carrierType]);

  return (
    <>
      <div
        onClick={onBackArrowClick}
        onKeyPress={() => {}}
        role="button"
        tabIndex="-1"
      >
        <LeftArrowIcon className="absolute inset-y-0 left-0 flex items-center w-6 h-6 mt-12 font-bold text-navy-500" />
      </div>
      <div className="p-4 mb-6 ml-5 text-navy-500">
        <p className="text-md">Drive</p>
        <h3 className="text-3xl font-bold ">Be the boss!</h3>
        <p className="text-md">Drive with NET and be a leader.</p>
      </div>

      <div className="relative p-4">
        <label
          className="block w-full mb-2 text-sm font-bold text-black"
          htmlFor=""
        >
          Owner Operator
        </label>
        <Controller
          name="carrierType"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              options={CarrierTypes}
              className="w-full text-xs font-semibold leading-none bg-white border-gray-200 outline-none appearance-none"
            />
          )}
        />
        {errors.carrierType && (
          <span className="text-xs text-red-700">
            {errors.carrierType.message}
          </span>
        )}
      </div>

      {isDispatcherUser && (
        <>
          <div className="p-4 mb-3">
            <label
              className="block w-full mb-2 text-sm font-bold text-black"
              htmlFor=""
            >
              Can any driver in your fleet book their own load?
            </label>
            <div className="float-left w-1/2 mb-4">
              <label>
                <input
                  {...register('canDriverBook')}
                  type="radio"
                  value="yes"
                />
                <span className="ml-1">Yes</span>
              </label>
            </div>
            <div className="float-left w-1/2 mb-4">
              <label>
                <input {...register('canDriverBook')} type="radio" value="no" />
                <span className="ml-1">No</span>
              </label>
            </div>
            {errors.canDriverBook && (
              <span className="text-xs text-red-700">
                {errors.canDriverBook.message}
              </span>
            )}
          </div>

          <div className="relative p-4">
            <label
              className="block w-full mb-2 text-sm font-bold text-black"
              htmlFor=""
            >
              About how many trucks are in your fleet?
            </label>
            <Controller
              name="noOfTrucks"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={CarrierTrucks}
                  className="w-full text-xs font-semibold leading-none bg-white border-gray-200 outline-none appearance-none"
                />
              )}
            />
            {errors.noOfTrucks && (
              <span className="text-xs text-red-700">
                {errors.noOfTrucks.message}
              </span>
            )}
          </div>
        </>
      )}

      <div className="p-4 mb-3">
        <label
          className="block w-full mb-2 text-sm font-bold text-black"
          htmlFor=""
        >
          Carrier Name
        </label>
        <input
          {...register('company')}
          className="w-full p-4 text-xs font-semibold leading-none bg-white border border-gray-200 outline-none appearance-none"
          type="text"
        />
        {errors.company && (
          <span className="text-xs text-red-700">{errors.company.message}</span>
        )}
      </div>

      <div className="p-4 mb-3">
        <label
          className="block w-full mb-2 text-sm font-bold text-black"
          htmlFor=""
        >
          Contact Name
        </label>
        <input
          {...register('name')}
          className="w-full p-4 text-xs font-semibold leading-none bg-white border border-gray-200 outline-none appearance-none"
          type="text"
        />
        {errors.name && (
          <span className="text-xs text-red-700">{errors.name.message}</span>
        )}
      </div>

      <div className="p-4 mb-3">
        <label
          className="block w-full mb-2 text-sm font-bold text-black"
          htmlFor=""
        >
          Email
        </label>
        <input
          {...register('email')}
          className="w-full p-4 text-xs font-semibold leading-none bg-white border border-gray-200 outline-none appearance-none"
          type="email"
        />
        {errors.email && (
          <span className="text-xs text-red-700">{errors.email.message}</span>
        )}
      </div>

      <div className="p-4 mb-3">
        <label
          className="block w-full mb-2 text-sm font-bold text-black"
          htmlFor=""
        >
          Create Password
        </label>
        <input
          {...register('password')}
          className="w-full p-4 text-xs font-semibold leading-none bg-white border border-gray-200 outline-none appearance-none"
          type="password"
        />
        {errors.password && (
          <span className="text-xs text-red-700">
            {errors.password.message}
          </span>
        )}
      </div>
    </>
  );
};

export default BasicDetailsForm;

BasicDetailsForm.propTypes = {
  formContent: PropTypes.any.isRequired
};

import { useAuthContext } from '../contexts/auth.context';

const invoiceAuthorizedUsers = [
  'steve@nationwidetrans.com',
  'laura@nationwidetrans.com',
  'mohak+salesrep@solvative.com',
  'parthpandya+salesrep@solvative.com',
  'rajesh@solvative.com',
  'steve@nationwidetrans.comdummy',
  'laura@nationwidetrans.comdummy',
  'dhrumesh+cust@solvative.comdummy',
  'rajesh+stv@solvative.com'
];

const useIsInvoiceAuthorizedUser = () => {
  const { authUser } = useAuthContext();
  return invoiceAuthorizedUsers.includes(authUser?.user?.email);
};

const INVOICE_STATUS = {
  UNPAID: 'unpaid',
  PAID: 'paid',
  SENT: 'sent'
};

const invoiceStatusOptions = [
  {
    value: 'unpaid',
    label: 'Ready to be Invoiced'
  },
  {
    value: 'paid',
    label: 'Paid'
  },
  {
    value: 'sent',
    label: 'Sent'
  }
];

const INVOICE_STATUS_FILTER = [
  {
    value: 'ALL',
    label: 'ALL'
  },
  {
    value: 'unpaid',
    label: 'Unpaid'
  },
  {
    value: 'paid',
    label: 'Paid'
  },
  {
    value: 'sent',
    label: 'Sent'
  }
];

const PAYMENT_MODES = {
  CHECK: 'check',
  CARD: 'card',
  WIRETRANSFER: 'wiretransfer'
};

export {
  useIsInvoiceAuthorizedUser,
  INVOICE_STATUS,
  invoiceStatusOptions,
  PAYMENT_MODES,
  INVOICE_STATUS_FILTER
};

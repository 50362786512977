import React from 'react';
import PropTypes from 'prop-types';

import { useFormContext } from 'react-hook-form';

import { ReactComponent as LeftArrowIcon } from '../../../assets/images/left_arrow.svg';

const AddressDetailsForm = ({ prevStep }) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <>
      <div className="mb-6  p-4 relative">
        <div
          onClick={prevStep}
          onKeyPress={() => {}}
          role="button"
          tabIndex="-1"
        >
          <LeftArrowIcon className="w-6 h-6 absolute inset-y-0 left-0 flex items-center mt-6 text-navy-500 font-bold" />
        </div>
        <h3 className="text-3xl text-navy-500 font-bold ml-4">
          Address Detail!
        </h3>
      </div>

      <div className="mb-3  p-4">
        <label
          className="w-full block text-black text-sm font-bold mb-2"
          htmlFor=""
        >
          Address line 1
        </label>
        <input
          {...register('address1')}
          className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
          type="text"
        />
        {errors.address1 && (
          <span className="text-xs text-red-700">
            {errors.address1.message}
          </span>
        )}
      </div>

      <div className="mb-3  p-4">
        <label
          className="w-full block text-black text-sm font-bold mb-2"
          htmlFor=""
        >
          Address line 2
        </label>
        <input
          {...register('address2')}
          className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
          type="text"
        />
        {errors.address2 && (
          <span className="text-xs text-red-700">
            {errors.address2.message}
          </span>
        )}
      </div>

      <div className="mb-3  p-4">
        <label
          className="w-full block text-black text-sm font-bold mb-2"
          htmlFor=""
        >
          City
        </label>
        <input
          {...register('city')}
          className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
          type="text"
        />
        {errors.city && (
          <span className="text-xs text-red-700">{errors.city.message}</span>
        )}
      </div>

      <div className="mb-3  p-4">
        <label
          className="w-full block text-black text-sm font-bold mb-2"
          htmlFor=""
        >
          State
        </label>
        <input
          {...register('state')}
          className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
          type="text"
        />
        {errors.state && (
          <span className="text-xs text-red-700">{errors.state.message}</span>
        )}
      </div>

      <div className="mb-3  p-4">
        <label
          className="w-full block text-black text-sm font-bold mb-2"
          htmlFor=""
        >
          Zip Code
        </label>
        <input
          {...register('zip')}
          className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
          type="text"
        />
        {errors.zip && (
          <span className="text-xs text-red-700">{errors.zip.message}</span>
        )}
      </div>
    </>
  );
};

export default AddressDetailsForm;

AddressDetailsForm.propTypes = {
  prevStep: PropTypes.func.isRequired,
  formContent: PropTypes.any.isRequired
};

import React from 'react';

import UsersTableView from '../../components/UsersTableView';

const CustomersListPage = () => {
  return (
    <section>
      <div className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <UsersTableView />
        </div>
      </div>
    </section>
  );
};

export default CustomersListPage;

import queryString from 'query-string';

const currentYear = new Date().getFullYear();

const VIEW_TYPE = {
  QUICK_VIEW: 'quick',
  GUIDED_VIEW: 'guided'
};

const ROLE_TYPE = {
  MAIN: '664b48faf70ecc1c4b90912b',
  ACCOUNTING: '664b48faf70ecc1c4b90912a',
  TRANSPORTATION: '664b48faf70ecc1c4b90912c',
  BILLING: '664b48faf70ecc1c4b90912d',
  GENERAL: '664b48faf70ecc1c4b90912e'
};

const isDuplicate = () => {
  const { search } = window.location;
  const { quoteId } = queryString.parse(search);
  return quoteId;
};

export { currentYear, VIEW_TYPE, ROLE_TYPE, isDuplicate };

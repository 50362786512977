import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { CARRIER_SERVICE_TYPES } from '../../constants/quote.constants';
import DatePickerInput from '../DatePickerInput/DatePickerInput';

const CarrierFilters = ({ closeModal, onApply, selectedFilters }) => {
  const [selectedServices, setselectedServices] = useState(
    selectedFilters?.serviceType ? selectedFilters.serviceType : []
  );
  const [zipcodes, setzipcodes] = useState({
    fromZipcode: selectedFilters?.fromZipcode
      ? selectedFilters.fromZipcode
      : '',
    toZipcode: selectedFilters?.toZipcode ? selectedFilters.toZipcode : ''
  });
  const [durations, setdurations] = useState({
    pickupDateFrom: selectedFilters?.pickupDateFrom
      ? selectedFilters.pickupDateFrom
      : '',
    pickupDateTo: selectedFilters?.pickupDateTo
      ? selectedFilters.pickupDateTo
      : '',
    deliveryDateFrom: selectedFilters?.deliveryDateFrom
      ? selectedFilters.deliveryDateFrom
      : '',
    deliveryDateTo: selectedFilters?.deliveryDateTo
      ? selectedFilters.deliveryDateTo
      : ''
  });
  const [isPickupFlexible, setisPickupFlexible] = useState(
    selectedFilters?.isPickupFlexible ? selectedFilters.isPickupFlexible : false
  );
  const [isDeliveryFlexible, setisDeliveryFlexible] = useState(
    selectedFilters?.isDeliveryFlexible
      ? selectedFilters.isDeliveryFlexible
      : false
  );

  const [filterSelected, setfilterSelected] = useState(false);

  useEffect(() => {
    if (
      selectedServices.length ||
      zipcodes.fromZipcode ||
      zipcodes.toZipcode ||
      durations.pickupDateFrom ||
      durations.pickupDateTo ||
      durations.deliveryDateFrom ||
      durations.deliveryDateTo ||
      isPickupFlexible ||
      isDeliveryFlexible
    ) {
      setfilterSelected(true);
    } else {
      setfilterSelected(false);
    }
  }, [
    selectedServices,
    zipcodes,
    durations,
    isPickupFlexible,
    isDeliveryFlexible
  ]);

  const onClearFilters = () => {
    setselectedServices([]);
    setzipcodes({ fromZipcode: '', toZipcode: '' });
    setdurations({
      pickupDateFrom: '',
      pickupDateTo: '',
      deliveryDateFrom: '',
      deliveryDateTo: ''
    });
    setisPickupFlexible(false);
    setisDeliveryFlexible(false);
  };

  const onServiceChange = (e, service) => {
    if (e.target.checked) {
      setselectedServices((oldServices) => [
        ...oldServices,
        `${service.value}`
      ]);
    } else {
      setselectedServices(selectedServices.filter((s) => s !== service.value));
    }
  };

  const onSubmitFilters = () => {
    const filtersData = {};
    if (filterSelected) {
      if (selectedServices.length) {
        filtersData.serviceType = selectedServices;
      }

      if (zipcodes.fromZipcode) {
        filtersData.fromZipcode = zipcodes.fromZipcode;
      }

      if (zipcodes.toZipcode) {
        filtersData.toZipcode = zipcodes.toZipcode;
      }

      if (durations.pickupDateFrom) {
        filtersData.pickupDateFrom = durations.pickupDateFrom;
      }

      if (durations.pickupDateTo) {
        filtersData.pickupDateTo = durations.pickupDateTo;
      }

      if (durations.deliveryDateFrom) {
        filtersData.deliveryDateFrom = durations.deliveryDateFrom;
      }

      if (durations.deliveryDateTo) {
        filtersData.deliveryDateTo = durations.deliveryDateTo;
      }

      if (isPickupFlexible) {
        filtersData.isPickupFlexible = isPickupFlexible;
      }

      if (isDeliveryFlexible) {
        filtersData.isDeliveryFlexible = isDeliveryFlexible;
      }
    }

    onApply(filtersData);
  };

  return (
    <div
      className="fixed z-10 overflow-y-auto top-0 w-full h-full left-0"
      id="modal"
    >
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-900 opacity-75" />
      </div>
      <div className="container mx-auto bg-gray-100 relative z-10">
        <div className="mb-6 lg:mb-10 text-left p-6">
          <div className="flex items-center mb-6  p-4 ">
            <div className="w-9/12">
              <h3 className="text-3xl font-bold inline-block text-navy-500">
                Filter
              </h3>
            </div>
            <div className="w-3/12 text-right">
              {filterSelected && (
                <button
                  type="button"
                  className="mr-4 underline"
                  onClick={onClearFilters}
                >
                  Clear All
                </button>
              )}

              <button
                type="button"
                onClick={closeModal}
                className="float-right close text-navy-500 bg-white"
              >
                <svg
                  className="h-6 w-6  cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <form action="">
            <div className="p-4">
              <label
                className="w-full block text-black text-xl  mb-6 font-bold"
                htmlFor="delivery-date"
              >
                Type of service
              </label>
              {CARRIER_SERVICE_TYPES.map((serviceType) => {
                return (
                  <div
                    className="mb-4 w-full lg:w-1/4 float-left"
                    key={serviceType.value}
                  >
                    <label>
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        name="checkbox-name"
                        value={serviceType.value}
                        onChange={(e) => {
                          onServiceChange(e, serviceType);
                        }}
                        checked={selectedServices.includes(serviceType.value)}
                      />
                      <span className="ml-1">{serviceType.label}</span>
                    </label>
                  </div>
                );
              })}
            </div>

            <div className="border  border-gray-200 border-b-0 border-l-0 border-r-0 pt-10 clear-both p-4">
              <p className="text-left w-full block text-black text-xl  mb-6 font-bold">
                Zipcode
              </p>
              <div className=" mb-10  w-1/3 float-left mr-10">
                <input
                  placeholder="Ship from"
                  className="leading-tight shadow appearance-none w-full text-xs bg-white border-gray-200 border-solid border bg-white py-3 px-2"
                  type="text"
                  id="ship-from"
                  value={zipcodes.fromZipcode}
                  maxLength={5}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                      setzipcodes({
                        ...zipcodes,
                        fromZipcode: e.target.value
                      });
                    }
                  }}
                  onKeyDown={(e) =>
                    (e.keyCode === 69 ||
                      e.keyCode === 190 ||
                      e.keyCode === 189) &&
                    e.preventDefault()
                  }
                />
              </div>
              <div className=" mb-10  w-1/3 float-left mr-10">
                <input
                  placeholder="Ship to"
                  className="leading-tight shadow appearance-none w-full text-xs bg-white border-gray-200 border-solid border bg-white py-3 px-2"
                  type="text"
                  id="ship-to"
                  value={zipcodes.toZipcode}
                  maxLength={5}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === '' || re.test(e.target.value)) {
                      setzipcodes({ ...zipcodes, toZipcode: e.target.value });
                    }
                  }}
                  onKeyDown={(e) =>
                    (e.keyCode === 69 ||
                      e.keyCode === 190 ||
                      e.keyCode === 189) &&
                    e.preventDefault()
                  }
                />
              </div>
            </div>

            <div className="border  border-gray-200 border-b-0 border-l-0 border-r-0 pt-10 clear-both p-4">
              <p className="text-left w-full block text-black text-xl  mb-6 font-bold">
                Date
              </p>
              <div className="mb-6 w-1/3 float-left mr-10">
                <DatePickerInput
                  name="pickupDateFrom"
                  handleDateChange={(val) =>
                    setdurations({ ...durations, pickupDateFrom: val })
                  }
                  selectedDate={
                    durations.pickupDateFrom &&
                    new Date(Date.parse(durations.pickupDateFrom))
                  }
                  placeholderText="Pickup Date From"
                  minDate=""
                />
                <div className="mb-3 py-4">
                  <label>
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="field-name"
                      value={isPickupFlexible}
                      checked={isPickupFlexible}
                      onChange={(e) => setisPickupFlexible(e.target.checked)}
                    />

                    <span className="ml-1">Including Flexible Date</span>
                  </label>
                </div>
              </div>
              <div className="mb-6 w-1/3 float-left mr-10">
                <DatePickerInput
                  name="pickupDateTo"
                  handleDateChange={(val) =>
                    setdurations({ ...durations, pickupDateTo: val })
                  }
                  selectedDate={
                    durations.pickupDateTo &&
                    new Date(Date.parse(durations.pickupDateTo))
                  }
                  placeholderText="Pickup Date To"
                  minDate=""
                />
                <div className="mb-3 py-4">
                  <label>
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="field-name"
                      value={isPickupFlexible}
                      checked={isPickupFlexible}
                      onChange={(e) => setisPickupFlexible(e.target.checked)}
                    />

                    <span className="ml-1">Including Flexible Date</span>
                  </label>
                </div>
              </div>
              <div className="mb-6 w-1/3 float-left mr-10">
                <DatePickerInput
                  name="deliveryDateFrom"
                  handleDateChange={(val) =>
                    setdurations({ ...durations, deliveryDateFrom: val })
                  }
                  selectedDate={
                    durations.deliveryDateFrom &&
                    new Date(Date.parse(durations.deliveryDateFrom))
                  }
                  placeholderText="Delivery Date From"
                  minDate=""
                />
                <div className="mb-3 py-4">
                  <label>
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="field-name"
                      value={isDeliveryFlexible}
                      checked={isDeliveryFlexible}
                      onChange={(e) => setisDeliveryFlexible(e.target.checked)}
                    />
                    <span className="ml-1">Including Flexible Date</span>
                  </label>
                </div>
              </div>
              <div className="mb-6 w-1/3 float-left mr-10">
                <DatePickerInput
                  name="deliveryDateTo"
                  handleDateChange={(val) =>
                    setdurations({ ...durations, deliveryDateTo: val })
                  }
                  selectedDate={
                    durations.deliveryDateTo &&
                    new Date(Date.parse(durations.deliveryDateTo))
                  }
                  placeholderText="Delivery Date To"
                  minDate=""
                />
                <div className="mb-3 py-4">
                  <label>
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="field-name"
                      value={isDeliveryFlexible}
                      checked={isDeliveryFlexible}
                      onChange={(e) => setisDeliveryFlexible(e.target.checked)}
                    />
                    <span className="ml-1">Including Flexible Date</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="p-4 flex w-full justify-end">
              <div className="m-2">
                <button
                  type="button"
                  onClick={closeModal}
                  className="px-10 mb-2 w-full py-4 
                    bg-white hover:bg-red-500  font-bold
                    text-navy-500 hover:text-white active:text-navy-600   
                    transition duration-200 text-center"
                >
                  Cancel
                </button>
              </div>
              <div className="m-2">
                <button
                  type="button"
                  onClick={() => {
                    onSubmitFilters();
                  }}
                  className="px-10 mb-2 w-full py-4 
                    bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700
                                hover:border-navy-600 active:border-navy-700
                     font-bold text-white
                    transition duration-200 text-center border "
                >
                  Apply
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CarrierFilters;

CarrierFilters.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired
};

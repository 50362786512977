import React from 'react';
import PropTypes from 'prop-types';

const SuggestionLabel = ({ quote }) => {
  let deliveryZipCode;
  let pickupZipCode;
  const { company, internalTrackingNumber, serviceTypeName, estimate } = quote;

  if (quote.pickupInfo) {
    pickupZipCode = quote.pickupInfo
      .map((val) => val.pickup_zip_code)
      .join(', ');

    deliveryZipCode = quote.deliveryInfo
      .map((val) => val.delivery_zip_code)
      .join(', ');
  } else {
    pickupZipCode = quote.pickup_zip_code;
    deliveryZipCode = quote.delivery_zip_code;
  }

  return (
    <div className="flex flex-col flex-wrap border-b p-2">
      <div className="font-bold">{`${company} - ${internalTrackingNumber}`}</div>
      <div className="flex flex-wrap gap-x-2">
        <div>
          <span className="font-semibold text-gray-500">From: </span>
          <span className="font-semibold">{pickupZipCode}</span>
        </div>
        <span className="font-semibold text-gray-400">{`  |  `}</span>
        <div>
          <span className="font-semibold text-gray-500">To: </span>{' '}
          <span className="font-semibold">{deliveryZipCode}</span>
        </div>
        <span className="font-semibold text-gray-400">{`  |  `}</span>
        <div className="font-semibold">{serviceTypeName}</div>
        <div>
          <span className="font-semibold text-gray-500">Budget: </span>
          <span className="font-semibold">
            ${estimate ? Number(estimate)?.toFixed(2) : '0.00'}
          </span>
        </div>
      </div>
    </div>
  );
};

SuggestionLabel.propTypes = {
  quote: PropTypes.object.isRequired
};

export default SuggestionLabel;

const BID_TYPE = {
  QUOTE_FROM_NET: 1,

  FIXED_AMOUNT: 2,

  OPEN_BID: 3
};

const BID_STATUS = {
  RECENT_QUOTE: 1,

  PENDING_QUOTE: 1,

  SALES_REP_PENDING_QUOTE: 2,

  ASSIGNED_QUOTE: 6,

  ENROUTE_QUOTE: 6,

  DELIVERED_QUOTE: 3
};

export { BID_TYPE, BID_STATUS };

import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MdModeEditOutline, MdDelete } from 'react-icons/md';
import CargoDefault from '../../assets/images/cargo_default.png';

const CargoProfile = memo(({ customerId, deleteCargo, savedCargos }) => {
  const navigate = useNavigate();

  return (
    <div className="w-full my-6">
      <div className="flex justify-between my-6 ml-1">
        <h3 className="font-bold">Select Cargo</h3>
        <button
          type="button"
          className="bg-navy-500 text-white px-4 py-2 text-sm font-semibold"
          onClick={() =>
            navigate(customerId ? `/add-cargo/${customerId}` : `/add-cargo`)
          }
        >
          Add New Cargo
        </button>
      </div>
      {savedCargos?.map((cargo) => {
        return (
          <div className="w-full flex justify-between items-center bg-gray-100 hover:bg-blue-200 my-3 py-2 px-3 text-left focus:outline-none">
            <div className="flex items-center">
              <span className="mb-4 lg:mb-0 mr-4 inline-block p-1 md:p-1 ">
                {cargo?.files?.fd ? (
                  <img
                    className="object-contain h-10 w-10"
                    src={`${process.env.REACT_APP_API_URL}/${cargo.files.fd}`}
                    alt="cargo img"
                    onError={(e) => {
                      e.target.src = CargoDefault;
                    }}
                  />
                ) : (
                  <img
                    className="object-contain h-10 w-10"
                    src={CargoDefault}
                    alt="cargo img"
                    onError={(e) => {
                      e.target.src = CargoDefault;
                    }}
                  />
                )}
              </span>
              <span>{cargo.quantity} - cargos</span>
              <span className="mx-4 text-gray-300"> | </span>
              <span>
                {' '}
                {cargo.length} in x {cargo.width} in x {cargo.height} in x
                {cargo.weight} lbs{' '}
              </span>
            </div>
            <div className="flex gap-4">
              <button
                type="button"
                onClick={() =>
                  navigate(
                    customerId
                      ? `/add-cargo/${customerId}`
                      : `/add-cargo?cargo=${cargo.id}`,
                    { state: cargo }
                  )
                }
              >
                <MdModeEditOutline
                  color="#1d4690"
                  className="text-2xl bg-navy-75 p-1 h-7 w-7 shadow-md rounded-sm "
                />
              </button>
              <button type="button" onClick={() => deleteCargo(cargo.id)}>
                <MdDelete
                  color="#d22027"
                  className="text-2xl bg-red-200 p-1 h-7 w-7 shadow-md rounded-sm "
                />
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
});

CargoProfile.propTypes = {
  customerId: PropTypes.string.isRequired,
  deleteCargo: PropTypes.func.isRequired,
  savedCargos: PropTypes.array.isRequired
};

export default CargoProfile;

import React, { useEffect, useContext } from 'react';
import toast from 'react-hot-toast';
import { useAuthContext } from '../../../contexts/auth.context';
import { GlobalFormContext } from '../../../contexts/QuickQuoteContext';
import { shipperProfileService } from '../../../services/shipper.service';

const ShipperCustomerSection = () => {
  const { authUser } = useAuthContext();
  const { currentCustomer, setCurrentCustomer } = useContext(GlobalFormContext);

  async function fetchUserDetails() {
    try {
      const response = await shipperProfileService(authUser.user.id);
      const currentCompany = response?.data?.companyId;
      setCurrentCustomer({
        ...currentCompany,
        value: currentCompany?.id,
        label: currentCompany?.name
      });
    } catch (error) {
      console.log({ error });
      toast.error(error?.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    fetchUserDetails();
  }, []);
  return (
    <div className="flex flex-col gap-2 w-full items-start">
      <div className="flex flex-row bg-navy-20 p-2 w-full gap-16 items-center justify-evenly pl-20">
        <div className="flex flex-col">
          <span className="text-gray-600 ">Credit Limit:</span>
          <span className="font-semibold">
            {currentCustomer?.creditLimit?.toFixed(2)}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-gray-600 ">Open balance:</span>
          <span className="font-semibold">
            {currentCustomer?.openBalance?.toFixed(2)}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-gray-600">Terms:</span>
          <span className="font-semibold">{currentCustomer?.paymentTerms}</span>
        </div>
      </div>
    </div>
  );
};
export default ShipperCustomerSection;

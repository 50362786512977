import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { VENDORS } from '../../constants/carrier.constants';

import { useAuthContext } from '../../contexts/auth.context';
import {
  shipperProfileService,
  updateEmailPreferences
} from '../../services/shipper.service';
import {
  updateCompanyData,
  getCompanyData
} from '../../services/company.service';
// import DeleteAccountButton from '../../components/DeleteAccountButton/DeleteAccountButton';

const ShipperSettings = () => {
  const [user, setUser] = useState();
  const [isAPICalled, setAPICalled] = useState(false);
  const [disabledCarriers, setDisabledCarriers] = useState([]);
  const { authUser } = useAuthContext();

  async function fetchUserProfile() {
    try {
      const userId = authUser?.user?.id;

      const response = await shipperProfileService(userId);

      setUser(response?.data);
      setAPICalled(true);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }
  function carrierChangeHandler(e, carrier) {
    let carrierList = disabledCarriers ? [...disabledCarriers] : [];
    if (e.target.checked) {
      carrierList = carrierList.filter((car) => car !== carrier);
    } else {
      carrierList.push(carrier);
    }
    setDisabledCarriers(carrierList);
  }

  const fetchCompanyDetails = async () => {
    try {
      const response = await getCompanyData(authUser?.user?.companyId?.id);
      if (response?.data?.flag) {
        setDisabledCarriers(response?.data?.data?.disabledCarrier);
      }
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };
  useEffect(() => {
    fetchUserProfile();
    fetchCompanyDetails();
  }, []);

  const saveSettings = async () => {
    try {
      await updateEmailPreferences(authUser?.user?.id, {
        userEmailPreferences: {
          newQuoteNotify: user.userEmailPreferences[0]?.newQuoteNotify,
          shipmentBookNotify: user.userEmailPreferences[0]?.shipmentBookNotify,
          shipmentDeliverNotify:
            user.userEmailPreferences[0]?.shipmentDeliverNotify
        }
      });

      const response = await updateCompanyData(authUser?.user?.companyId?.id, {
        disabledCarrier: disabledCarriers
      });
      if (response?.data) {
        toast.success('Settings saved successfully');
      } else {
        toast.error('Error in save settings');
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };
  return (
    <section className="py-4">
      <div className="container mx-auto">
        <div className="mx-auto">
          <div className="mb-8 w-full lg:w-1/2 px-3">
            <div className="mb-2  p-4 relative pl-0">
              <h3 className="text-3xl font-bold text-navy-500">
                General Settings
              </h3>
            </div>

            <div className="mb-6">
              {isAPICalled ? (
                <>
                  <h2 className="text-2xl font-bold pb-6">Notifications</h2>
                  <div className="w-full pb-4 md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      <input
                        type="checkbox"
                        className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                        defaultChecked={
                          user?.userEmailPreferences[0]?.newQuoteNotify
                        }
                        checked={user?.userEmailPreferences[0]?.newQuoteNotify} // Use checked instead of value
                        onChange={(e) =>
                          setUser((prevUser) => ({
                            ...prevUser,
                            userEmailPreferences: [
                              {
                                ...prevUser.userEmailPreferences[0],
                                newQuoteNotify: e.target.checked
                              }
                            ]
                          }))
                        }
                      />
                      <span className="ml-1">Receive new quote emails</span>
                    </label>
                  </div>
                  <div className="w-full pb-4 md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      <input
                        type="checkbox"
                        className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                        defaultChecked={
                          user?.userEmailPreferences[0]?.shipmentBookNotify
                        }
                        value={
                          user?.userEmailPreferences[0]?.shipmentBookNotify
                        }
                        onChange={(e) =>
                          setUser((prevUser) => ({
                            ...prevUser,
                            userEmailPreferences: [
                              {
                                ...prevUser.userEmailPreferences[0],
                                shipmentBookNotify: e.target.checked
                              }
                            ]
                          }))
                        }
                      />
                      <span className="ml-1">Receive shipment book emails</span>
                    </label>
                  </div>
                  <div className="w-full pb-4 md:w-9/12">
                    <label className="block mb-2 tracking-wide">
                      <input
                        type="checkbox"
                        className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                        defaultChecked={
                          user?.userEmailPreferences[0]?.shipmentDeliverNotify
                        }
                        value={
                          user?.userEmailPreferences[0]?.shipmentDeliverNotify
                        }
                        onChange={(e) =>
                          setUser((prevUser) => ({
                            ...prevUser,
                            userEmailPreferences: [
                              {
                                ...prevUser.userEmailPreferences[0],
                                shipmentDeliverNotify: e.target.checked
                              }
                            ]
                          }))
                        }
                      />
                      <span className="ml-1">
                        Receive shipment delivery emails
                      </span>
                    </label>
                  </div>{' '}
                  <div className=" w-full pb-4">
                    <h3 className="mb-4 text-2xl font-bold">LTL carriers</h3>
                    {VENDORS.map((carrier) => (
                      <label
                        className="block mb-2 tracking-wide"
                        key={carrier.name}
                      >
                        <input
                          type="checkbox"
                          value={carrier.name}
                          onChange={(e) =>
                            carrierChangeHandler(e, carrier.name)
                          }
                          checked={!disabledCarriers?.includes(carrier.name)}
                          className="block w-full px-4 py-3 mb-3 leading-tight border border-gray-400 appearance-none focus:outline-none disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                        />
                        <span className="ml-1">
                          {`${carrier.display_name} (${carrier.service})`}
                        </span>
                      </label>
                    ))}
                  </div>
                </>
              ) : null}
            </div>
            <div className="text-left w-full md:w-9/12 pb-2">
              <button
                type="button"
                onClick={saveSettings}
                className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-4
                        bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                      hover:border-navy-600 active:border-navy-700 text-lg font-bold transition duration-200 text-left"
              >
                Save
                <span className="pointer-events-none float-right px-2 mr-2 text-xl">
                  &#10003;
                </span>
              </button>
            </div>
            {/* <DeleteAccountButton /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShipperSettings;

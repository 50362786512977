import * as fileDownload from 'js-file-download';
import toast from 'react-hot-toast';
import { downloadInvoice } from '../services/invoice.service';

const useDownloadInvoice = () => {
  const handleDownloadInvoice = async (quoteId) => {
    try {
      const response = await downloadInvoice(quoteId);
      fileDownload(response?.data, `${quoteId}.pdf`);
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data?.message ?? 'Something went wrong!');
    }
  };
  return handleDownloadInvoice;
};

export default useDownloadInvoice;

import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as RightArrow } from '../assets/images/right_arrow.svg';

const SignupPage = () => {
  return (
    <div className="">
      <section className="py-20 ">
        <div className="container mx-auto px-4">
          <div className="mb-6  p-4 relative pl-0">
            <h3 className="text-3xl font-bold text-navy-500">Signup</h3>
          </div>
          <div className="flex flex-wrap text-center -mx-4">
            <div className="mb-8 w-full lg:w-1/2 px-3 carrier-link">
              <Link to="/carrier-signup">
                <div
                  className="relative py-10 bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700 shadow "
                >
                  <h4 className="mb-1 font-bold text-xl">Carrier</h4>
                  <RightArrow className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-9 font-bold" />
                </div>
              </Link>
            </div>
            <div className="mb-8 w-full lg:w-1/2 px-3 shipper-link">
              <Link to="/shipper-signup">
                <div
                  className="relative py-10 bg-white text-navy-500 hover:bg-navy-50 hover:text-navy-500 active:text-navy-600 
                   border border-navy-500 hover:border-navy-600 shadow "
                >
                  <h4 className="mb-2 text-navy-500 font-bold text-xl">
                    Shipper
                  </h4>
                  <RightArrow className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-9 font-bold" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignupPage;

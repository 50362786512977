import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { ReactComponent as RightArrow } from '../../assets/images/right_arrow.svg';
import useDownloadInvoice from '../../customHooks/useDownloadInvoice';

const DownloadInvoiceButton = ({ quoteId, showArrow, styling }) => {
  const handleDownloadInvoice = useDownloadInvoice();
  const handleOnClick = async (e) => {
    try {
      e.preventDefault();
      await handleDownloadInvoice(quoteId);
    } catch (error) {
      console.log({ error });
      toast.error(error.message ?? 'Something went wrong!');
    }
  };
  return (
    <button type="button" onClick={handleOnClick} className={styling}>
      Download Invoice
      {showArrow && <RightArrow className="w-5 h-5 font-bold" />}
    </button>
  );
};

export default DownloadInvoiceButton;

DownloadInvoiceButton.propTypes = {
  quoteId: PropTypes.string.isRequired,
  showArrow: PropTypes.bool,
  styling: PropTypes.string
};

DownloadInvoiceButton.defaultProps = {
  showArrow: false,
  styling: ''
};

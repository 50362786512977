import API from './api.services';
import API_ENDPOINTS from '../constants/api-endpoints.constants';

const getOpenInvoices = (page, limit, status) => {
  return API.get(API_ENDPOINTS.INVOICE.GetInvoices, {
    params: {
      token: localStorage.getItem('token'),
      status,
      platform: 'web',
      skip: page,
      limit
    }
  });
};
const sendInvoice = (quoteIds) => {
  return API.post(API_ENDPOINTS.INVOICE.SendInvoice, quoteIds, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    }
  });
};

const generateInvoice = (quoteIds) => {
  return API.put(API_ENDPOINTS.INVOICE.GenerateInvoice, quoteIds, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    }
  });
};

const downloadInvoice = (quoteId) => {
  return API.post(`${API_ENDPOINTS.INVOICE.DownloadInvoice}/${quoteId}`, null, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    },
    responseType: 'blob'
  });
};

const completePayment = (body) => {
  return API.post(API_ENDPOINTS.INVOICE.CompletePayment, body, {
    params: {
      token: localStorage.getItem('token'),
      platform: 'web'
    }
  });
};

export {
  getOpenInvoices,
  sendInvoice,
  generateInvoice,
  downloadInvoice,
  completePayment
};

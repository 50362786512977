import toast from 'react-hot-toast';
import { sendInvoice } from '../services/invoice.service';

const useSendInvoice = () => {
  const handleSendInvoice = async (quoteIds) => {
    try {
      const response = await sendInvoice({ quoteIds });

      if (response && response.data) {
        const { flag, errorWhileSendingMail, successfullySentMail } =
          response.data;

        if (flag) {
          if (!errorWhileSendingMail.length) {
            if (successfullySentMail.length === 1) {
              toast.success('Invoice Mailed Successfully');
            } else {
              toast.success('All Invoices Mailed Successfully');
            }
          } else {
            if (successfullySentMail.length) {
              toast.success('Invoices Mailed Successfully');
            }
            errorWhileSendingMail.forEach((element) => {
              toast.error(element?.message ?? 'Something Went Wrong!');
            });
          }
        } else {
          toast.error(response.data.message ?? 'Something Went Wrong!');
        }
      } else {
        toast.error('Invalid response from server');
      }
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };
  return handleSendInvoice;
};

export default useSendInvoice;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../../contexts/auth.context';
import Roles from '../../constants/roles.constants';

const PrivateRoute = ({ element: Element, rolesAccess, path }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  }, []);

  const { authUser } = useAuthContext();
  if (!authUser.token) {
    localStorage.setItem('link', window.location.pathname);
  }

  const isAuthenticated = authUser && authUser.token;
  const isAbleToAccess = isAuthenticated
    ? rolesAccess.includes(authUser.user.usertype)
    : false;

  const getHomePageOfUser = () => {
    switch (authUser.user.usertype) {
      case Roles.SHIPPER:
        return '/shipper-dashboard';

      case Roles.CARRIER:
        if (!authUser.user.isDOTVerified) {
          return '/carrier-dot-number';
        }
        return '/carrier-dashboard';

      case Roles.SALES_REP:
      case Roles.CUSTOMER_REP:
        return '/dashboard';

      default:
        break;
    }
  };

  const ableToRoute = () => {
    switch (authUser.user.usertype) {
      case Roles.CARRIER:
        if (
          (!authUser.user.isDOTVerified || !authUser.user.isVerifiedOnStripe) &&
          path !== '/carrier-dot-number' &&
          path !== '/carrier-onboarding'
        ) {
          if (!authUser.user.isDOTVerified)
            return <Navigate to="/carrier-dot-number" />;
        }

        return <Element />;

      default:
        return <Element />;
    }
  };

  if (!Element) {
    return null;
  }

  return !isAuthenticated ? (
    <Navigate to="/login" />
  ) : (
    (isAbleToAccess && ableToRoute()) || (
      <Navigate to={getHomePageOfUser()} replace />
    )
  );
};

export default PrivateRoute;

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  element: PropTypes.func.isRequired,
  rolesAccess: PropTypes.array.isRequired
};

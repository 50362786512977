import React from 'react';

import CustomerRepTableView from '../../components/CustomerRepTableView';

const CustomerRepListPage = () => {
  return (
    <section>
      <div className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <CustomerRepTableView />
        </div>
      </div>
    </section>
  );
};

export default CustomerRepListPage;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getPackageType } from '../../../utils/cargo.util';
import { formatPickupDeliveryDate } from '../../../utils/date_conversion.util';
import {
  BLANKET_WRAP,
  LTL_FREIGHT,
  PARTIAL_VAN,
  TRUCKLOAD_VAN
} from '../../../constants/service_type.constants';
import { ReactComponent as LeftArrowIcon } from '../../../assets/images/left_arrow.svg';
import {
  calculateDensity,
  cargoStringConvertor,
  printServiceOptions
} from '../../../utils/quote.util';
import { BID_TYPE } from '../../../constants/bids.constants';
import DummyImage from '../../../components/DummyImage/DummyImage';

const QuoteSummary = ({ prevStep, formContent }) => {
  const navigate = useNavigate();
  const [totalCargoWeight, setTotalCargoWeight] = useState(0);
  const [isAutoConverted, setAutoConverted] = useState(false);
  const { QUOTE_FROM_NET, FIXED_AMOUNT, OPEN_BID } = BID_TYPE;

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    getValues
  } = useFormContext();

  const isInsuranceRequired = watch('is_insurance_required');
  const isFixedBid = watch('bidType');

  const serviceType = formContent.service_type;
  const showBidSelection =
    serviceType !== LTL_FREIGHT && serviceType !== BLANKET_WRAP;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });

    if (serviceType === LTL_FREIGHT) {
      let highestCubicFeet = 0;

      formContent?.cargos?.forEach((cargo) => {
        const { cubitFeet } = calculateDensity(cargo);
        if (cubitFeet > highestCubicFeet) {
          highestCubicFeet = Number(cubitFeet);
        }
      });

      if (highestCubicFeet >= 750) {
        setValue('service_type', PARTIAL_VAN);
        setValue('isAutoConverted', true);
        setAutoConverted(true);
      }
      //  else if (highestCubicFeet >= 192) {
      //   setValue('service_type', TRUCKLOAD_VAN);
      //   setValue('isAutoConverted', true);
      //   setAutoConverted(true);
      // }
      else {
        setValue('service_type', serviceType);
        setValue('isAutoConverted', false);
        setAutoConverted(false);
      }
    }

    if (serviceType === LTL_FREIGHT || serviceType === BLANKET_WRAP) {
      setValue('bidType', '1', { shouldValidate: true });
    }

    const currentServiceType = getValues('service_type');

    const currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('type', currentServiceType);
    navigate(`${window.location.pathname}?${currentUrlParams.toString()}`);
  }, []);

  useEffect(() => {
    if (!isInsuranceRequired) {
      setValue('insurance_value', '', { shouldValidate: true });
    }
  }, [isInsuranceRequired]);

  useEffect(() => {
    setTotalCargoWeight(
      formContent?.cargos?.reduce(
        (prev, next) => prev + next.weight * next.quantity,
        0
      )
    );
  }, [formContent]);

  const getPickupDateTime = () => {
    if (![LTL_FREIGHT, BLANKET_WRAP].includes(getValues('service_type'))) {
      return formContent?.pickupInfo
        ?.map((pick) => {
          const formattedPickupDate = formatPickupDeliveryDate(
            pick?.pickup_date,
            pick?.is_pickup_flexible
          );

          let pickupCloseTime = '';
          if (!pick?.is_pickup_time_flexible && pick?.pickup_close_time) {
            pickupCloseTime = ` (close time: ${moment(
              pick.pickup_close_time
            ).format('hh:mm A')})`;
          }
          return `${formattedPickupDate}${pickupCloseTime}`;
        })
        .join(', ');
    }
    let pickupCloseTime = '';
    if (
      !formContent?.is_pickup_time_flexible &&
      formContent?.pickup_close_time
    ) {
      pickupCloseTime = ` (close time: ${moment(
        formContent.pickup_close_time
      ).format('hh:mm A')})`;
    }

    const formattedPickupDate = formatPickupDeliveryDate(
      formContent?.pickup_date,
      formContent?.is_pickup_flexible
    );

    return `${formattedPickupDate} ${pickupCloseTime}`;
  };

  const getDeliveryDateTime = () => {
    if (![LTL_FREIGHT, BLANKET_WRAP].includes(getValues('service_type'))) {
      return formContent?.deliveryInfo
        ?.map((del) => {
          const formattedDeliveryDate = formatPickupDeliveryDate(
            del?.delivery_date,
            del?.is_delivery_flexible
          );

          let deliveryCloseTime = '';
          if (!del?.is_delivery_time_flexible && del?.delivery_close_time) {
            deliveryCloseTime = ` (close time: ${moment(
              del.delivery_close_time
            ).format('hh:mm A')})`;
          }
          return `${formattedDeliveryDate}${deliveryCloseTime}`;
        })
        .join(', ');
    }
    let deliveryCloseTime = '';
    if (
      !formContent?.is_delivery_time_flexible &&
      formContent?.delivery_close_time
    ) {
      deliveryCloseTime = ` (close time: ${moment(
        formContent.delivery_close_time
      ).format('hh:mm A')})`;
    }

    const formattedDeliveryDate = formatPickupDeliveryDate(
      formContent?.delivery_date,
      formContent?.is_delivery_flexible
    );

    return `${formattedDeliveryDate} ${deliveryCloseTime}`;
  };

  return (
    <>
      <div className="py-4 flex flex-row items-center">
        <div
          onClick={prevStep}
          onKeyPress={() => {}}
          role="button"
          tabIndex="-1"
        >
          <LeftArrowIcon className="w-6 h-6 text-navy-500 font-bold" />
        </div>
        <h3 className="text-3xl font-bold ml-4 text-left text-navy-500">
          Summary
        </h3>
      </div>
      <div className="pb-5">
        {formContent?.pickupInfo?.length &&
        formContent?.deliveryInfo?.length &&
        ![LTL_FREIGHT, BLANKET_WRAP].includes(getValues('service_type')) ? (
          <p className="text-left">
            You are requesting for a quote of{' '}
            {cargoStringConvertor(formContent.cargos)}. All piece will need to
            be transported from{' '}
            {formContent?.pickupInfo
              ?.map((pick) => pick.pickup_zip_code)
              ?.join(', ')}{' '}
            to{' '}
            {formContent?.deliveryInfo
              ?.map((del) => del.delivery_zip_code)
              ?.join(', ')}{' '}
            {formContent?.distance ? `(${formContent?.distance} miles) ` : ''}
            with a pick up on {getPickupDateTime()} and drop off on{' '}
            {getDeliveryDateTime()}. The total weight of your cargo is{' '}
            {totalCargoWeight} lbs.{' '}
          </p>
        ) : (
          <p className="text-left">
            You are requesting for a quote of{' '}
            {cargoStringConvertor(formContent.cargos)}. All piece will need to
            be transported from {formContent?.pickup_zip_code} to{' '}
            {formContent?.delivery_zip_code} with a pick up on{' '}
            {getPickupDateTime()} and drop off on {getDeliveryDateTime()}. The
            total weight of your cargo is {totalCargoWeight} lbs.
          </p>
        )}

        <p className="text-left mt-5">
          You have requested special pickup services and delivery Services.
        </p>
        <div className="pt-5">
          <p className="text-xl text-left text-gray-400">
            Special pickup services
          </p>

          {formContent?.pickupInfo?.length &&
          ![LTL_FREIGHT, BLANKET_WRAP].includes(getValues('service_type'))
            ? printServiceOptions(
                formContent?.pickupInfo?.map(
                  (pick) => pick.pickup_service_options
                ),
                true,
                'PICKUP'
              )
            : printServiceOptions(
                formContent.pickup_service_options,
                false,
                'PICKUP'
              )}
        </div>
        <div className="pt-5 border  border-gray-200 border-t-0 border-l-0 border-r-0 pb-5">
          <p className="text-xl text-left text-gray-400">
            Special delivery services
          </p>
          {formContent?.deliveryInfo?.length &&
          ![LTL_FREIGHT, BLANKET_WRAP].includes(getValues('service_type'))
            ? printServiceOptions(
                formContent?.deliveryInfo?.map(
                  (del) => del.delivery_service_options
                ),
                true,
                'DELIVERY'
              )
            : printServiceOptions(
                formContent.delivery_service_options,
                false,
                'DELIVERY'
              )}{' '}
        </div>
        {isAutoConverted && (
          <p className="text-left mt-5 text-red-500 text-lg font-medium">
            This is more than LTL or partial truckload pricing, we are switching
            to{' '}
            {getValues('service_type') === TRUCKLOAD_VAN ? 'Truckload Van' : ''}
            {getValues('service_type') === PARTIAL_VAN
              ? 'Partial Direct Van'
              : ''}{' '}
            pricing.
          </p>
        )}
      </div>

      <div className="border  border-gray-200 border-t-0 border-l-0 border-r-0 pb-5 mt-6">
        <p className="text-xl text-left text-black pb-4">Cargo detail</p>
        {formContent?.cargos?.map((cargo) => {
          return (
            <div className="pb-5 mt-6" key={cargo.cargo_id}>
              <div className="p-4 flex flex-wrap bg-gray-200">
                {cargo.cargo_img ? (
                  <div>
                    <span className="mb-4 lg:mb-0 mr-6 inline-block p-1 md:p-1 ">
                      <img
                        // className="h-8 w-8 md:w-12 md:h-12"
                        className="object-contain h-20 w-20"
                        src={cargo.cargo_img}
                        alt="cargo img"
                      />
                    </span>
                  </div>
                ) : (
                  <div key={cargo.cargo_id}>
                    <DummyImage />
                  </div>
                )}

                <div className="w-full lg:w-2/3">
                  <h3 className="mb-2 text-2xl font-bold font-heading">
                    {cargo.quantity} - {getPackageType(cargo.package_type)}
                  </h3>
                  <p className="text-gray-500">
                    {cargo.length} in x {cargo.width} in x {cargo.height} in x{' '}
                    {cargo.weight}lbs
                  </p>
                  {cargo.length && cargo.height && cargo.width ? (
                    <p className="text-gray-500 mt-2 font-heading space-x-2">
                      <span>
                        Density: {calculateDensity(cargo).density} lb/ft
                        <sup>3</sup>
                      </span>
                      <span>|</span>
                      <span>
                        Cubic FT: {calculateDensity(cargo).cubitFeet} ft
                        <sup>3</sup>
                      </span>
                    </p>
                  ) : null}
                  {formContent?.pickupInfo?.length > 1 ||
                  formContent?.deliveryInfo?.length > 1 ? (
                    <p className="text-gray-500 space-x-2 mt-2">
                      <span>
                        Ship From:{' '}
                        {
                          formContent?.pickupInfo?.find(
                            (pick) => pick.pickup_id === cargo.ship_from
                          )?.pickup_zip_code
                        }
                      </span>
                      <span>|</span>
                      <span>
                        Ship To:{' '}
                        {
                          formContent?.deliveryInfo?.find(
                            (del) => del.delivery_id === cargo.ship_to
                          )?.delivery_zip_code
                        }
                      </span>
                    </p>
                  ) : null}
                  {cargo.tarps ? (
                    <p className="text-md mt-1 font-bold text-gray-600 ">
                      <span />
                      Note: Tarps required for this cargo.
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="pt-5 text-left">
        <p className="text-xl text-left text-black">Insurance</p>
        <div className="mb-3  p-4 pl-0 border  border-gray-200 border-t-0 border-l-0 border-r-0 pb-5 relative">
          <label>
            <input {...register('is_insurance_required')} type="checkbox" />
            <span className="ml-1">
              Select this option if you would like us to provide a quote on
              insurance
            </span>
            {errors.is_insurance_required && (
              <span className="text-xs text-red-700">
                {errors.is_insurance_required.message}
              </span>
            )}
          </label>
          <p className="mb-3  p-4 pl-0">
            <input
              {...register('insurance_value')}
              placeholder="Enter Insurance Amount"
              className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
              type="number"
              onWheel={(e) => e.target.blur()}
              min="0"
              disabled={!isInsuranceRequired}
            />
            {errors.insurance_value && (
              <span className="text-xs text-red-700">
                {errors.insurance_value.message}
              </span>
            )}
          </p>
        </div>

        {showBidSelection && !isAutoConverted && (
          <div className="mb-4">
            <p className="text-xl text-left text-black">Quote</p>
            <ul className="mt-3">
              <li className="p-4 pl-0 border  border-gray-200 border-t-0 border-l-0 border-r-0 relative">
                <label>
                  <input
                    {...register('bidType')}
                    type="radio"
                    value={QUOTE_FROM_NET}
                  />
                  <span className="ml-1">Quote from NET system</span>
                </label>
                <p className="text-gray-400 mt-4 leading-loose">
                  NET System will calculate Quote amount based on your shipment.
                </p>
              </li>
              <li className="p-4 pl-0 border  border-gray-200 border-t-0 border-l-0 border-r-0 relative">
                <label>
                  <input
                    {...register('bidType')}
                    type="radio"
                    value={FIXED_AMOUNT}
                  />
                  <span className="ml-1">Your fixed amount for the cargos</span>
                </label>

                <div className="text-gray-400 mt-4 leading-loose">
                  <p className="text-gray-400">
                    Your can specify your desired amount for the shipment
                  </p>
                  <p className="mb-3  p-4 pl-0">
                    <input
                      {...register('amount')}
                      placeholder="Enter Amount"
                      className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200 text-gray-700"
                      type="number"
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      disabled={isFixedBid !== '2'}
                    />
                    {errors.amount && (
                      <span className="text-xs text-red-700">
                        {errors.amount.message}
                      </span>
                    )}
                  </p>
                </div>
              </li>
              <li className="p-4 pl-0 border  border-gray-200 border-t-0 border-l-0 border-r-0 relative">
                <label>
                  <input
                    {...register('bidType')}
                    type="radio"
                    value={OPEN_BID}
                  />
                  <span className="ml-1">
                    Open bidding from all the carriers
                  </span>
                </label>
                <p className="text-gray-400 mt-4 leading-loose">
                  All the carriers will place a bid on your shipment and you
                  will be able to select any one of your choice.
                </p>
              </li>
              {errors.bidType && (
                <span className="text-xs text-red-700">
                  {errors.bidType.message}
                </span>
              )}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default QuoteSummary;

QuoteSummary.propTypes = {
  prevStep: PropTypes.func.isRequired,
  formContent: PropTypes.any.isRequired
};

import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as RightArrow } from '../../assets/images/right_arrow.svg';
import { getQuotePrice } from '../../utils/quote.util';

const MarkAsPaidButton = ({ quote, showArrow, styling }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    navigate(`/salesRep/open-invoices/payment/${quote?.id}`, {
      state: {
        totalAmountString: getQuotePrice(quote),
        selectedQuotes: [{ quoteId: quote?.id, amount: getQuotePrice(quote) }]
      }
    });
  };
  return (
    <button type="button" onClick={handleClick} className={styling}>
      Mark as Paid
      {showArrow && <RightArrow className="w-5 h-5 font-bold" />}
    </button>
  );
};

export default MarkAsPaidButton;

MarkAsPaidButton.propTypes = {
  quote: PropTypes.object.isRequired,
  showArrow: PropTypes.bool,
  styling: PropTypes.string
};

MarkAsPaidButton.defaultProps = {
  showArrow: false,
  styling: ''
};

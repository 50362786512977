import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import '../../styles/custom.css';
import Select from 'react-select';
import { debounce } from 'lodash';
import { DatePicker } from 'antd';
import { FaArrowRightLong } from 'react-icons/fa6';
import {
  EquipmentTypes,
  options,
  serviceTypeOptions
} from '../../constants/carrier.constants';
import DashboardCard from './DashboardCard';
import Pagination from '../../components/Pagination';
import PlacesAutoCompleteInput from './PlaceAutoCompleteInput';
import { getDashboardLoads } from '../../services/carrier.service';

const { RangePicker } = DatePicker;

const CarrierDashboard = () => {
  const dropDownStyles = {
    control: (base, state) => ({
      ...base,
      width: state.selectProps.width || '90px',
      borderColor: 'inherit',
      background: '#F6F6F6',
      border: '1px inherit',
      borderRadius: 0,
      boxShadow: 'none',
      '&:hover': {
        border: '1px inherit'
      },
      color: 'black'
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      fontWeight: 'bold',
      fontSize: '14px',
      whiteSpace: 'normal',
      overflow: 'visible',
      textOverflow: 'clip'
    }),
    dropdownIndicator: (baseStyles, state) => ({
      ...baseStyles,
      paddingRight: '12px',
      color: state.isDisabled ? 'gray' : 'black', // Ensure color doesn't change on hover
      '&:hover': {
        color: 'black' // Override hover state color
      }
    }),
    menu: (base) => ({
      ...base,
      // kill the gap
      marginTop: 0
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none'
    })
  };

  const [origin, setOrigin] = useState(options[0]);
  const [destination, setDestination] = useState(options[0]);
  const [serviceType, setServiceType] = useState();
  const [equipmentTypes, setEquipmentTypes] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [quotes, setQuotes] = useState([]);
  const [isAPICalled, setisAPICalled] = useState(false);
  const [filters, setFilters] = useState({});
  const [disableOriginSelect, setDisableOriginSelect] = useState(true);
  const [disableDestinationSelect, setDisableDestinationSelect] =
    useState(true);
  const [isFiltered, setIsFiltered] = useState(false);

  const fetchDashboardLoads = debounce(async (body, isFilterable) => {
    try {
      const response = await getDashboardLoads(
        recordsPerPage,
        currentPage,
        body,
        isFilterable
      );
      if (response?.data?.flag) {
        setQuotes(response?.data?.data);
        setisAPICalled(true);
        setTotalCount(response?.data?.total);
      }
    } catch (error) {
      console.log({ error });
      toast.error(error?.message ?? 'Something went wrong!');
    }
  }, 5);

  const handleOriginInput = (_, latLng) => {
    setFilters({
      ...filters,
      origin: [latLng.lng, latLng.lat],
      originMaxDistance: origin.value
    });
  };
  const handleDestinationInput = (_, latLng) => {
    setFilters({
      ...filters,
      destination: [latLng.lng, latLng.lat],
      destinationMaxDistance: destination.value
    });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = () => {
    setIsFiltered(true);
    setCurrentPage(1);
    fetchDashboardLoads(filters, true);
  };

  const handleOriginDistance = (option) => {
    setOrigin(option);
    setFilters({ ...filters, originMaxDistance: option.value });
  };
  const handleDestinationDistance = (option) => {
    setDestination(option);
    setFilters({ ...filters, destinationMaxDistance: option.value });
  };

  const handleServiceTypeChange = (opt) => {
    setServiceType(opt);

    if (!opt) {
      const newFilters = { ...filters };
      delete newFilters.serviceType;
      setFilters(newFilters);
    } else {
      setFilters({ ...filters, serviceType: opt.value });
    }
  };
  const handleEquipmentTypeChange = (opt) => {
    setEquipmentTypes(opt);

    if (!opt.length) {
      const newFilters = { ...filters };
      delete newFilters.equipmentType;
      setFilters(newFilters);
    } else {
      setFilters({ ...filters, equipmentType: opt.map((item) => item.value) });
    }
  };

  const handleDateChange = (date, dateString) => {
    if (!date) {
      const newFilters = { ...filters };
      delete newFilters.dateRange;
      setFilters(newFilters);
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        dateRange: dateString.map((item) => new Date(item).toISOString())
      }));
    }
  };

  const handleLengthChange = (e) => {
    if (!e.target.value) {
      const newFilters = { ...filters };
      delete newFilters.cargoMaxLength;
      setFilters(newFilters);
    } else {
      setFilters({ ...filters, cargoMaxLength: Number(e.target.value) });
    }
  };
  const handleWeightChange = (e) => {
    if (!e.target.value) {
      const newFilters = { ...filters };
      delete newFilters.cargoMaxWeight;
      setFilters(newFilters);
    } else {
      setFilters({ ...filters, cargoMaxWeight: Number(e.target.value) });
    }
  };

  useEffect(() => {
    if (!isFiltered) {
      fetchDashboardLoads({}, false);
    } else {
      fetchDashboardLoads(filters, isFiltered);
    }
  }, [currentPage]);

  useEffect(() => {
    if (Object.keys(filters).length === 0) {
      setIsFiltered(false);
      fetchDashboardLoads({}, false);
    } else {
      setIsFiltered(true);
    }
  }, [filters]);

  return (
    <div className="flex flex-col py-10 bg-gray-50">
      <div className="container px-4 mx-auto">
        <div className="w-full inline-block">
          <div className="font-extrabold text-2xl mb-4">Shipments</div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col lg:flex-row justify-between">
              <div className="flex flex-col py-2 gap-1.5">
                <div className="flex flex-row">
                  <span className="font-medium mr-36">Origin</span>
                  <span className="origin-input">Miles</span>
                </div>
                <div className="flex flex-row gap-1 items-center">
                  <PlacesAutoCompleteInput
                    onSelect={handleOriginInput}
                    setDisableOriginSelect={setDisableOriginSelect}
                    isOrigin
                    filters={filters}
                    setFilters={setFilters}
                    setOrigin={setOrigin}
                  />
                  <div
                    className={`w-20 ${disableOriginSelect && 'cursor-not-allowed'}`}
                  >
                    <Select
                      value={origin}
                      onChange={handleOriginDistance}
                      options={options}
                      isSearchable={false}
                      styles={dropDownStyles}
                      isDisabled={disableOriginSelect}
                    />
                  </div>
                  <div className="ml-8">
                    <FaArrowRightLong
                      style={{
                        height: '23px',
                        width: '23px'
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col py-2 gap-1.5">
                <div className="flex flex-row">
                  <span className="font-medium mr-28">Destination</span>
                  <span className="destination-input">Miles</span>
                </div>
                <div className="flex flex-row gap-1 items-center">
                  <PlacesAutoCompleteInput
                    onSelect={handleDestinationInput}
                    setDisableDestinationSelect={setDisableDestinationSelect}
                    isOrigin={false}
                    filters={filters}
                    setFilters={setFilters}
                    setDestination={setDestination}
                  />
                  <div
                    className={`w-20 ${disableDestinationSelect && 'cursor-not-allowed'}`}
                  >
                    <Select
                      value={destination}
                      onChange={handleDestinationDistance}
                      options={options}
                      isSearchable={false}
                      styles={dropDownStyles}
                      isDisabled={disableDestinationSelect}
                    />
                  </div>
                  <div className="ml-8">
                    <div className="h-8 w-px bg-gray-300" />
                  </div>
                </div>
              </div>
              <div className="flex flex-col py-2 gap-1.5">
                <span className="font-medium">Maximum Length</span>
                <div className="flex flex-row gap-1 items-center">
                  <input
                    type="number"
                    onChange={handleLengthChange}
                    placeholder="Enter Length"
                    className="carrier-input pl-3 border border-gray-200 placeholder:text-sm focus:outline-none carrier-dashboard-input"
                  />
                  <div className="ml-5">
                    <div className="h-8 w-px bg-gray-300" />
                  </div>
                </div>
              </div>
              <div className="flex flex-col py-2 gap-1.5">
                <span className="font-medium">Weight lbs</span>
                <div className="flex flex-row gap-1 items-center">
                  <input
                    type="number"
                    onChange={handleWeightChange}
                    placeholder="Enter Weight"
                    className="carrier-input pl-3 border border-gray-200 placeholder:text-sm focus:outline-none carrier-dashboard-input"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-4 justify-between">
              <div className="flex flex-col py-2 gap-1.5">
                <span className="font-medium">Service Type</span>
                <div className="flex flex-row gap-1 items-center">
                  <Select
                    value={serviceType}
                    onChange={handleServiceTypeChange}
                    options={serviceTypeOptions}
                    isSearchable={false}
                    styles={dropDownStyles}
                    width="250px"
                    placeholder="Service Type"
                    isClearable
                  />
                  <div className="ml-7">
                    <div className="h-8 w-px bg-gray-300" />
                  </div>
                </div>
              </div>
              <div className="flex flex-col py-2 gap-1.5 w-60 ml-1">
                <span className="font-medium">Equipment Types</span>
                <div className="flex flex-row gap-1 items-center ">
                  <Select
                    value={equipmentTypes}
                    onChange={handleEquipmentTypeChange}
                    options={EquipmentTypes}
                    isSearchable={false}
                    styles={dropDownStyles}
                    width="250px"
                    isMulti
                  />
                  <div className="ml-6">
                    <div className="h-8 w-px bg-gray-300" />
                  </div>
                </div>
              </div>
              <div className="flex flex-col py-2 gap-1.5 lg:ml-9">
                <span className="font-medium">Date Range</span>
                <div className="flex flex-row gap-1 mr-7 items-center">
                  <RangePicker className="h-9" onChange={handleDateChange} />
                </div>
              </div>
              <button
                type="button"
                className={`bg-navy-500 disabled:bg-navy-100 h-12 w-40 mt-6 text-white text-xl rounded-lg ${!isFiltered && 'cursor-not-allowed'}`}
                onClick={handleSearch}
                disabled={!isFiltered}
              >
                Search
              </button>
            </div>
            {isAPICalled && (
              <div className="flex flex-col gap-4 py-4">
                {quotes.map((item) => {
                  return <DashboardCard key={item?.id} quote={item} />;
                })}
              </div>
            )}
            {!totalCount && (
              <h3 className="text-center p-3 font-bold">No shipments found!</h3>
            )}
            <div className="px-4 py-3 flex items-end justify-end border-gray-200 sm:px-6">
              {totalCount > 0 && (
                <Pagination
                  activePage={currentPage}
                  perPage={recordsPerPage}
                  total={totalCount}
                  handlePageChange={(page) => handlePageChange(page)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarrierDashboard;

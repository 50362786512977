import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Controller, useForm, useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import toast from 'react-hot-toast';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';

import Camera from '../../../assets/images/camera.png';
import { ReactComponent as RightArrowIcon } from '../../../assets/images/right_arrow.svg';

import { uploadCargoFileService } from '../../../services/quote.service';

import {
  FREIGHT_CLASS,
  GOODS_CONDITION,
  GOODS_TYPE,
  PACKAGE_TYPE
} from '../../../constants/quote.constants';
import {
  PARTIAL_FLAT,
  TRUCKLOAD_FLAT,
  TRUCKLOAD_VAN,
  LTL_FREIGHT,
  BLANKET_WRAP
} from '../../../constants/service_type.constants';
import {
  calculateDensity,
  getDefaultFreightValue
} from '../../../utils/quote.util';
import {
  formatPickupDeliveryDate,
  formatPickupDeliveryTime
} from '../../../utils/date_conversion.util';

// addCargo
function AddCargo({
  addCargo,
  isAddMode,
  updateCargo,
  initialValues,
  serviceType,
  nextStep,
  selectedCargo,
  showAddNewCargoForm,
  setShowAddNewCargoForm
}) {
  const validationSchema = yup.object({
    goods_condition: yup.mixed().required('Goods Condition is required'),
    freight_class: yup.mixed().when('dummy', {
      is: () => serviceType === LTL_FREIGHT,
      then: () =>
        yup
          .mixed()
          .test({
            name: 'notFirstOption',
            message: 'Please select a valid Freight Class',
            test(value) {
              return value && value.value !== '0';
            }
          })
          .required('Freight Class is required'),
      otherwise: () => yup.mixed().nullable()
    }),
    nmfc_code: yup.mixed().when('dummy', {
      is: () => serviceType === LTL_FREIGHT,
      then: () => yup.mixed().nullable(),
      otherwise: () => yup.mixed().nullable()
    }),
    goods_type: yup.array().nullable(),
    package_type: yup.mixed().required('Cargo Package type is required'),
    width: yup.number().when('dummy', {
      is: () => serviceType === TRUCKLOAD_FLAT || serviceType === TRUCKLOAD_VAN,
      then: () =>
        yup
          .number()
          .positive()
          .min(0)
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          }),
      otherwise: () =>
        yup
          .number()
          .positive()
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          })
          .required('Cargo width is required')
    }),
    length: yup.number().when('dummy', {
      is: () => serviceType === TRUCKLOAD_FLAT || serviceType === TRUCKLOAD_VAN,
      then: () =>
        yup
          .number()
          .positive()
          .min(0)
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          }),
      otherwise: () =>
        yup
          .number()
          .positive()
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          })
          .required('Cargo length is required')
    }),
    height: yup.number().when('dummy', {
      is: () => serviceType === TRUCKLOAD_FLAT || serviceType === TRUCKLOAD_VAN,
      then: () =>
        yup
          .number()
          .positive()
          .min(0)
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          }),
      otherwise: () =>
        yup
          .number()
          .positive()
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          })
          .required('Cargo height is required')
    }),
    weight: yup
      .number()
      .positive()
      .transform((cv, ov) => {
        return ov === '' ? undefined : cv;
      })
      .required('Cargo weight is required'),
    desc: yup.string().required('Cargo description is required'),
    quantity: yup
      .number()
      .positive()
      .transform((cv, ov) => {
        return ov === '' ? undefined : cv;
      })
      // .default(1)
      .required('Cargo quantity is required'),
    tarps: yup.bool(),
    files: yup.string().nullable(),
    cargo_img: yup.string(),
    ship_from: yup.mixed().when('dummy', {
      is: () => ![LTL_FREIGHT, BLANKET_WRAP].includes(serviceType),
      then: () => yup.string().nullable().required('Ship from is required'),
      otherwise: () => yup.string().nullable()
    }),
    ship_to: yup.mixed().when('dummy', {
      is: () => ![LTL_FREIGHT, BLANKET_WRAP].includes(serviceType),
      then: () => yup.string().nullable().required('Ship to is required'),
      otherwise: () => yup.string().nullable()
    }),
    version: yup.string().default('v3'),
    cargo_id: yup.string().default()
  });

  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, isValid },
    trigger,
    reset
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange'
    // defaultValues: initialValues,
  });

  const { getValues: getContextVal } = useFormContext({});
  const cargo = watch();
  const currentPackageType = watch('package_type');
  const cargos = getContextVal('cargos');
  const pickupData = getContextVal('pickupInfo');
  const deliveryData = getContextVal('deliveryInfo');

  const [files, setFiles] = useState();
  const [fileURL, setfileURL] = useState();
  const [isFileSelected, setFileSelected] = useState(false);
  const [density, setDensity] = useState();
  const [cubitFeet, setCubitFeet] = useState();

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles[0]);
    setValue('cargo_img', URL.createObjectURL(acceptedFiles[0]));
    setfileURL(URL.createObjectURL(acceptedFiles[0]));
    setFileSelected(true);
  }, []);

  const { getRootProps } = useDropzone({ onDrop });

  const remove = () => {
    setfileURL(null);
    setFileSelected(false);
  };

  const showTarpsSelection =
    serviceType === TRUCKLOAD_FLAT || serviceType === PARTIAL_FLAT;

  useEffect(() => {
    if (isAddMode) {
      reset({
        quantity: '1',
        goods_condition: { value: '57f638ef95757c64129caea6', label: 'New' },
        freight_class: {
          value: '0',
          label: 'Select Freight Class'
        },
        goods_type: [],
        package_type: PACKAGE_TYPE[0].value
      });
    } else {
      reset(initialValues);
      setfileURL(getValues('cargo_img'));
    }

    setValue(`cargo_id`, uuidv4());
    setValue(`version`, 'v3');
  }, [isAddMode, initialValues]);

  useEffect(() => {
    if (selectedCargo && Object.keys(selectedCargo)?.length) {
      reset({
        ...selectedCargo,
        goods_condition: {
          value: selectedCargo?.goods_condition?.id,
          label: selectedCargo?.goods_condition?.display_name
        },
        goods_type: selectedCargo?.goods_type?.map((type) => type.id),
        freight_class: {
          value: selectedCargo?.freight_class,
          label: selectedCargo?.freight_class
        },
        package_type: selectedCargo?.package_type?.[0].id,
        cargo_img: selectedCargo?.files?.fd
          ? `${process.env.REACT_APP_API_URL}/${selectedCargo?.files?.fd}`
          : '',
        ship_from: pickupData?.length === 1 ? pickupData[0]?.pickup_id : null,
        ship_to:
          deliveryData?.length === 1 ? deliveryData[0]?.delivery_id : null
      });
      setfileURL(
        selectedCargo?.files?.fd
          ? `${process.env.REACT_APP_API_URL}/${selectedCargo?.files?.fd}`
          : ''
      );
    }

    if (selectedCargo && !Object.keys(selectedCargo)?.length) {
      reset({
        goods_type: [],
        quantity: '1',
        goods_condition: { value: '57f638ef95757c64129caea6', label: 'New' },
        freight_class: {
          value: '0',
          label: 'Select Freight Class'
        },
        package_type: PACKAGE_TYPE[0].value
      });
    }
  }, [selectedCargo]);

  const onAddCargo = async (isProceedClicked) => {
    if (isProceedClicked && !showAddNewCargoForm) return;
    if (!showAddNewCargoForm) {
      setShowAddNewCargoForm(true);
      return;
    }
    try {
      let fileId;
      await trigger([
        'goods_condition',
        'freight_class',
        'nmfc_code',
        'goods_type',
        'package_type',
        'width',
        'length',
        'height',
        'weight',
        'desc',
        'quantity',
        'tarps',
        'files',
        'ship_from',
        'ship_to',
        'version',
        'cargo_id'
      ]);
      if (isValid) {
        if (isFileSelected && files) {
          const formData = new FormData();
          formData.append('cargo_image', files);
          const response = await uploadCargoFileService(formData);
          if (response.data.flag) {
            fileId = response.data.id;
            toast.success(response.data.message);
          }
        }
      } else if (errors.files) {
        if (cargo?.cargo_img?.includes('blob:')) {
          const formData = new FormData();
          formData.append('cargo_image', files);
          const response = await uploadCargoFileService(formData);
          if (response.data.flag) {
            fileId = response.data.id;
            toast.success(response.data.message);
          }
        } else {
          fileId = cargo?.files?.id;
        }
      } else {
        return errors;
      }

      if (isAddMode) {
        addCargo({
          goods_condition: cargo.goods_condition,
          freight_class: cargo.freight_class,
          nmfc_code: cargo.nmfc_code,
          goods_type: cargo.goods_type,
          package_type: cargo.package_type,
          width: cargo.width ? parseFloat(cargo.width) : cargo.width,
          length: cargo.length ? parseFloat(cargo.length) : cargo.length,
          height: cargo.height ? parseFloat(cargo.height) : cargo.height,
          weight: parseFloat(cargo.weight),
          desc: cargo.desc,
          quantity: Number(cargo.quantity),
          tarps: cargo.tarps && showTarpsSelection,
          files: fileId,
          version: cargo.version,
          cargo_id: cargo.cargo_id,
          cargo_img: cargo.cargo_img,
          ship_from: cargo.ship_from,
          ship_to: cargo.ship_to
        });
      } else {
        updateCargo({
          goods_condition: cargo.goods_condition,
          freight_class: cargo.freight_class,
          nmfc_code: cargo.nmfc_code,
          goods_type: cargo.goods_type,
          package_type: cargo.package_type,
          width: cargo.width ? parseFloat(cargo.width) : cargo.width,
          length: cargo.length ? parseFloat(cargo.length) : cargo.length,
          height: cargo.height ? parseFloat(cargo.height) : cargo.height,
          weight: parseFloat(cargo.weight),
          desc: cargo.desc,
          quantity: Number(cargo.quantity),
          tarps: cargo.tarps && showTarpsSelection,
          files: fileId,
          version: cargo.version,
          cargo_id: cargo.cargo_id,
          cargo_img: cargo.cargo_img,
          ship_from: cargo.ship_from,
          ship_to: cargo.ship_to
        });
      }
      setShowAddNewCargoForm(false);
      remove();
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const onFileChange = (e) => {
    setFiles(e.target.files[0]);
    setValue('cargo_img', URL.createObjectURL(e.target.files[0]));
    setfileURL(URL.createObjectURL(e.target.files[0]));
    setFileSelected(true);
  };

  const nextButtonClick = async () => {
    const addCargoResponse = await onAddCargo(true);
    if (addCargoResponse) return addCargoResponse;
    if (cargos?.length) {
      let isSafe = true;
      cargos?.forEach((cg) => {
        if (serviceType === LTL_FREIGHT && !cg?.freight_class?.value) {
          isSafe = false;
          return toast.error('Freight class required for each cargo');
        }
      });
      if (isSafe) nextStep();
    } else {
      nextStep();
    }
  };
  useEffect(() => {
    setDensity(calculateDensity(cargo).density);
    setCubitFeet(calculateDensity(cargo).cubitFeet);
  }, [cargo]);

  useEffect(() => {
    const value = getDefaultFreightValue(density);
    setValue('freight_class', value);
  }, [density]);

  return (
    <div className="container mx-auto">
      <div className=" mx-auto">
        <div className="mb-6 lg:mb-10 text-left">
          {showAddNewCargoForm && (
            <div>
              <div className="p-4">
                <label
                  className="w-full block text-black text-xl  mb-6"
                  htmlFor="delivery-date"
                >
                  Packaging
                </label>
                <div>
                  <div className="mb-4 w-1/3 float-left">
                    <label
                      className="w-9/12 overflow-hidden flex flex-col text-center items-center bg-white rounded-xl border-2 border-dotted border-navy-500 cursor-pointer "
                      {...getRootProps({})}
                    >
                      {isFileSelected || fileURL ? (
                        <>
                          <input
                            type="file"
                            multiple={false}
                            accept="image/*"
                            onChange={onFileChange}
                            className="hidden"
                          />
                          <img
                            src={fileURL}
                            className="object-cover w-28 h-28"
                            alt="cargo_img"
                          />
                        </>
                      ) : (
                        <>
                          <span className="mt-2 p-8 pt-10 pb-10  text-base leading-normal">
                            <img
                              className="mx-auto mb-2"
                              src={Camera}
                              alt="camera"
                            />
                            Drag & Drop{' '}
                            <span className="text-gray-400">files here</span>{' '}
                            <br /> <span className="text-gray-400">OR</span>{' '}
                            <br />
                            Click{' '}
                            <span className="text-gray-400">to upload</span>
                          </span>
                          <input
                            type="file"
                            multiple={false}
                            accept="image/*"
                            onChange={onFileChange}
                            className="hidden"
                          />
                        </>
                      )}
                    </label>
                    <button
                      type="button"
                      className="bg-red-500 hover:bg-red-400 text-white font-bold py-1 px-4 border-b-4 border-red-700 hover:border-red-500 rounded mx-20 mt-1 sm:ml-2 md:mx-20"
                      onClick={remove}
                    >
                      Remove
                    </button>
                  </div>
                  <div className="mb-4 w-2/3 float-left">
                    {PACKAGE_TYPE.map((packageType) => {
                      return (
                        <div
                          className="mb-4 mt-4 w-1/2 float-left"
                          key={packageType.value}
                        >
                          <label>
                            <input
                              {...register(`package_type`)}
                              type="radio"
                              value={packageType.value}
                              defaultChecked={
                                currentPackageType?.value === packageType.value
                              }
                            />
                            <span className="ml-1">{packageType.label}</span>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  {errors.package_type && (
                    <span className="text-xs text-red-700">
                      {errors.package_type.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="border  border-gray-200 border-b-0 border-l-0 border-r-0 pt-5 clear-both p-4">
                <p className="text-left w-full block text-black text-xl  mb-6">
                  Dimensions
                </p>

                <div className="mb-8  w-1/4 float-left pr-1">
                  <label
                    className="w-full block text-black text-sm font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    Length
                  </label>
                  <input
                    {...register(`length`)}
                    className="leading-tight shadow appearance-none w-full text-xs bg-white border-gray-200 border-solid border bg-white py-3 px-2"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    // min="0"
                  />
                  {errors.length && (
                    <span className="text-xs text-red-700">
                      {errors.length.message}
                    </span>
                  )}
                </div>

                <div className="mb-8  w-1/4 float-left pr-1">
                  <label
                    className="w-full block text-black text-sm font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    Width
                  </label>
                  <input
                    {...register(`width`)}
                    className="leading-tight shadow appearance-none w-full text-xs  border-gray-200 border-solid border bg-white py-3 px-2"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    // min="0"
                  />
                  {errors.width && (
                    <span className="text-xs text-red-700">
                      {errors.width.message}
                    </span>
                  )}
                </div>

                <div className="mb-8 w-1/4 float-left pr-1">
                  <label
                    className="w-full block text-black text-sm font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    Height
                  </label>
                  <input
                    {...register(`height`)}
                    className="leading-tight shadow appearance-none w-full text-xs bg-white border-gray-200 border-solid border bg-white py-3 px-2"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    // min="0"
                  />
                  {errors.height && (
                    <span className="text-xs text-red-700">
                      {errors.height.message}
                    </span>
                  )}
                </div>

                <div className="mb-8 w-1/4 float-left pr-1">
                  <label
                    className="w-full block text-black text-sm font-bold mb-2"
                    htmlFor="ship-from"
                  >
                    Weight
                  </label>
                  <input
                    {...register(`weight`)}
                    className="leading-tight shadow appearance-none w-full text-xs bg-white border-gray-200 border-solid border bg-white py-3 px-2"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    min="0"
                  />
                  {errors.weight && (
                    <span className="text-xs text-red-700">
                      {errors.weight.message}
                    </span>
                  )}
                </div>
                {cargo.length &&
                cargo.height &&
                cargo.width &&
                cargo.weight &&
                cargo.quantity ? (
                  <div className="mb-8 float-left pr-1">
                    <p className="mb-1 font-heading space-x-2">
                      <span>
                        <b>Density</b>: {density} lb/ft
                        <sup>3</sup>
                      </span>
                    </p>
                    <p>
                      <span>
                        <b> Cubic FT</b>: {cubitFeet} ft
                        <sup>3</sup>
                      </span>
                    </p>
                  </div>
                ) : null}
              </div>

              <div className="border  border-gray-200 border-b-0 border-l-0 border-r-0 pt-5 clear-both p-4">
                <p className="text-left w-full block text-black text-xl  mb-6">
                  Quantity
                </p>
                <div className="mb-3  w-full ">
                  <input
                    {...register(`quantity`)}
                    className="leading-tight shadow appearance-none w-full text-xs bg-white border-gray-200 border-solid border bg-white py-3 px-2"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    min="1"
                  />
                  {errors.quantity && (
                    <span className="text-xs text-red-700">
                      {errors.quantity.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="border  border-gray-200 border-b-0 border-l-0 border-r-0 pt-5 clear-both p-4">
                <p className="text-left w-full block text-black text-xl  mb-6">
                  Goods type
                </p>
                <div className="relative">
                  <Controller
                    name="goods_condition"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={GOODS_CONDITION}
                        className="appearance-none w-full text-xs font-semibold leading-none bg-white  outline-none border-gray-200"
                      />
                    )}
                  />
                  {errors.goods_condition && (
                    <span className="text-xs text-red-700">
                      {errors.goods_condition.message}
                    </span>
                  )}
                </div>

                {GOODS_TYPE.map((goodsType) => {
                  return (
                    <div
                      className="mt-4 mb-4 w-1/2 float-left"
                      key={goodsType.value}
                    >
                      <label>
                        <input
                          {...register(`goods_type`)}
                          type="checkbox"
                          value={goodsType.value}
                        />
                        <span className="ml-1">{goodsType.label}</span>
                      </label>
                    </div>
                  );
                })}
                {errors.goods_type && (
                  <span className="text-xs text-red-700">
                    {errors.goods_type.message}
                  </span>
                )}
              </div>

              {showTarpsSelection ? (
                <div className="border  border-gray-200 border-b-0 border-l-0 border-r-0 pt-5 clear-both p-4">
                  <p className="text-xl text-left text-black">Tarps</p>
                  <div className="p-2 pl-0 relative">
                    <label>
                      <input {...register('tarps')} type="checkbox" />
                      <span className="ml-1">
                        Select this option if you would like us to deliver this
                        cargo covered with tarps
                      </span>
                      {errors.tarps && (
                        <span className="text-xs text-red-700">
                          {errors.tarps.message}
                        </span>
                      )}
                    </label>
                  </div>
                </div>
              ) : (
                ''
              )}
              {serviceType === LTL_FREIGHT ? (
                <div className="border  border-gray-200 border-b-0 border-l-0 border-r-0 pt-5 clear-both p-4 flex">
                  <div className="mt-4 mb-4 w-1/2 float-left flex-1">
                    <p className="text-left w-full block text-black text-xl  mb-6">
                      Freight Class
                    </p>
                    <div className="relative mb-3 w-1/2">
                      <Controller
                        name="freight_class"
                        defaultValue={{
                          value: '0',
                          label: 'Select Freight Class'
                        }}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={FREIGHT_CLASS}
                            className="appearance-none w-full text-xs font-semibold leading-none bg-white  outline-none border-gray-200"
                          />
                        )}
                      />

                      {errors.freight_class && (
                        <span className="text-xs text-red-700">
                          {errors.freight_class.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="mt-4 mb-4 w-1/2 float-right flex-1">
                    <p className="text-left w-full block text-black text-xl  mb-6">
                      NMFC Code
                    </p>
                    <input
                      {...register(`nmfc_code`)}
                      className="leading-tight shadow appearance-none w-1/2 text-xs bg-white border-gray-200 border-solid border bg-white py-2 px-2"
                      type="string"
                    />
                    {errors.nmfc_code && (
                      <span className="text-xs text-red-700">
                        {errors.nmfc_code.message}
                      </span>
                    )}
                  </div>
                </div>
              ) : null}
              {![LTL_FREIGHT, BLANKET_WRAP].includes(serviceType) ? (
                <>
                  <div className="border  border-gray-200 border-b-0 border-l-0 border-r-0 pt-5 clear-both p-4">
                    <p className="text-left w-full block text-black text-xl  mb-6">
                      Ship from
                    </p>
                    {pickupData.map((pickup, index) => {
                      return (
                        <div
                          className="mt-4 mb-4 w-1/2 float-left"
                          key={pickup.id}
                        >
                          <label>
                            <input
                              {...register(`ship_from`)}
                              type="radio"
                              value={pickup.pickup_id}
                              checked={
                                pickupData.length === 1
                                  ? index === 0
                                  : pickup.pickup_id === cargo.ship_from
                              }
                            />
                            <span className="ml-1">
                              {
                                pickup?.pickup_formatted_address?.split(
                                  '|'
                                )?.[0]
                              }
                            </span>
                            <p className="pl-8 pt-1">
                              (
                              {!pickup.pickup_date
                                ? 'flexible date'
                                : formatPickupDeliveryDate(
                                    pickup.pickup_date
                                  )}{' '}
                              |{' '}
                              {!pickup.pickup_time
                                ? 'flexible time'
                                : formatPickupDeliveryTime(pickup.pickup_time)}
                              )
                            </p>
                          </label>
                        </div>
                      );
                    })}
                    {errors.ship_from && (
                      <span className="text-xs text-red-700">
                        {errors.ship_from?.message}
                      </span>
                    )}
                  </div>
                  <div className="border  border-gray-200 border-b-0 border-l-0 border-r-0 pt-5 clear-both p-4">
                    <p className="text-left w-full block text-black text-xl  mb-6">
                      Ship to
                    </p>
                    {deliveryData.map((delivery, index) => {
                      return (
                        <div
                          className="mt-4 mb-4 w-1/2 float-left"
                          key={delivery.id}
                        >
                          <label>
                            <input
                              {...register(`ship_to`)}
                              type="radio"
                              value={delivery.delivery_id}
                              checked={
                                deliveryData.length === 1
                                  ? index === 0
                                  : delivery.delivery_id === cargo.ship_to
                              }
                            />
                            <span className="ml-1">
                              {
                                delivery?.delivery_formatted_address?.split(
                                  '|'
                                )?.[0]
                              }
                            </span>
                            <p className="pl-8 pt-1">
                              (
                              {!delivery.delivery_date
                                ? 'flexible date'
                                : formatPickupDeliveryDate(
                                    delivery.delivery_date
                                  )}{' '}
                              |{' '}
                              {!delivery.delivery_time
                                ? 'flexible time'
                                : formatPickupDeliveryTime(
                                    delivery.delivery_time
                                  )}
                              )
                            </p>
                          </label>
                        </div>
                      );
                    })}
                    {errors.ship_to && (
                      <span className="text-xs text-red-700">
                        {errors.ship_to?.message}
                      </span>
                    )}
                  </div>
                </>
              ) : null}
              <div className="border  border-gray-200 border-b-0 border-l-0 border-r-0 pt-5 clear-both p-4">
                <p className="text-left w-full block text-black text-xl  mb-6">
                  Description
                </p>
                <textarea
                  {...register('desc')}
                  className="resize border w-full"
                  placeholder="make, model, serial number"
                />
                {errors.desc && (
                  <span className="text-xs text-red-700">
                    {errors.desc.message}
                  </span>
                )}
              </div>
            </div>
          )}
          <div className="flex flex-col xl:flex-row w-full">
            <div className="text-left p-4 relative xl:w-1/2">
              <button
                type="button"
                onClick={() => {
                  onAddCargo(false);
                }}
                className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-4 
                        bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700
                     text-sm font-bold transition duration-200 text-left"
              >
                {isAddMode ? 'Add New Cargo' : 'Update Cargo'}
                <RightArrowIcon className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-6 font-bold" />
              </button>

              {/* <svg
                className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white mr-4 mt-6 font-bold"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg> */}
            </div>
            <div className="text-left p-4 relative xl:w-1/2">
              <button
                type="button"
                onClick={nextButtonClick}
                className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-4 
                        bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700
                     text-sm font-bold transition duration-200 text-left"
              >
                Proceed
                <RightArrowIcon className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-6 font-bold" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCargo;

AddCargo.propTypes = {
  addCargo: PropTypes.func.isRequired,
  isAddMode: PropTypes.bool.isRequired,
  updateCargo: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  serviceType: PropTypes.string.isRequired,
  nextStep: PropTypes.func.isRequired,
  selectedCargo: PropTypes.object.isRequired,
  showAddNewCargoForm: PropTypes.bool.isRequired,
  setShowAddNewCargoForm: PropTypes.func.isRequired
  // index: PropTypes.number.isRequired,
};

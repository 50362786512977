import React, { useState } from 'react';
import { Modal, Select, ConfigProvider } from 'antd';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { rolesOptions } from '../../constants/dashboard.constants';
import { useAuthContext } from '../../contexts/auth.context';
import { shipperSignUpService } from '../../services/shipper.service';

const AddNewUserForm = ({
  showAddUserModal,
  setShowAddUserModal,
  fetchUserDetails,
  companyId
}) => {
  const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    email: yup
      .string()
      .required('Email is required!')
      .email('Please enter a valid email.'),
    phone: yup
      .number()
      .typeError('Mobile number must be a number')
      .integer('Mobile number must be an integer')
      .positive('Mobile number must be a positive number')
      .required('Mobile number is required')
      .min(1000000000, 'Mobile number must be at least 10 digits')
      .max(9999999999, 'Mobile number can be at most 10 digits')
      .test('is-num', 'Mobile number must be numeric', (value) => {
        return /^[0-9]*$/.test(value.toString());
      })
  });

  const { authUser } = useAuthContext();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const [currentRole, setCurrentRole] = useState(rolesOptions[0]);

  const onSubmitForm = async (data) => {
    if (isValid) {
      try {
        const response = await shipperSignUpService(
          {
            companyId,
            name: data?.name,
            email: data?.email,
            phone: data?.phone,
            role: currentRole?.value,
            version: process.env.REACT_APP_APP_VERSION,
            salesRepId:
              authUser?.user?.usertype === 'salesRep'
                ? authUser?.user?.id
                : authUser?.user?.salesRepId,
            referralCode: authUser?.referralCode
          },
          false,
          true
        );

        if (response?.data?.flag) {
          toast.success(response?.data?.message);
          setShowAddUserModal(false);
          reset({
            name: '',
            email: '',
            phone: null
          });
          setCurrentRole(rolesOptions[0]);
          fetchUserDetails();
        }
      } catch (error) {
        console.log({ error });
        toast.error(error?.response?.data?.message);
      }
    } else {
      console.log('form not submitted');
    }
  };

  return (
    <Modal
      title={
        <span className="text-navy-500 text-xl font-semibold mx-2">
          Enter New User Details
        </span>
      }
      centered
      open={showAddUserModal}
      onOk={handleSubmit(onSubmitForm)}
      onCancel={() => setShowAddUserModal(false)}
      okButtonProps={{
        style: {
          backgroundColor: '#173773',
          borderColor: '#173773',
          color: 'white',
          width: '80px'
        }
      }}
      cancelButtonProps={{
        style: {
          borderColor: 'red',
          color: 'red',
          backgroundColor: 'white'
        }
      }}
    >
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className="flex flex-col gap-5 mx-2 my-4">
          <div className="flex flex-col gap-2">
            <span className="text-base font-semibold">Name</span>
            <input
              {...register('name')}
              type="text"
              placeholder="Enter Your Name"
              className="border border-gray-200 focus:outline-none rounded-sm h-9 p-2"
            />
            {errors.name && (
              <span className="text-red-600 text-md">
                {errors.name.message}
              </span>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-base font-semibold">Email</span>
            <input
              {...register('email')}
              type="text"
              placeholder="Enter Your Email"
              className="border border-gray-200 focus:outline-none rounded-sm h-9 p-2"
            />
            {errors.email && (
              <span className="text-red-600 text-md">
                {errors.email.message}
              </span>
            )}
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-base font-semibold">Mobile Number</span>
            <input
              {...register('phone')}
              type="text"
              placeholder="Enter Your Phone Number"
              className="border border-gray-200 focus:outline-none rounded-sm h-9 p-2"
            />
            {errors.phone && (
              <span className="text-red-600 text-md">
                {errors.phone.message}
              </span>
            )}
          </div>
          <div className="flex flex-col gap-2 h-9">
            <span className="text-base font-semibold">Role</span>
            <ConfigProvider
              theme={{
                components: {
                  Select: {
                    optionActiveBg: '#DEEBFF',
                    optionFontSize: 13
                  }
                }
              }}
            >
              <Select
                value={currentRole}
                onChange={(_, obj) => setCurrentRole(obj)}
                showSearch
                style={{
                  width: 280
                }}
                defaultActiveFirstOption
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={rolesOptions}
              />
            </ConfigProvider>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddNewUserForm;

AddNewUserForm.propTypes = {
  showAddUserModal: PropTypes.bool.isRequired,
  setShowAddUserModal: PropTypes.func.isRequired,
  fetchUserDetails: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired
};

import React, { useEffect, useState } from 'react';

import toast from 'react-hot-toast';

import QuoteDetailsCard from '../../components/QuoteDetailsCard';
import { VENDORS } from '../../constants/carrier.constants';
import { LTL_FREIGHT } from '../../constants/service_type.constants';

import { getAllRecentQuotes } from '../../services/quote.service';

const RecentQuotesPage = () => {
  const [quotes, setQuotes] = useState([]);
  const [isAPICalled, setisAPICalled] = useState(false);
  const [page, setPage] = useState(1);
  const [isEnd, setIsEnd] = useState(false);

  async function fetchRecentQuotes(isReset, pageNo) {
    try {
      const apiResponse = await getAllRecentQuotes(pageNo || 1, 10);
      const response = apiResponse.data.quotes;

      if (!response.length) setIsEnd(true);

      const finalRes = isReset ? response : [...quotes, ...response];

      if (apiResponse.data.flag) {
        const carriers = VENDORS.map((vendor) => vendor.name);
        const quoteArr = [];
        finalRes.forEach((quote) => {
          if (quote.service_type !== LTL_FREIGHT) {
            if (quote.estimate) quoteArr.push(quote);
          } else {
            const rates = [];
            carriers.forEach((carrier) => {
              const rate = quote[carrier]?.estimate;
              if (rate && rate !== 0) rates.push(rate);
            });
            const quoteData = { ...quote };
            const min = Math.min(...rates);

            quoteData.minRate = min === Infinity ? 0 : min;
            // Set minimum budget to minimum quote rate
            if (quoteData.estimate === 0) {
              quoteData.estimate = quoteData.minRate;
            }
            if (quoteData.minRate) {
              quoteArr.push(quoteData);
            }
          }
        });

        setQuotes(quoteArr);
        setisAPICalled(true);
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    fetchRecentQuotes(true);
  }, []);

  const handleLoadMore = () => {
    fetchRecentQuotes(undefined, page + 1);
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <section>
      <div className="skew skew-top mr-for-radius">
        <svg
          className="w-full h-8 md:h-12 lg:h-20 text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 10 0 10" />
        </svg>
      </div>
      <div className="skew skew-top ml-for-radius">
        <svg
          className="w-full h-8 md:h-12 lg:h-20 text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 10 10 0 10 10" />
        </svg>
      </div>
      <div className="py-20 bg-gray-50 radius-for-skewed">
        <div className="container px-4 mx-auto">
          <div className="relative p-4 pl-0 mb-6">
            <h3 className="text-3xl font-bold text-navy-500 ">Recent Quotes</h3>
          </div>
          <div className="flex flex-wrap -mx-3">
            {isAPICalled && quotes.length > 0
              ? quotes.map((quote) => (
                  <QuoteDetailsCard
                    key={quote.id}
                    quote={quote}
                    loadType="shipper-recent-quotes"
                  />
                ))
              : 'No record found.'}
          </div>
          {!isEnd ? (
            <button
              type="submit"
              className="bg-navy-500 text-white px-10 py-2 text-sm font-semibold float-right mt-4"
              onClick={handleLoadMore}
            >
              Load More
            </button>
          ) : (
            <p className="text-center">No more data</p>
          )}
        </div>
      </div>
      <div className="skew skew-bottom mr-for-radius">
        <svg
          className="w-full h-8 md:h-12 lg:h-20 text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 0 0 10" />
        </svg>
      </div>
      <div className="skew skew-bottom ml-for-radius">
        <svg
          className="w-full h-8 md:h-12 lg:h-20 text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 0 10 10" />
        </svg>
      </div>
    </section>
  );
};

export default RecentQuotesPage;

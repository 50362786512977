import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import appRouter from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

// using createRoot for Concurrent Rendering

// Concurrent Rendering: createRoot is typically used when you want to opt into concurrent rendering for the specified root. Concurrent rendering allows React to work on multiple updates at the same time, potentially improving the performance of your application.

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={appRouter} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

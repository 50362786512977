import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import Roles from '../../constants/roles.constants';
import { VENDORS } from '../../constants/carrier.constants';

const LTLRateTable = memo(
  ({ quote, authUser, isOldQuote, carrierValue, setCarrierValueChange }) => {
    return (
      <div className="w-full my-6">
        <table className="w-full p-4 table-fixed">
          <tbody>
            <tr className="w-full p-4 border-b border-gray-300">
              <td className="w-1/4 p-4 font-bold">Select</td>
              <td className="w-1/4 p-4 font-bold">Carrier</td>
              <td className="w-1/4 p-4 font-bold">Service Name</td>
              <td className="w-1/4 p-4 font-bold">Delivery Days</td>
              {![Roles.SALES_REP, Roles.CUSTOMER_REP].includes(
                authUser.user.usertype
              ) ? (
                <td className="w-1/4 p-4 font-bold">Estimate</td>
              ) : (
                <>
                  <td className="w-1/4 p-4 font-bold">Revenue</td>
                  <td className="w-1/4 p-4 font-bold">Carrier price</td>
                  <td className="w-1/4 p-4 font-bold">Profit</td>
                </>
              )}
            </tr>
            {VENDORS.sort((a, b) => {
              const aEstimate = quote?.[a.name]?.[`${a.name}_estimate`] || 0;
              const bEstimate = quote?.[b.name]?.[`${b.name}_estimate`] || 0;
              return aEstimate - bEstimate;
            }).map((carrier) => {
              return !isOldQuote ? (
                <React.Fragment key={uuidv4()}>
                  {quote?.[carrier.name]?.[`${carrier.name}_estimate`] ? (
                    <tr className="w-full p-4 border-b border-gray-300">
                      <td>
                        <label>
                          <input
                            type="radio"
                            value={carrier.name}
                            checked={carrierValue === carrier.name}
                            onChange={(e) => setCarrierValueChange(e)}
                          />
                          <span className="ml-1"> </span>
                        </label>
                      </td>
                      <td className="w-2/4 p-4 uppercase ">
                        {carrier.display_name}
                      </td>
                      <td className="w-2/4 p-4 uppercase">{carrier.service}</td>
                      <td className="w-2/4 p-4">
                        {quote[carrier.name]?.transitDays
                          ? quote[carrier.name].transitDays
                          : 'N/A'}
                      </td>
                      <td className="w-1/4 p-4">
                        $
                        {quote[carrier.name] && quote[carrier.name].estimate
                          ? quote[carrier.name].estimate.toFixed(2)
                          : '0.00'}
                      </td>
                      {[Roles.SALES_REP, Roles.CUSTOMER_REP].includes(
                        authUser.user.usertype
                      ) ? (
                        <>
                          <td className="w-1/4 p-4">
                            $
                            {quote[carrier.name] && quote[carrier.name].estimate
                              ? quote[carrier.name][
                                  `${carrier.name}_estimate`
                                ].toFixed(2)
                              : '0.00'}
                          </td>
                          <td className="w-1/4 p-4">
                            $
                            {quote?.[carrier.name]?.commission &&
                            quote[carrier.name].commission
                              ? quote[carrier.name].commission.toFixed(2)
                              : '0.00'}
                          </td>
                        </>
                      ) : null}
                    </tr>
                  ) : null}
                </React.Fragment>
              ) : (
                <tr className="w-full p-4 border-b border-gray-300">
                  <td className="w-1/4 p-4 uppercase">
                    <div className="w-6 h-6 ml-2 bg-gray-200 rounded-full" />
                  </td>
                  {[...Array(6)].map(() => {
                    return (
                      <td className="w-1/4 p-4 uppercase" key={uuidv4()}>
                        <div className="rounded bg-gray-200 h-0.5" />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
);

LTLRateTable.propTypes = {
  quote: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  isOldQuote: PropTypes.bool.isRequired,
  carrierValue: PropTypes.string.isRequired,
  setCarrierValueChange: PropTypes.any.isRequired
};

export default LTLRateTable;

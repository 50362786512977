import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  ElementsConsumer
} from '@stripe/react-stripe-js';

import toast from 'react-hot-toast';

import { createIntentForPaymentService } from '../../services/payment.service';
import { bookNowQuoteService } from '../../services/quote.service';

const CheckoutForm = ({ stripe, elements }) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isPaymentLoading, setPaymentLoading] = useState(false);

  const [clientSecret, setclientSecret] = useState();

  const { quoteId } = useParams();

  async function getClientSecret() {
    try {
      const paymentIntentRes = await createIntentForPaymentService(
        quoteId,
        state?.quote.service_type
      );

      if (paymentIntentRes.data.flag) {
        setclientSecret(paymentIntentRes.data.clientSecret);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    getClientSecret();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setPaymentLoading(true);
    try {
      const { paymentIntent, error } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement)
          }
        }
      );

      setPaymentLoading(false);
      if (error) {
        alert(error.message);
      } else if (paymentIntent.status === 'succeeded') {
        const response = await bookNowQuoteService({
          id: quoteId,
          pickup_address: JSON.stringify(state.data.pickup_address),
          delivery_address: JSON.stringify(state.data.delivery_address)
        });
        if (response.data.flag) {
          toast.success('Payment successfully completed!');
          navigate('/thank-you', { replace: true });
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  return (
    <div className="p-3 mb-5">
      <h2 className="my-5 text-4xl font-bold">Payment Confirmation</h2>
      <form onSubmit={handleSubmit} className="w-full">
        <CardElement className="px-2 py-3 border border-black " />
        <button
          type="submit"
          className="my-5 py-2 px-10 md:float-right border border-black text-white hover:text-black bg-black hover:bg-white transition-all transition-75"
          disabled={isPaymentLoading}
        >
          {isPaymentLoading ? 'Loading...' : 'Pay'}
        </button>
      </form>
    </div>
  );
};

CheckoutForm.propTypes = {
  stripe: PropTypes.any.isRequired,
  elements: PropTypes.any.isRequired
};

const InjectedCheckoutForm = () => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutForm stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);

const ShipperPaymentPage = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  return (
    <section className="py-20 ">
      <div className="container mx-auto px-4">
        {/* <div className="flex flex-wrap text-center mx-auto"> */}
        <Elements stripe={stripePromise}>
          <InjectedCheckoutForm />
        </Elements>
        {/* </div> */}
      </div>
    </section>
  );
};

export default ShipperPaymentPage;

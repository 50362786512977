import toast from 'react-hot-toast';
import { generateInvoice } from '../services/invoice.service';

const useGenerateInvoice = () => {
  const handleGenerateInvoice = async (quoteIds) => {
    try {
      const response = await generateInvoice({ quoteIds });
      if (response && response.data) {
        const { flag, errorWhileInvoicing, successfullyInvoiced } =
          response.data;

        if (flag) {
          if (!errorWhileInvoicing.length) {
            if (successfullyInvoiced.length === 1) {
              toast.success('Invoice Generated Successfully!');
            } else {
              toast.success('All Invoices Generated Successfully!');
            }
          } else {
            if (successfullyInvoiced.length) {
              toast.success('Invoices Generated Successfully!');
            }
            errorWhileInvoicing.forEach((element) => {
              toast.error(element?.message ?? 'Something Went Wrong!');
            });
          }
        } else {
          toast.error(response.data.message ?? 'Something Went Wrong!');
        }
      } else {
        toast.error('Invalid response from server!');
      }
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  return handleGenerateInvoice;
};

export default useGenerateInvoice;

import React from 'react';
import { Link } from 'react-router-dom';

// import NETLogo from '../../assets/images/Screen-Shot-2021-08-12-at-1-03-20-PM.png';
import NETFooterLogo from '../../assets/images/Nationwide-Logo.svg';

const ProtectedHeader = () => {
  return (
    <section>
      <nav className="relative py-6 bg-navy-500">
        <div className="container mx-auto px-4 flex justify-between items-center">
          <Link className="text-3xl font-bold leading-none bg-white" to="/">
            <img src={NETFooterLogo} alt="" width="150" />
          </Link>
        </div>
      </nav>
    </section>
  );
};

export default ProtectedHeader;

import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { deleteShipmentService } from '../../services/quote.service';

const DeletePopup = ({ quote, setOpenModal }) => {
  const navigate = useNavigate();
  const onDeleteShipment = async () => {
    try {
      const response = await deleteShipmentService({ quoteId: quote.id });
      toast.success(response?.data?.message);
      navigate(-1);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
      setOpenModal(false);
    }
  };
  return (
    <div
      className="fixed inset-0 z-10 overflow-y-auto backdrop-filter 
                backdrop-brightness-50"
    >
      <div className="flex items-center min-h-screen px-4 py-8">
        <div className="relative w-full max-w-lg py-8 mx-auto bg-white rounded-md shadow-lg">
          <div className="mt-3 sm:flex justify-center">
            <div className="mt-2 text-center sm:ml-4 sm:text-left">
              <h4 className="text-lg font-medium text-gray-800">
                Delete shipment ?
              </h4>
              <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                Do you really want to delete this shipment?
              </p>
              <div className="items-center gap-2 mt-3 sm:flex">
                <button
                  type="button"
                  className="w-full mt-2 p-2.5 flex-1 text-white bg-red-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                  onClick={onDeleteShipment}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                  onClick={() => setOpenModal(false)}
                >
                  Exit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletePopup;

DeletePopup.propTypes = {
  quote: PropTypes.object.isRequired,
  setOpenModal: PropTypes.bool.isRequired
};

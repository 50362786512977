import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';

import { getQuoteDetailsService } from '../../services/quote.service';

import { ReactComponent as LeftArrowIcon } from '../../assets/images/left_arrow.svg';

const AddressInfoPage = () => {
  const { quoteId } = useParams();
  const navigate = useNavigate();

  const [isPickUpActive, setisPickUpActive] = useState(true);
  const [isDeliveryActive, setisDeliveryActive] = useState(false);
  const [pickupData, setPickupData] = useState([]);
  const [deliveryData, setDeliveryData] = useState([]);
  const [isAPICalled, setisAPICalled] = useState(false);

  const toggleAddress = () => {
    setisPickUpActive((prevPickup) => !prevPickup);
    setisDeliveryActive((prevDelivery) => !prevDelivery);
  };

  async function fetchQuoteDetails() {
    try {
      const response = await getQuoteDetailsService(quoteId);

      setisAPICalled(true);
      const quoteData = response.data.quote;

      setPickupData(
        quoteData.isMultipleQuote ? quoteData.pickupInfo : [quoteData]
      );
      setDeliveryData(
        quoteData.isMultipleQuote ? quoteData.deliveryInfo : [quoteData]
      );
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }
  useEffect(() => {
    fetchQuoteDetails();
  }, [quoteId]);

  const onBackArrowClick = () => {
    navigate(-1);
  };

  return (
    <section>
      <div className="skew skew-top mr-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 10 0 10" />
        </svg>
      </div>
      <div className="skew skew-top ml-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 10 10 0 10 10" />
        </svg>
      </div>
      <div className="py-20 bg-gray-50 radius-for-skewed">
        <div className="container mx-auto px-4">
          <div
            onClick={onBackArrowClick}
            role="button"
            tabIndex="-1"
            onKeyPress={() => {}}
            className="mb-6  p-4 relative"
          >
            <LeftArrowIcon className="w-6 h-6 absolute inset-y-0 left-0 flex items-center mt-6 text-navy-500" />
            {/* <svg
                stroke="currentColor"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 absolute inset-y-0 left-0 flex items-center mt-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 19l-7-7m0 0l7-7m-7 7h18"
                />
              </svg> */}
            <h3 className="text-3xl font-bold ml-4 text-navy-500">
              Address info
            </h3>
          </div>
          <div className="max-w-3xl ">
            <div className="flex flex-wrap px-4 text-center lg:-mx-4 lg:space-x-4 text-base lg:text-xl">
              <button
                type="button"
                onClick={toggleAddress}
                className={`${
                  isPickUpActive
                    ? 'px-4 py-4 w-full md:w-1/2 lg:w-auto font-bold text-navy-500 border-b-2 border-navy-500'
                    : 'px-4 py-4 w-full md:w-1/2 lg:w-auto font-bold text-gray-400 hover:text-navy-600 border-b-2 border-transparent border-gray-300 hover:border-navy-600 transition duration-150'
                }`}
              >
                Pickup address
              </button>
              <button
                type="button"
                onClick={toggleAddress}
                className={`${
                  isDeliveryActive
                    ? 'px-4 py-4 w-full md:w-1/2 lg:w-auto font-bold text-navy-500 border-b-2 border-navy-500'
                    : 'px-4 py-4 w-full md:w-1/2 lg:w-auto font-bold text-gray-400 hover:text-navy-600 border-b-2 border-transparent border-gray-300 hover:border-navy-600 transition duration-150'
                }`}
              >
                Delivery address
              </button>
            </div>
          </div>
          {isAPICalled ? (
            <div className="mx-auto">
              {isPickUpActive && (
                <form action="">
                  {pickupData.map((pick) => {
                    return (
                      <>
                        <div className="space-y-8 my-8 mx-4">
                          <div>
                            <label
                              className="w-full block text-black text-sm font-bold mb-2"
                              htmlFor=""
                            >
                              Company Name
                            </label>
                            <input
                              className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
                              type="text"
                              name="company-name"
                              readOnly
                              value={pick?.pickup_address?.company}
                            />
                          </div>
                          <div>
                            <label
                              className="w-full block text-black text-sm font-bold mb-2"
                              htmlFor=""
                            >
                              Address line 1
                            </label>
                            <input
                              className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
                              type="text"
                              name="address-detail1"
                              readOnly
                              value={pick?.pickup_address?.address1}
                            />
                          </div>
                          <div>
                            <label
                              className="w-full block text-black text-sm font-bold mb-2"
                              htmlFor=""
                            >
                              Address line 2
                            </label>
                            <input
                              className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
                              type="text"
                              name="address-detail2"
                              readOnly
                              value={pick?.pickup_address?.address2}
                            />
                          </div>
                          <div>
                            <label
                              className="w-full block text-black text-sm font-bold mb-2"
                              htmlFor=""
                            >
                              City
                            </label>
                            <input
                              className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
                              type="text"
                              name="city"
                              readOnly
                              value={pick?.pickup_address?.city}
                            />
                          </div>
                          <div>
                            <label
                              className="w-full block text-black text-sm font-bold mb-2"
                              htmlFor=""
                            >
                              State
                            </label>
                            <input
                              className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
                              type="text"
                              name="state"
                              readOnly
                              value={pick?.pickup_address?.state}
                            />
                          </div>
                          <div>
                            <label
                              className="w-full block text-black text-sm font-bold mb-2"
                              htmlFor=""
                            >
                              Zip Code
                            </label>
                            <input
                              className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
                              type="text"
                              name="zip-code"
                              readOnly
                              value={pick?.pickup_address?.zip}
                            />
                          </div>
                          <div>
                            <label
                              className="w-full block text-black text-sm font-bold mb-2"
                              htmlFor=""
                            >
                              Contact person name
                            </label>
                            <input
                              className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
                              type="text"
                              name="contact-name"
                              readOnly
                              value={pick?.pickup_address?.contactperson}
                            />
                          </div>
                          <div>
                            <label
                              className="w-full block text-black text-sm font-bold mb-2"
                              htmlFor=""
                            >
                              Mobile Number
                            </label>
                            <input
                              className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
                              type="text"
                              name="mobile-number"
                              readOnly
                              value={pick?.pickup_address?.contactnumber}
                            />
                          </div>
                        </div>
                        <hr />
                      </>
                    );
                  })}
                </form>
              )}
              {isDeliveryActive && (
                <form action="">
                  {deliveryData?.map((del) => {
                    return (
                      <>
                        <div className="space-y-8 my-8 mx-4">
                          <div>
                            <label
                              className="w-full block text-black text-sm font-bold mb-2"
                              htmlFor=""
                            >
                              Company Name
                            </label>
                            <input
                              className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
                              type="text"
                              name="company-name"
                              readOnly
                              value={del?.delivery_address?.company}
                            />
                          </div>
                          <div>
                            <label
                              className="w-full block text-black text-sm font-bold mb-2"
                              htmlFor=""
                            >
                              Address line 1
                            </label>
                            <input
                              className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
                              type="text"
                              name="address-detail1"
                              readOnly
                              value={del?.delivery_address?.address1}
                            />
                          </div>
                          <div>
                            <label
                              className="w-full block text-black text-sm font-bold mb-2"
                              htmlFor=""
                            >
                              Address line 2
                            </label>
                            <input
                              className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
                              type="text"
                              name="address-detail2"
                              readOnly
                              value={del?.delivery_address?.address2}
                            />
                          </div>
                          <div>
                            <label
                              className="w-full block text-black text-sm font-bold mb-2"
                              htmlFor=""
                            >
                              City
                            </label>
                            <input
                              className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
                              type="text"
                              name="city"
                              readOnly
                              value={del?.delivery_address?.city}
                            />
                          </div>
                          <div>
                            <label
                              className="w-full block text-black text-sm font-bold mb-2"
                              htmlFor=""
                            >
                              State
                            </label>
                            <input
                              className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
                              type="text"
                              name="state"
                              readOnly
                              value={del?.delivery_address?.state}
                            />
                          </div>
                          <div>
                            <label
                              className="w-full block text-black text-sm font-bold mb-2"
                              htmlFor=""
                            >
                              Zip Code
                            </label>
                            <input
                              className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
                              type="text"
                              name="zip-code"
                              readOnly
                              value={del?.delivery_address?.zip}
                            />
                          </div>
                          <div>
                            <label
                              className="w-full block text-black text-sm font-bold mb-2"
                              htmlFor=""
                            >
                              Contact person name
                            </label>
                            <input
                              className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
                              type="text"
                              name="contact-name"
                              readOnly
                              value={del?.delivery_address?.contactperson}
                            />
                          </div>
                          <div>
                            <label
                              className="w-full block text-black text-sm font-bold mb-2"
                              htmlFor=""
                            >
                              Mobile Number
                            </label>
                            <input
                              className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
                              type="text"
                              name="mobile-number"
                              readOnly
                              value={del?.delivery_address?.contactnumber}
                            />
                          </div>
                        </div>
                        <hr />
                      </>
                    );
                  })}
                </form>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="skew skew-bottom mr-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 0 0 10" />
        </svg>
      </div>
      <div className="skew skew-bottom ml-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 0 10 10" />
        </svg>
      </div>
    </section>
  );
};

export default AddressInfoPage;

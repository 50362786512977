import React, { useEffect, useState, Fragment } from 'react';
import toast from 'react-hot-toast';
import { SERVICE_TYPES } from '../../constants/quote.constants';
import {
  salesRepProfile,
  updateSettings
} from '../../services/salesrep.service';
import { customerRepProfileService } from '../../services/customerRep.service';
import { useAuthContext } from '../../contexts/auth.context';

const GeneralSettings = () => {
  const [serviceTypes, setServiceTypes] = useState([]);
  const [isAPICalled, setAPICalled] = useState(false);
  const { authUser } = useAuthContext();
  async function fetchSalesRepProfile() {
    try {
      const userType = authUser?.user?.usertype;
      const userId = authUser?.user?.id;
      const allTypes = SERVICE_TYPES.map((service) => service.value);

      const response =
        userType === 'salesRep'
          ? await salesRepProfile(userId)
          : await customerRepProfileService(userId);
      setServiceTypes(
        !response?.data?.emailServiceTypes
          ? allTypes
          : response.data.emailServiceTypes
      );
      setAPICalled(true);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    fetchSalesRepProfile();
  }, []);

  const onServiceChange = (e, service) => {
    if (e.target.checked) {
      setServiceTypes((oldServices) => [...oldServices, service]);
    } else {
      setServiceTypes(serviceTypes.filter((s) => s !== service));
    }
  };

  const saveSettings = async () => {
    try {
      const response = await updateSettings({
        emailServiceTypes: serviceTypes
      });
      if (response?.data?.flag) {
        toast.success('Settings saved successfully');
        setServiceTypes(response.data.emailServiceTypes);
      } else {
        toast.error('Error in save settings');
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };
  return (
    <section className="py-4">
      <div className="container mx-auto">
        <div className="mx-auto">
          <div className="mb-8 w-full lg:w-1/2 px-3">
            <div className="mb-2  p-4 relative pl-0">
              <h3 className="text-3xl font-bold text-navy-500">
                General Settings
              </h3>
            </div>

            <div className="mb-6">
              <h2 className="text-2xl font-bold pb-4">Notifications</h2>
              <h4 className="text-xl font-bold pb-4">Service Types</h4>

              {SERVICE_TYPES.map((serviceType) => {
                return (
                  <Fragment key={serviceType.value}>
                    {isAPICalled ? (
                      <div className="mb-4" key={serviceType.value}>
                        <label>
                          <input
                            type="checkbox"
                            value={serviceType.value}
                            onChange={(e) => {
                              onServiceChange(e, serviceType.value);
                            }}
                            defaultChecked={serviceTypes.includes(
                              serviceType.value
                            )}
                          />
                          <span className="ml-1">{serviceType.label}</span>
                        </label>
                      </div>
                    ) : null}
                  </Fragment>
                );
              })}
            </div>
            <div className="text-left w-full md:w-9/12 pt-4 pb-2">
              <button
                type="button"
                onClick={saveSettings}
                className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-4
                        bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                      hover:border-navy-600 active:border-navy-700 text-lg font-bold transition duration-200 text-left"
              >
                Save
                <span className="pointer-events-none float-right px-2 mr-2 text-xl">
                  &#10003;
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GeneralSettings;

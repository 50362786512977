import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useAuthContext } from '../../contexts/auth.context';
import {
  SalesRepColumns,
  ShipperColumns
} from '../../constants/columns.constants';
import Roles from '../../constants/roles.constants';
import { updateSettings } from '../../services/salesrep.service';
import { updateUserSettings } from '../../services/shipper.service';

const DashboardSettings = ({ closeModal, onApply, selectedFilters }) => {
  const [columnAttr, setColumnAttr] = useState(selectedFilters);

  const [filterSelected, setfilterSelected] = useState(false);

  const { authUser } = useAuthContext();

  const columns =
    authUser.user.usertype === Roles.SHIPPER ? ShipperColumns : SalesRepColumns;

  useEffect(() => {
    if (columnAttr.length) {
      setfilterSelected(true);
    } else {
      setfilterSelected(false);
    }
  }, [columnAttr]);

  const onServiceChange = (e, service) => {
    if (e.target.checked) {
      setColumnAttr((oldServices) => [...oldServices, service]);
    } else {
      setColumnAttr(columnAttr.filter((s) => s !== service));
    }
  };

  const onSubmitFilters = async () => {
    try {
      const filtersData = {};
      if (filterSelected) {
        if (columnAttr.length) {
          filtersData.column = columnAttr;
        }
      }
      if (!filtersData?.column?.length)
        return toast.error('Select atleast one column');

      let response;

      if (authUser.user.usertype === Roles.SHIPPER) {
        response = await updateUserSettings({
          columns: filtersData.column || []
        });
      } else {
        response = await updateSettings({
          columns: filtersData.column || []
        });
      }
      console.log({ response });
      toast.success('Settings saved successfully');
      onApply(response?.data?.columns);
      closeModal(true);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  return (
    <div
      className="fixed z-10 overflow-y-auto top-0 w-full h-full left-0"
      id="modal"
    >
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-900 opacity-75" />
      </div>
      <div className="container mx-auto bg-gray-100 relative z-10">
        <div className="mb-6 lg:mb-10 text-left p-6">
          <div className="flex items-center mb-6  p-4 ">
            <div className="w-9/12">
              <h3 className="text-3xl font-bold inline-block text-navy-500">
                Settings
              </h3>
            </div>
          </div>
          <form action="">
            <div className="p-4">
              <label
                className="w-full block text-black text-xl  mb-6 font-bold"
                htmlFor="delivery-date"
              >
                Column Attributes
              </label>
              {columns.map((column) => {
                return (
                  <div
                    className="mb-4 w-full lg:w-1/4 float-left"
                    key={column.value}
                  >
                    <label>
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        name="checkbox-name"
                        value={column.value}
                        onChange={(e) => {
                          onServiceChange(e, column.value);
                        }}
                        defaultChecked={selectedFilters.includes(column.value)}
                      />
                      <span className="ml-1">{column.displayName}</span>
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="p-4 flex w-full justify-end">
              <div className="m-2">
                <button
                  type="button"
                  onClick={closeModal}
                  className="px-10 mb-2 w-full py-4 
                    bg-white hover:bg-red-500  font-bold
                    text-navy-500 hover:text-white active:text-navy-600   
                    transition duration-200 text-center"
                >
                  Cancel
                </button>
              </div>
              <div className="m-2">
                <button
                  type="button"
                  onClick={() => {
                    onSubmitFilters();
                  }}
                  className="px-10 mb-2 w-full py-4 
                    bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700
                                hover:border-navy-600 active:border-navy-700
                     font-bold text-white
                    transition duration-200 text-center border "
                >
                  Apply
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DashboardSettings;

DashboardSettings.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array.isRequired
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import GoogleMapReact from 'google-map-react';

import Marker from '../Marker';

import { ReactComponent as TruckIcon } from '../../assets/images/delivery_truck.svg';

const GoogleMapComponent = ({
  pickupLatLong,
  deliveryLatLong,
  isTrackingEnabled,
  carrierLocation
}) => {
  const [mapData, setMapData] = useState();
  const [mapsData, setMapsData] = useState();

  const latLongs = [...(pickupLatLong ?? []), ...(deliveryLatLong ?? [])];
  const getMapBounds = (maps) => {
    const bounds = new maps.LatLngBounds();
    latLongs?.forEach((data) => {
      bounds.extend(new maps.LatLng(data?.lat, data?.lng));
    });

    if (isTrackingEnabled) {
      bounds.extend(
        new maps.LatLng(carrierLocation.latitude, carrierLocation.longitude)
      );
    }
    return bounds;
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

  const apiIsLoaded = (map, maps) => {
    // Get bounds by our places
    const bounds = getMapBounds(maps);

    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  };

  useEffect(() => {
    if (mapData && mapsData) {
      apiIsLoaded(mapData, mapsData);
    }
  }, [latLongs]);

  return (
    <GoogleMapReact
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => {
        setMapData(map);
        setMapsData(maps);
        apiIsLoaded(map, maps);
      }}
      bootstrapURLKeys={{
        key: ' AIzaSyC_3WmDjIG2L4k8sm1P3lvcEt-_VgA4Bhg'
      }}
      center={{ lat: latLongs[0]?.lat, lng: latLongs[0]?.lng }}
      zoom={11}
    >
      {latLongs?.map((data, index) => {
        // fix: Marker not sticking to coordinates provided when moving map
        return (
          <Marker
            key={`${data.lat}-${data.lng}`}
            lat={data?.lat}
            lng={data?.lng}
            text={String.fromCharCode(65 + index)}
          />
        );
      })}

      {isTrackingEnabled && (
        <div lat={carrierLocation.latitude} lng={carrierLocation.longitude}>
          <TruckIcon />
        </div>
      )}
    </GoogleMapReact>
  );
};

export default GoogleMapComponent;

GoogleMapComponent.propTypes = {
  pickupLatLong: PropTypes.array.isRequired,
  deliveryLatLong: PropTypes.array.isRequired,
  isTrackingEnabled: PropTypes.bool,
  carrierLocation: PropTypes.object
};

GoogleMapComponent.defaultProps = {
  isTrackingEnabled: false,
  carrierLocation: {
    latitude: 35.6872,
    longitude: -97.3301
  }
};

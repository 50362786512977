import React, { useContext, useEffect, useState } from 'react';
import { Select } from 'antd';
import toast from 'react-hot-toast';
import Roles from '../../../constants/roles.constants';
import {
  FREIGHT_CLASS,
  PACKAGE_TYPE
} from '../../../constants/quote.constants';
import { GlobalFormContext } from '../../../contexts/QuickQuoteContext';
import QuickQuoteCargoTile from '../QuickQuoteCargoTile';
import { useAuthContext } from '../../../contexts/auth.context';
import { getSavedCargosService } from '../../../services/shipper.service';
import {
  LTL_FREIGHT,
  TRUCKLOAD_FLAT,
  TRUCKLOAD_VAN
} from '../../../constants/service_type.constants';
import { isDuplicate } from '../../../constants/generalConstants';
import {
  calculateDensity,
  getDefaultFreightValue
} from '../../../utils/quote.util';

const CargoCard = () => {
  const { authUser } = useAuthContext;

  const {
    cargos,
    setCargos,
    serviceType,
    currentCustomer,
    handleAddCargo,
    cargoType,
    setCargotype,
    cargoValidate,
    setCargoValidate,
    length,
    setLength,
    width,
    setWidth,
    height,
    setHeight,
    weight,
    setWeight,
    description,
    setDescription,
    isDimensionMandatory,
    setIsDimensionMandatory,
    clearCargoForm,
    validate,
    allCargoFieldsEmpty,
    freightClass,
    setFreightClass,
    nmfcCode,
    setNmfcCode,
    lengthRef
  } = useContext(GlobalFormContext);

  const [isEditMode, setIsEditMode] = useState(false);
  const [editableCargo, setEditableCargo] = useState({});
  const [computedDensity, setComputedDensity] = useState(null);

  const handleSelect = (_, option) => {
    setCargotype(option);
  };

  const handleFreightSelect = (_, option) => {
    setFreightClass(option);
  };

  const handleEditCargo = (cargo) => {
    console.log({ cargo });
    setIsEditMode(true);
    setCargotype(cargo.cargoType);
    setLength(cargo.length);
    setWidth(cargo.width);
    setHeight(cargo.height);
    setWeight(cargo.weight);
    setDescription(cargo.description);
    setEditableCargo(cargo);
    setFreightClass(cargo?.freightClass);
    setNmfcCode(cargo?.nmfcCode);
  };

  const handleCancel = () => {
    setIsEditMode(false);
    clearCargoForm();
    setEditableCargo({});
  };

  const handleSave = () => {
    if (
      !cargoValidate ||
      !weight ||
      !description ||
      (isDimensionMandatory && (!length || !width || !height))
    ) {
      setCargoValidate(true);

      if (!description || !weight) {
        return;
      }
      if (isDimensionMandatory && (!length || !width || !height)) {
        return;
      }
    }
    const updatedCargos = cargos.map((item) =>
      item.id === editableCargo.id
        ? {
            ...item,
            cargoType,
            length,
            width,
            height,
            weight,
            description,
            freightClass,
            nmfcCode
          }
        : item
    );
    setCargos(updatedCargos);
    handleCancel();
  };

  const getSavedCargos = async () => {
    try {
      if (!cargos?.length) {
        const companyId =
          authUser?.user?.usertype === Roles.SHIPPER
            ? authUser?.user?.companyId?.id
            : currentCustomer?.id;
        const response = await getSavedCargosService(companyId);
        const cgData = response.data.data;
        const defaultCg = cgData?.find?.((cg) => cg.isDefault);

        if (defaultCg && Object.keys(defaultCg).length) {
          const updatedDefaultCargos = [
            ...cargos,
            {
              id: defaultCg.id,
              cargoType: PACKAGE_TYPE.find(
                (item) => item.value === defaultCg?.package_type[0]?.id
              ),
              length: defaultCg?.length,
              width: defaultCg?.width,
              height: defaultCg?.height,
              weight: defaultCg?.weight,
              description: defaultCg?.desc
            }
          ];
          setCargos(updatedDefaultCargos);
        }
      }
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  useEffect(() => {
    if (length && width && height && weight) {
      const cargo = { length, width, height, weight };
      setComputedDensity(calculateDensity(cargo));
    }
  }, [length, width, height, weight]);

  useEffect(() => {
    if (serviceType === LTL_FREIGHT) {
      const val = getDefaultFreightValue(computedDensity?.density);
      setFreightClass(val);
    } else {
      setFreightClass('');
    }
  }, [serviceType, computedDensity?.density]);

  useEffect(() => {
    if (serviceType) {
      setIsDimensionMandatory(
        ![TRUCKLOAD_VAN, TRUCKLOAD_FLAT].includes(serviceType)
      );
    }
  }, [serviceType]);

  useEffect(() => {
    if (Object.keys(currentCustomer).length && !isDuplicate()) {
      getSavedCargos();
    }
  }, [currentCustomer]);

  return (
    <div className="flex flex-col gap-2 w-full items-start">
      <div className="flex flex-row justify-between w-full">
        <span className="font-medium">Cargo</span>
        {!allCargoFieldsEmpty() && (
          <span
            className="font-medium underline text-red-600 cursor-pointer"
            onClick={clearCargoForm}
          >
            Reset
          </span>
        )}
      </div>
      <div className="flex flex-col bg-navy-20 p-2 w-full items-start gap-4">
        <div className="flex flex-row w-full gap-12">
          <div className="flex flex-col gap-1 h-14 items-start">
            <span className="text-gray-500 font-medium">Cargo Type</span>
            <Select
              className="text-left"
              value={cargoType}
              style={{
                width: 227
              }}
              onSelect={handleSelect}
              options={PACKAGE_TYPE}
            />
          </div>
          <div className="flex flex-col gap-1 items-start">
            <span className="text-gray-500 font-medium">
              {isDimensionMandatory && <span className="text-red-600">*</span>}{' '}
              Length | Width | Height
            </span>
            <div className="flex flex-row items-start w-full ">
              <div className="flex flex-col gap-2">
                <input
                  ref={lengthRef}
                  value={length}
                  onChange={(e) => setLength(e.target.value)}
                  type="number"
                  placeholder="Enter Length"
                  className="focus:outline-none h-7 text-center border border-t border-l border-b border-gray-300"
                  style={{ borderRight: 'none' }}
                />
                {cargoValidate && isDimensionMandatory && !length && (
                  <span className="text-sm text-red-700 ">
                    Please enter length
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <input
                  value={width}
                  onChange={(e) => setWidth(e.target.value)}
                  type="number"
                  placeholder="Enter Width"
                  className="focus:outline-none h-7 text-center border  border-gray-300"
                />
                {cargoValidate && isDimensionMandatory && !width && (
                  <span className="text-sm text-red-700 ">
                    Please enter width
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-2">
                <input
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                  type="number"
                  placeholder="Enter Height"
                  className="focus:outline-none h-7 text-center border border-t  border-b border-gray-300"
                  style={{ borderRight: 'none', borderLeft: 'none' }}
                />
                {cargoValidate && isDimensionMandatory && !height && (
                  <span className="text-sm text-red-700 ">
                    Please enter height
                  </span>
                )}
              </div>
              <span
                className="text-sm font-medium h-7 bg-white pr-2 pt-1 border border-t border-b border-r border-gray-300"
                style={{ borderLeft: 'none' }}
              >
                ft
              </span>
            </div>
          </div>
          <div className="flex flex-col items-start gap-1">
            <span className="text-gray-500 font-medium">
              <span className="text-red-600">*</span>Weight
            </span>
            <div className="flex flex-row items-center border border-gray-300">
              <input
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
                type="number"
                placeholder="Enter Weight"
                className="focus:outline-none h-7 p-2"
              />
              <span className="text-sm font-medium bg-white pr-2 pt-1">
                lbs
              </span>
            </div>
            {cargoValidate && !weight && (
              <span className="text-sm text-red-700 ">Please enter weight</span>
            )}
          </div>
        </div>
        <div className="flex flex-col w-full gap-2 items-start">
          <span className="text-gray-500 font-medium">
            <span className="text-red-600">*</span>Description
          </span>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            cols="30"
            rows="2"
            className="w-full px-1 focus:outline-none"
            placeholder="Enter Description"
          />
          {cargoValidate && !description && (
            <span className="text-sm text-red-700 ">
              Please enter description
            </span>
          )}
          {length && width && height && weight && (
            <div className="flex flex-row w-full gap-16">
              <div className="flex flex-row gap-1">
                <span className="font-bold">Density:</span>
                <span>
                  {`${computedDensity?.density} lb/ft`}
                  <sup>3</sup>
                </span>
              </div>
              <div className="flex flex-row gap-1">
                <span className="font-bold">Cubic FT:</span>
                <span>
                  {`${computedDensity?.cubitFeet} ft`}
                  <sup>3</sup>
                </span>
              </div>
            </div>
          )}
          {serviceType === LTL_FREIGHT && (
            <div className="flex flex-row w-full gap-28">
              <div className="flex flex-col items-start">
                <span className="text-gray-500 font-medium">
                  <span className="text-red-600">*</span>Freight Class
                </span>
                <Select
                  value={freightClass}
                  className="text-left"
                  placeholder="Select Freight Class"
                  style={{
                    width: 227
                  }}
                  options={FREIGHT_CLASS}
                  onSelect={handleFreightSelect}
                />
                {cargoValidate &&
                  serviceType === LTL_FREIGHT &&
                  (!freightClass || freightClass?.value === '0') && (
                    <span className="text-sm text-red-700 ">
                      Please select freight class
                    </span>
                  )}
              </div>
              <div className="flex flex-col items-start">
                <span className="text-gray-500 font-medium">NMFC Code</span>
                <input
                  value={nmfcCode}
                  onChange={(e) => setNmfcCode(e.target.value)}
                  type="number"
                  placeholder="Enter nmfc code"
                  className="focus:outline-none h-8 p-2 border border-gray-300"
                />
              </div>
            </div>
          )}
          {cargos.length > 0 && (
            <div className="flex flex-col gap-4 w-full my-6">
              {cargos.map((item) => (
                <QuickQuoteCargoTile
                  key={item.id}
                  cargo={item}
                  handleEditCargo={handleEditCargo}
                />
              ))}
            </div>
          )}
          {isEditMode ? (
            <div className="flex flex-row w-full gap-4">
              <button
                type="button"
                className="bg-navy-500 w-full font-bold h-12 text-white"
                onClick={handleSave}
              >
                Save
              </button>
              <button
                type="button"
                className="bg-red-500 w-full font-bold h-12 text-white"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              type="button"
              className="bg-navy-50 h-12 text-navy-500 font-bold w-full border border-dashed border-1 border-navy-400"
              onClick={() => handleAddCargo(false)}
            >
              + Add New Cargo
            </button>
          )}
        </div>
        {validate && allCargoFieldsEmpty() && !cargos.length && (
          <span className="text-sm text-red-700 ">
            Please add atleast one cargo
          </span>
        )}
      </div>
    </div>
  );
};

export default CargoCard;

import { useAuthContext } from '../../contexts/auth.context';

// NOTE: defining constant functions as custom hooks because React Hooks(in this case useAuthContext) must be called in a React function component or a custom React Hook function

const useGetCarrierSidebarRoutes = () => {
  const { authUser } = useAuthContext();

  const routes = !authUser.user.isDOTVerified
    ? []
    : [
        {
          route: '/available-loads',
          label: 'Available Loads'
        },
        {
          route: '/accepted-loads',
          label: 'Accepted Loads'
        },
        {
          route: '/pending-loads',
          label: 'Pending Loads'
        },
        {
          route: '/rejected-loads',
          label: 'Rejected Loads'
        },
        {
          route: '/assigned-loads',
          label: 'Assigned Loads'
        },
        {
          route: '/enroute-loads',
          label: 'Enroute Loads'
        },
        {
          route: '/completed-loads',
          label: 'Delivered Loads'
        },
        {
          route: '/carrier-settings',
          label: 'Settings'
        }
      ];

  return routes;
};

export default useGetCarrierSidebarRoutes;

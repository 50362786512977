const shipperSidebarRoutes = [
  {
    route: '/new-quote',
    label: 'New Quote'
  },
  {
    route: '/recent-quotes',
    label: 'Recent Quotes'
  },

  {
    route: '/shipper-pending',
    label: 'Pending'
  },
  {
    route: '/active-shipment',
    label: 'Assigned'
  },
  {
    route: '/shipper-enroute',
    label: 'Enroute'
  },
  {
    route: '/shipper-completed',
    label: 'Delivered'
  },
  {
    route: '/shipper-settings',
    label: 'Settings'
  },
  {
    route: '/shipper-profile',
    label: 'Your Profile'
  }
];

export default shipperSidebarRoutes;

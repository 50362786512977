import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';

import { useAuthContext } from '../../contexts/auth.context';

import { updateDOTNumberService } from '../../services/carrier.service';
import { userLogout } from '../../services/auth.service';

const DOTNumberPage = () => {
  const { authUser, setAuthUser } = useAuthContext();
  const navigate = useNavigate();

  const schema = yup.object().shape({
    dotNumber: yup
      .string()
      .matches(/^[0-9]+$/, 'DOT number must be only digits')
      .required('DOT number is required')
      .min(7, 'DOT number must be at least 7 digits')
      .max(8, 'DOT number can not exceed 8 digits')
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmitForm = async (data) => {
    try {
      const response = await updateDOTNumberService(data);

      if (response.status === 200 && response.data.flag) {
        toast.success(response.data.message);
        const responseUser = await userLogout({ token: authUser.token });

        if (responseUser.data.flag) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          setAuthUser({});
          toast.success(responseUser.data.message);

          navigate('/login', { replace: true });
        }
      }
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  return (
    <div className="">
      <section className="py-10 lg:py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-md mx-auto">
            <div className="mb-6 lg:mb-10 text-left">
              <div className="mb-6  p-4">
                <h3 className="text-2xl font-bold text-navy-500">DOT Number</h3>
              </div>
              <form action="" onSubmit={handleSubmit(onSubmitForm)}>
                <div className="mb-3  p-4">
                  <label
                    className="w-full block text-black text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    Enter your DOT Number
                  </label>
                  <input
                    {...register('dotNumber')}
                    className="leading-tight shadow appearance-none w-full text-xs bg-white border-gray-200 border-solid border py-3 px-2"
                    type="text"
                    id="dotNumber"
                  />
                  {errors.dotNumber && (
                    <span className="text-md text-red-700">
                      {errors.dotNumber.message}
                    </span>
                  )}
                </div>

                <div className="text-left p-4 relative">
                  <button
                    // href="carrier-available-load.html"
                    type="submit"
                    disabled={isSubmitting}
                    className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-4
                     bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700 text-sm font-bold
                    transition duration-200 text-left"
                  >
                    Submit
                    <svg
                      className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-6 font-bold"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DOTNumberPage;

import Roles from '../constants/roles.constants';

const getHomePageOfUser = (authUser) => {
  if (authUser.user) {
    switch (authUser.user.usertype) {
      case Roles.SHIPPER:
        return '/shipper-dashboard';

      case Roles.CARRIER:
        if (!authUser.user.isDOTVerified) {
          return '/carrier-dot-number';
        }

        return '/carrier-dashboard';

      case Roles.SALES_REP:
      case Roles.CUSTOMER_REP:
        return '/dashboard';

      default:
        break;
    }
  } else {
    return '/login';
  }
};

export default getHomePageOfUser;

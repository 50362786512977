import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';

import {
  AreasOfOperation,
  EquipmentTypes
} from '../../../constants/carrier.constants';

import { ReactComponent as LeftArrowIcon } from '../../../assets/images/left_arrow.svg';

const ContactDetailsForm = ({ prevStep }) => {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <>
      <div className="mb-6  p-4 relative">
        <div
          onClick={prevStep}
          onKeyPress={() => {}}
          role="button"
          tabIndex="-1"
        >
          <LeftArrowIcon className="w-6 h-6 absolute inset-y-0 left-0 flex items-center mt-6 text-navy-500 font-bold" />
        </div>
        <h3 className="text-3xl text-navy-500 font-bold ml-6">
          Contact Detail
        </h3>
      </div>
      <div className="mb-3  p-4">
        <label
          className="w-full block text-black text-sm font-bold mb-2"
          htmlFor=""
        >
          Mobile Number
        </label>
        <input
          {...register('contactnumber')}
          className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
          type="text"
        />
        {errors.contactnumber && (
          <span className="text-xs text-red-700">
            {errors.contactnumber.message}
          </span>
        )}
      </div>

      <div className="mb-3  p-4">
        <label
          className="w-full block text-black text-sm font-bold mb-2"
          htmlFor=""
        >
          Fax Number
        </label>
        <input
          {...register('fax')}
          className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
          type="text"
        />
        {errors.fax && (
          <span className="text-xs text-red-700">{errors.fax.message}</span>
        )}
      </div>

      <div className="mb-3  p-4">
        <label
          className="w-full block text-black text-sm font-bold mb-2"
          htmlFor=""
        >
          DOT#
        </label>
        <input
          {...register('dot')}
          className="appearance-none w-full p-4 text-xs font-semibold leading-none bg-white  outline-none border border-gray-200"
          type="text"
        />
        {errors.dot && (
          <span className="text-xs text-red-700">{errors.dot.message}</span>
        )}
      </div>

      <div className="relative p-4">
        <label
          className="w-full block text-black text-sm font-bold mb-2"
          htmlFor=""
        >
          Area of operation
        </label>
        <Controller
          name="radius"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              options={AreasOfOperation}
              className="appearance-none w-full text-xs font-semibold leading-none bg-white  outline-none border-gray-200"
            />
          )}
        />
        {errors.radius && (
          <span className="text-xs text-red-700">{errors.radius.message}</span>
        )}
      </div>

      <div className="relative p-4">
        <label
          className="w-full block text-black text-sm font-bold mb-2"
          htmlFor=""
        >
          Equipment Types
        </label>
        <Controller
          name="equipmentType"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              isMulti
              options={EquipmentTypes}
              className="appearance-none w-full text-xs font-semibold leading-none bg-white  outline-none border-gray-200"
            />
          )}
        />
        {errors.equipmentType && (
          <span className="text-xs text-red-700">
            {errors.equipmentType.message}
          </span>
        )}
      </div>
    </>
  );
};

export default ContactDetailsForm;

ContactDetailsForm.propTypes = {
  prevStep: PropTypes.func.isRequired,
  formContent: PropTypes.any.isRequired
};

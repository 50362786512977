import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import QuoteDetailsCard from '../../components/QuoteDetailsCard/QuoteDetailsCard';
import { getCarrierBidsService } from '../../services/bid.service';

const AcceptedLoadsPage = () => {
  const [quotes, setQuotes] = useState([]);
  const [isAPICalled, setisAPICalled] = useState(false);

  async function fetchAvailableLoads() {
    try {
      const response = await getCarrierBidsService();
      if (response.data.flag) {
        setQuotes(response.data.bids);
        setisAPICalled(true);
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    fetchAvailableLoads();
  }, []);

  return (
    <section>
      <div className="skew skew-top mr-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 10 0 10" />
        </svg>
      </div>
      <div className="skew skew-top ml-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 10 10 0 10 10" />
        </svg>
      </div>
      <div className="py-20 bg-gray-50 radius-for-skewed">
        <div className="container mx-auto px-4">
          <div className="mb-6  p-4 relative pl-0">
            <h3 className="text-3xl font-bold text-navy-500">Accepted Loads</h3>
          </div>
          <div className="flex flex-wrap -mx-3">
            {isAPICalled &&
              (quotes.length
                ? quotes.map((quote) => (
                    <QuoteDetailsCard
                      key={quote.id}
                      quote={quote}
                      loadType="carrier-accepted-loads"
                    />
                  ))
                : 'No bids to display')}
          </div>
        </div>
      </div>
      <div className="skew skew-bottom mr-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 0 0 10" />
        </svg>
      </div>
      <div className="skew skew-bottom ml-for-radius">
        <svg
          className="h-8 md:h-12 lg:h-20 w-full text-gray-50"
          viewBox="0 0 10 10"
          preserveAspectRatio="none"
        >
          <polygon fill="currentColor" points="0 0 10 0 10 10" />
        </svg>
      </div>
    </section>
  );
};

export default AcceptedLoadsPage;

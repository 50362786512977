import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { Collapse, theme } from 'antd';
import toast from 'react-hot-toast';
import { ReactComponent as LeftArrowIcon } from '../../assets/images/left_arrow.svg';
import { ReactComponent as RightArrow } from '../../assets/images/right_arrow.svg';
import {
  PAYMENT_MODES,
  useIsInvoiceAuthorizedUser
} from '../../constants/invoice.constants';
import PaymentForm from '../../components/PaymentForm';
import { completePayment } from '../../services/invoice.service';

const PaymentPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthorizedUser = useIsInvoiceAuthorizedUser();
  const { token } = theme.useToken();
  const { pathname, state } = location;
  const isSinglePaymentMode = pathname.startsWith(
    '/salesRep/open-invoices/payment'
  );
  const { totalAmountString = null, selectedQuotes = [] } = state ?? {};
  const { CHECK, CARD, WIRETRANSFER } = PAYMENT_MODES;
  const currentDateObject = {
    date: dayjs(),
    dateString: dayjs().format('MM/DD/YYYY')
  };

  const [selectedPaymentMode, setSelectedPaymentMode] = useState(CHECK);
  const [amount, setAmount] = useState('');
  const [chequeNumber, setChequeNumber] = useState(null);
  const [transactionDate, setTransactionDate] = useState(currentDateObject);
  const [comment, setComment] = useState('');
  const [validate, setValidate] = useState(false);

  const panelStyles = {
    marginBottom: 20,
    border: '1px solid #D0D0D0'
  };

  const getItems = (panelStyle) => [
    {
      key: CHECK,
      label: (
        <>
          <div className="flex flex-row justify-between items-center w-full">
            <span className="mr-2 font-semibold text-base">Check</span>
            <input
              type="radio"
              value="1"
              name="payment"
              className="collapsible-radio"
              checked={selectedPaymentMode === CHECK}
            />
          </div>
          {selectedPaymentMode === CHECK && <hr className="mt-3" />}
        </>
      ),
      children: (
        <PaymentForm
          paymentMode={CHECK}
          amount={amount}
          setAmount={setAmount}
          chequeNumber={chequeNumber}
          setChequeNumber={setChequeNumber}
          transactionDate={transactionDate}
          setTransactionDate={setTransactionDate}
          comment={comment}
          setComment={setComment}
          validate={validate}
          isSinglePaymentMode={isSinglePaymentMode}
        />
      ),
      style: panelStyle,
      showArrow: false
    },
    {
      key: CARD,
      label: (
        <>
          <div className="flex flex-row justify-between items-center w-full">
            <span className="mr-2 font-semibold text-base">Credit Card</span>
            <input
              type="radio"
              value="2"
              name="payment"
              className="collapsible-radio"
              checked={selectedPaymentMode === CARD}
            />
          </div>
          {selectedPaymentMode === CARD && <hr className="mt-3" />}
        </>
      ),
      children: (
        <PaymentForm
          amount={amount}
          setAmount={setAmount}
          chequeNumber={chequeNumber}
          setChequeNumber={setChequeNumber}
          transactionDate={transactionDate}
          setTransactionDate={setTransactionDate}
          comment={comment}
          setComment={setComment}
          validate={validate}
          isSinglePaymentMode={isSinglePaymentMode}
        />
      ),
      style: panelStyle,
      showArrow: false
    },
    {
      key: WIRETRANSFER,
      label: (
        <>
          <div className="flex flex-row justify-between items-center w-full">
            <span className="mr-2 font-semibold text-base">Wiretransfer</span>
            <input
              type="radio"
              value="3"
              name="payment"
              className="collapsible-radio"
              checked={selectedPaymentMode === WIRETRANSFER}
            />
          </div>
          {selectedPaymentMode === WIRETRANSFER && <hr className="mt-3" />}
        </>
      ),
      children: (
        <PaymentForm
          amount={amount}
          setAmount={setAmount}
          chequeNumber={chequeNumber}
          setChequeNumber={setChequeNumber}
          transactionDate={transactionDate}
          setTransactionDate={setTransactionDate}
          comment={comment}
          setComment={setComment}
          validate={validate}
          isSinglePaymentMode={isSinglePaymentMode}
        />
      ),
      style: panelStyle,
      showArrow: false
    }
  ];
  const onChange = (key) => {
    setAmount(totalAmountString);
    setChequeNumber('');
    setTransactionDate(currentDateObject);
    setComment('');
    setSelectedPaymentMode(key[0]);
    setValidate(false);
  };

  const handleSave = async () => {
    try {
      setValidate(true);
      if (
        !amount ||
        (selectedPaymentMode === CHECK && !chequeNumber) ||
        !transactionDate.dateString
      ) {
        return toast.error('Please fill all the details correctly!');
      }
      const response = await completePayment({
        quotes: isSinglePaymentMode
          ? [{ quoteId: selectedQuotes[0].quoteId, amount }]
          : selectedQuotes,
        paymentMethod: selectedPaymentMode,
        checkNumber: chequeNumber,
        comment,
        paymentDate: transactionDate.dateString,
        totalAmount: amount
      });
      console.log({ response });
      if (response && response?.data && response?.data?.flag) {
        const { successfullTransactions, erroredTransaction } = response.data;
        if (!erroredTransaction.length) {
          if (successfullTransactions.length === 1) {
            toast.success('Invoice Marked as Paid Successfully!');
          } else {
            toast.success('All Invoices Marked as Paid Successfully!');
          }
        } else {
          if (successfullTransactions.length) {
            toast.success('Invoices Marked as Paid Successfully!');
          }
          erroredTransaction.forEach((element) => {
            toast.error(element?.message ?? 'Something Went Wrong!');
          });
        }
        navigate(-1);
      } else {
        toast.error(response.data.message ?? 'Something Went Wrong!');
      }
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data?.message ?? 'Something Went Wrong!');
    }
  };

  useEffect(() => {
    if (!isAuthorizedUser || !state) {
      return navigate('/page-not-found');
    }

    setAmount(totalAmountString);
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="py-10">
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-3 mb-8 lg:w-1/2">
            <div className="relative mb-2">
              <div className="flex flex-col items-start gap-5">
                <div
                  tabIndex="-1"
                  role="button"
                  onKeyPress={() => {}}
                  onClick={() => navigate(-1)}
                  className="inline-flex items-center gap-3"
                >
                  <LeftArrowIcon className="w-6 h-6 " />
                  <p>Back to the list</p>
                </div>
                <div className="flex flex-col items-start w-full">
                  <h4 className="text-3xl font-bold text-navy-500 ">
                    Select Your Payment Method
                  </h4>
                  <span>
                    Choose your payment method and enter the mandatory details.
                  </span>
                </div>
                <div className="w-full">
                  <Collapse
                    items={getItems(panelStyles)}
                    accordion
                    onChange={onChange}
                    defaultActiveKey={[CHECK]}
                    className="mt-3"
                    bordered={false}
                    style={{
                      background: token.colorBgContainer
                    }}
                  />
                </div>
                <button
                  type="button"
                  className="flex flex-row justify-between items-center bg-navy-500 w-full text-lg font-semibold text-white px-5 py-3 cursor-pointer"
                  onClick={handleSave}
                >
                  Save
                  <RightArrow className="w-6 h-6 font-bold" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentPage;

import React from 'react';
import { Outlet } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';

const LayoutAnonymous = () => {
  return (
    <div className="antialiased bg-body text-body font-body overflow-x-hidden">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default LayoutAnonymous;

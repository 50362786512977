import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const PlaceBid = ({ closeModal, sendBidAmount }) => {
  const [bidAmount, setbidAmount] = useState();
  const [bidError, showBidError] = useState(false);

  useEffect(() => {
    if (bidAmount) {
      showBidError(false);
    }
  }, [bidAmount]);

  return (
    <div
      className="fixed z-10 overflow-y-auto top-0 w-full h-full left-0"
      id="modal"
    >
      <div className="flex flex-wrap -mx-3 items-center justify-center text-center">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <div className="inline-block align-center bg-white rounded-lg text-center p-5 overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="max-w-xl mx-auto lg:mx-0 text-navy-500  mt-5 text-xl">
            <p className="mb-5 font-bold">Please enter bid amount</p>
            <input
              placeholder="Amount"
              className="leading-tight shadow appearance-none w-full text-xs bg-white border-gray-200 border-solid border bg-white py-3 px-2"
              type="text"
              value={bidAmount}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  setbidAmount(e.target.value);
                }
              }}
              onKeyDown={(e) =>
                (e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 189) &&
                e.preventDefault()
              }
              name="email"
            />
            {bidError && (
              <span className="text-md text-red-700 mb-2">
                Please enter a valid bid amount.
              </span>
            )}
          </div>
          <div className="my-10 flex">
            <button
              type="button"
              className="order-1 xl:order-0 mr-2 inline-block p-4 mb-2 w-1/2 py-4 
              bg-white text-navy-500 hover:bg-navy-50 hover:text-navy-500 active:text-navy-600 
                border border-navy-500 hover:border-navy-600
                text-md font-bold 
                 transition duration-200 text-center"
              onClick={closeModal}
              // onKeyPress={() => {}}
            >
              Cancel
            </button>
            <button
              type="button"
              className="order-1 xl:order-0 ml-2 inline-block p-4 mb-2 w-1/2 py-4 
                bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                hover:border-navy-600 active:border-navy-700 text-md font-bold
                 transition duration-200 text-center"
              onClick={() => {
                if (!bidAmount) {
                  showBidError(true);
                  return;
                }
                sendBidAmount(bidAmount);
              }}
              // onKeyPress={() => {}}
            >
              Place Bid
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaceBid;

PlaceBid.propTypes = {
  closeModal: PropTypes.func.isRequired,
  sendBidAmount: PropTypes.func.isRequired
};

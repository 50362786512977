import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { MdModeEditOutline, MdDelete } from 'react-icons/md';

const PickupDeliveryProfile = memo(
  ({ accord, deleteLocation, userAddress, customerId }) => {
    const navigate = useNavigate();

    return (
      <div className="bg-white py-4">
        <div className="flex justify-between mt-4">
          <h3 className="font-bold">Saved {accord.type} Addresses</h3>
          <button
            type="button"
            className="bg-navy-500 text-white px-4 py-2 text-sm font-semibold"
            onClick={() =>
              navigate(
                customerId
                  ? `/add-address/${accord.type.toLowerCase()}/${customerId}`
                  : `/add-address/${accord.type.toLowerCase()}`
              )
            }
          >
            Add New {accord.type} Address
          </button>
        </div>
        <div className="mt-4">
          <p className="font-semibold">Default Address</p>
          <div className="flex flex-col mb-4 mt-4">
            {userAddress
              ?.filter(
                (addr) =>
                  addr.isDefault && addr.type === accord.type.toLowerCase()
              )
              ?.map((addr) => {
                return (
                  <div className="flex justify-between bg-gray-100 my-2 p-3">
                    <span>{addr.name}</span>
                    <div className="flex gap-4">
                      <button
                        type="button"
                        onClick={() =>
                          navigate(
                            customerId
                              ? `/add-address/${accord.type.toLowerCase()}/${customerId}?location=${
                                  addr.id
                                }`
                              : `/add-address/${accord.type.toLowerCase()}?location=${
                                  addr.id
                                }`
                          )
                        }
                      >
                        <MdModeEditOutline
                          color="#1d4690"
                          className="text-2xl bg-navy-75 p-1 h-7 w-7 shadow-md rounded-sm "
                        />
                      </button>
                      <button
                        type="button"
                        onClick={() => deleteLocation(addr.id)}
                      >
                        <MdDelete
                          color="#d22027"
                          className="text-2xl bg-red-200 p-1 h-7 w-7 shadow-md rounded-sm "
                        />
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <hr className="my-6" />
        <div className="mt-4">
          <p className="font-semibold">Other Address</p>
          <div className="flex flex-col mb-4 mt-4">
            {userAddress
              ?.filter(
                (addr) =>
                  !addr.isDefault && addr.type === accord.type.toLowerCase()
              )
              ?.map((addr) => {
                return (
                  <div className="flex justify-between bg-gray-100 my-2 p-3">
                    <span>{addr.name}</span>
                    <div className="flex gap-4">
                      <button
                        type="button"
                        onClick={() =>
                          navigate(
                            customerId
                              ? `/add-address/${accord.type.toLowerCase()}/${customerId}?location=${
                                  addr.id
                                }`
                              : `/add-address/${accord.type.toLowerCase()}?location=${
                                  addr.id
                                }`
                          )
                        }
                      >
                        <MdModeEditOutline
                          color="#1d4690"
                          className="text-2xl bg-navy-75 p-1 h-7 w-7 shadow-md rounded-sm "
                        />
                      </button>
                      <button
                        type="button"
                        onClick={() => deleteLocation(addr.id)}
                      >
                        <MdDelete
                          color="#d22027"
                          className="text-2xl bg-red-200 p-1 h-7 w-7 shadow-md rounded-sm "
                        />
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
);

PickupDeliveryProfile.propTypes = {
  accord: PropTypes.object.isRequired,
  deleteLocation: PropTypes.func.isRequired,
  userAddress: PropTypes.array.isRequired,
  customerId: PropTypes.string.isRequired
};

export default PickupDeliveryProfile;

import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import toast from 'react-hot-toast';

const ForgetPassword = memo(({ closeModal, sendEmail }) => {
  const [emailInput, setemailInput] = useState('');

  const emailSchema = yup.string().email();

  return (
    <div
      className="fixed z-10 overflow-y-auto top-0 w-full h-full left-0"
      id="modal"
    >
      <div className="flex flex-wrap -mx-3 items-center justify-center text-center">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <div className="inline-block align-center bg-white rounded-lg text-center p-5 overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="max-w-xl mx-auto lg:mx-0 text-black  mt-5 text-xl">
            <p className="mb-5 font-bold">
              Please enter your email to reset password
            </p>
            <input
              placeholder="Email"
              className="leading-tight shadow appearance-none w-full text-xs bg-white border-gray-200 border-solid border  py-3 px-2"
              type="email"
              value={emailInput}
              onChange={(e) => setemailInput(e.target.value)}
              name="email"
            />
          </div>
          <div className="my-10 flex">
            <button
              type="button"
              className="order-1 xl:order-0 mr-2 inline-block p-4 mb-2 w-1/2 py-4 
              bg-white text-navy-500 hover:bg-navy-50 hover:text-navy-500 active:text-navy-600 
                border border-navy-500 hover:border-navy-600
                text-md font-bold 
                 transition duration-200 text-center"
              onClick={closeModal}
              // onKeyPress={() => {}}
            >
              Cancel
            </button>
            <button
              type="button"
              className="order-1 xl:order-0 ml-2 inline-block p-4 mb-2 w-1/2 py-4 
                bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                hover:border-navy-600 active:border-navy-700 text-md font-bold
                 transition duration-200 text-center"
              onClick={() => {
                if (
                  !emailInput.length ||
                  !emailSchema.isValidSync(emailInput)
                ) {
                  toast.error('Please enter a valid email address!');
                  return;
                }

                sendEmail(emailInput);
              }}
              // onKeyPress={() => {}}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ForgetPassword;

ForgetPassword.propTypes = {
  closeModal: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired
};

import React, { useContext, useEffect } from 'react';
import Roles from '../../../constants/roles.constants';
import ViewMode from '../../../components/QuickQuote/ViewMode';
import { SERVICE_TYPES } from '../../../constants/quote.constants';
import { ReactComponent as RightArrow } from '../../../assets/images/right_arrow.svg';
import ServiceTypeCard from '../../../components/QuickQuote/ServiceTypeCard';
import PickupAddressCard from '../../../components/QuickQuote/PickupAddressCard/PickupAddressCard';
import CargoCard from '../../../components/QuickQuote/CargoCard';
import DeliveryAddressCard from '../../../components/QuickQuote/DeliveryAddressCard';
import { GlobalFormContext } from '../../../contexts/QuickQuoteContext';
import { useAuthContext } from '../../../contexts/auth.context';
import SalesRepCustomerSection from '../../../components/QuickQuote/SalesRepCustomerSection';
import ShipperCustomerSection from '../../../components/QuickQuote/ShipperCustomerSection';

const QuickQuotePage = () => {
  const { authUser } = useAuthContext();
  const { validate, serviceType, handleSubmit, customerRef } =
    useContext(GlobalFormContext);

  useEffect(() => {
    if (customerRef && customerRef.current) {
      customerRef.current.focus();
    }
  }, [customerRef]);

  return (
    <div className="container px-4 mx-auto">
      <div className="flex flex-wrap h-full -mx-4 text-center">
        <div className=" flex flex-col gap-5 w-full px-4 mb-8">
          <div className="flex flex-col items-start gap-6">
            <div className="flex flex-row justify-between w-full">
              <span className="text-3xl font-bold text-navy-500">
                Quick Quote
              </span>
              <ViewMode />
            </div>
            {authUser.user.usertype === Roles.SHIPPER ? (
              <ShipperCustomerSection />
            ) : (
              <SalesRepCustomerSection />
            )}
            <div className="flex flex-col gap-2 w-full items-start">
              <span className="font-medium">Service Type</span>
              <div className="grid grid-cols-2 gap-4 w-full">
                {SERVICE_TYPES.map((servicetypeItem) => (
                  <ServiceTypeCard
                    key={servicetypeItem.value}
                    servicetypeItem={servicetypeItem}
                  />
                ))}
              </div>
              {validate && !serviceType && (
                <span className="text-sm text-red-700 ml-3">
                  Please select a Service Type
                </span>
              )}
            </div>
            <div className="flex flex-row gap-2 w-full">
              <PickupAddressCard />
              <DeliveryAddressCard />
            </div>
            <CargoCard />
          </div>
          <button
            type="button"
            className="w-full bg-navy-500 text-white flex flex-row justify-between px-4 py-3 font-medium"
            onClick={handleSubmit}
          >
            Get Quote
            <RightArrow className="h-6 w-6 font-bold" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuickQuotePage;

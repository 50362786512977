import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import GoogleMapComponent from '../../components/GoogleMapComponent/GoogleMapComponent';

import {
  getQuoteDetailsService,
  trackShipmentService
} from '../../services/quote.service';

import { ReactComponent as LeftArrowIcon } from '../../assets/images/left_arrow.svg';
import { getAddressFromLatLong } from '../../services/geo.service';

const TrackShipmentPage = () => {
  const navigate = useNavigate();
  const { quoteId } = useParams();

  const [isAPICalled, setisAPICalled] = useState(false);
  const [quote, setQuote] = useState(null);
  const [carrier, setCarrier] = useState(null);
  const [carrierLocation, setcarrierLocation] = useState({});
  const [locationDetails, setlocationDetails] = useState(null);

  async function fetchQuoteDetails() {
    try {
      const [quoteResponse, trackResponse] = await Promise.all([
        getQuoteDetailsService(quoteId),
        trackShipmentService(quoteId)
      ]);

      setQuote(quoteResponse.data.quote);
      setcarrierLocation(trackResponse.data.lastLocation);
      setCarrier(trackResponse.data.carrier);

      if (
        trackResponse.data.lastLocation.latitude !== null &&
        trackResponse.data.lastLocation.longitude !== null
      ) {
        const locationResponse = await getAddressFromLatLong(
          trackResponse.data.lastLocation.latitude,
          trackResponse.data.lastLocation.longitude
        );

        setlocationDetails(locationResponse);
      }

      setisAPICalled(true);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchQuoteDetails();
  }, [quoteId]);

  const onBackArrowClick = () => {
    navigate(-1);
  };

  return (
    <section className="py-20">
      <div className="container px-4 mx-auto">
        {isAPICalled ? (
          <div className="w-full min-h-full px-3 mb-8">
            <div className="relative mb-6 text-navy-500">
              <div
                tabIndex="-1"
                role="button"
                onKeyPress={() => {}}
                onClick={onBackArrowClick}
                className="flex items-center"
              >
                <LeftArrowIcon className="left-0 flex items-center w-6 h-6 " />
                <p className="ml-4">Back to Summary</p>
              </div>
              <h3 className="mb-4 text-3xl font-bold text-left">
                Track Shipment
              </h3>
            </div>
            <div className="flex flex-col items-start w-full md:flex-row">
              <div className="order-1 inline-block w-full pr-4 mb-6 text-black md:w-1/3 md:order-0">
                <p>
                  Carrier Name:{' '}
                  <span className="font-bold">{carrier.name}</span>
                </p>

                <p>
                  Last Updated on:{' '}
                  <span className="font-bold">
                    {new Date(carrier.locationUpdatedAt).toString()}
                  </span>
                </p>

                <p>
                  Last Location:{' '}
                  <span className="font-bold">
                    {locationDetails?.address ?? 'N/A'}
                  </span>
                </p>

                <p>
                  Zipcode:{' '}
                  <span className="font-bold">
                    {locationDetails?.postcode ?? 'N/A'}
                  </span>
                </p>
              </div>
              <div className="inline-block w-full mb-6 md:w-2/3 h-96 md:h-48 order-0 md:order-1">
                <GoogleMapComponent
                  pickupLatitude={quote.pickupLatitude}
                  pickupLongitude={quote.pickupLongitude}
                  deliveryLatitude={quote.deliveryLatitude}
                  deliveryLongitude={quote.deliveryLongitude}
                  isTrackingEnabled
                  carrierLocation={carrierLocation}
                />
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </section>
  );
};

export default TrackShipmentPage;

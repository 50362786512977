import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';
import SignaturePad from 'signature_pad';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import moment from 'moment';
import { carrierSignatureService } from '../../services/bid.service';
import { ReactComponent as RightArrowIcon } from '../../assets/images/right_arrow.svg';
import { getQuoteDetailsService } from '../../services/quote.service';
import { getCarrierPrice } from '../../utils/quote.util';

const CarrierAddSignaturePage = () => {
  const { quoteId, bidId } = useParams();
  const navigate = useNavigate();

  const [signatureData, setSignatureData] = useState();
  const [quoteData, setQuoteData] = useState();

  const validationSchema = yup.object({
    driverName: yup.string().required('Please enter driver name'),
    driverPhone: yup
      .string()
      .matches(/^[0-9]+$/, 'Mobile number must be only digits')
      .required('Mobile number is required')
      .max(11)
      .min(10, 'Mobile number must be at least 10 digits')
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  let signaturePad = null;

  function resizeCanvas() {
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    const canvasT = document.getElementById('signature-pad');
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvasT.width = canvasT.offsetWidth * ratio;
    canvasT.height = canvasT.offsetHeight * ratio;
    canvasT.getContext('2d').scale(ratio, ratio);
  }
  function setCanvas() {
    resizeCanvas();
    const canvasObj = document.getElementById('signature-pad');

    signaturePad = new SignaturePad(canvasObj, {
      backgroundColor: 'rgb(255, 255, 255)',
      penColor: 'rgb(0, 0, 0)'
    });
    setSignatureData(signaturePad);
    document.addEventListener('resize', resizeCanvas);
  }
  useEffect(() => {
    setCanvas();
  }, []);

  async function fetchQuoteDetails() {
    try {
      const response = await getQuoteDetailsService(quoteId);
      setQuoteData(response.data?.quote);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    if (quoteId) {
      fetchQuoteDetails();
    }
  }, [quoteId]);

  const onConfirm = async (data) => {
    try {
      if (!signaturePad?.isEmpty() && !signatureData?.isEmpty()) {
        const dataURL = signaturePad?.toDataURL() || signatureData.toDataURL();

        const fileResponse = await (await fetch(dataURL)).blob();
        const fileObj = new File([fileResponse], 'signature.png');
        const formData = new FormData();
        formData.append('id', quoteId);
        formData.append('bidId', bidId);
        formData.append('assignDateTime', moment().format('MM/DD/YYYY HH:mm'));
        formData.append('driverName', data.driverName);
        formData.append('driverPhone', data.driverPhone);
        formData.append('signature', fileObj);

        const response = await carrierSignatureService(formData);

        if (response.data.flag) {
          toast.success(response.data.message);
          navigate(`/accepted-loads`, { replace: true });
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
      setCanvas();
    }
  };

  const onClear = () => {
    signaturePad?.clear();
    signatureData?.clear();
  };

  const onBackArrowClick = () => {
    navigate(-1);
  };

  return (
    <section className="py-20">
      <div className="container mx-auto px-4">
        <div className="mb-6 px-3 p-4 relative">
          <h3 className="text-3xl font-bold text-navy-500">Signature</h3>
        </div>
        <div className="flex flex-wrap text-center">
          <div className="mb-8 w-full lg:w-1/2 px-3">
            <div className="relative bg-white shadow border h-full border-navy-500 ">
              <canvas
                id="signature-pad"
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          </div>
          <div className="mb-8 w-full lg:w-1/2 px-3 -mt-4">
            <div className="text-left p-4 relative">
              <button
                type="submit"
                onClick={handleSubmit(onConfirm)}
                className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-3 text-sm font-bold 
                  bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700
                   transition duration-200 text-left"
              >
                Confirm
                <RightArrowIcon className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-5 font-bold" />
              </button>
            </div>
            <div className="text-left p-4 relative">
              <button
                type="button"
                className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-3 
                  bg-white hover:bg-red-500  text-sm font-bold text-navy-500 hover:text-white 
                  border border-navy-500 hover:border-red-500 transition duration-200 text-left"
                onClick={onBackArrowClick}
              >
                Cancel
                <span className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-4 font-bold text-5xl">
                  &#215;
                </span>
              </button>
            </div>
            <div className="text-left p-4 relative">
              <button
                type="button"
                onClick={onClear}
                className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-3 
                  bg-white hover:bg-navy-100  text-sm font-bold text-navy-500 hover:text-white border border-navy-500 transition duration-200 text-left"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
        <div className="p-3 w-1/3">
          <form>
            <div className="mb-3">
              <label className="w-full block text-black text-sm font-bold mb-2">
                Driver Name
              </label>
              <input
                {...register('driverName')}
                className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                type="text"
              />
              {errors.driverName && (
                <span className="text-xs text-red-700">
                  {errors.driverName.message}
                </span>
              )}
            </div>
            <div className="mb-3">
              <label className="w-full block text-black text-sm font-bold mb-2">
                Driver Phone
              </label>
              <input
                {...register('driverPhone')}
                className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                type="text"
              />
              {errors.driverPhone && (
                <span className="text-xs text-red-700">
                  {errors.driverPhone.message}
                </span>
              )}
            </div>
          </form>
        </div>
        {quoteData && (
          <div className="p-3">
            <div className="font-bold text-xl mb-4">Shipment Details</div>
            <div className="mb-3">
              <label className="w-full block text-black font-semibold mb-2">
                Shipment From -{' '}
                {quoteData.isMultipleQuote ? (
                  quoteData?.pickupInfo?.map((pick) => (
                    <li className="font-medium">
                      {pick?.pickup_address?.city},{' '}
                      {pick?.pickup_address?.state},{' '}
                      {pick?.pickup_address?.country},{' '}
                      {pick?.pickup_address?.zip}
                    </li>
                  ))
                ) : (
                  <span className="font-normal">
                    {quoteData?.pickup_address ? (
                      <>
                        {quoteData?.pickup_address?.city},{' '}
                        {quoteData?.pickup_address?.state},{' '}
                        {quoteData?.pickup_address?.country},{' '}
                        {quoteData?.pickup_address?.zip}
                      </>
                    ) : (
                      quoteData?.pickup_from?.split('|')?.[1]?.trim()
                    )}
                  </span>
                )}
              </label>
            </div>
            <div className="mb-3">
              <label className="w-full block text-black font-semibold mb-2">
                Shipment To -{' '}
                {quoteData.isMultipleQuote ? (
                  quoteData?.deliveryInfo?.map((del) => {
                    return (
                      <li className="font-medium">
                        {del?.delivery_address?.city},{' '}
                        {del?.delivery_address?.state},{' '}
                        {del?.delivery_address?.country},{' '}
                        {del?.delivery_address?.zip}
                      </li>
                    );
                  })
                ) : (
                  <span className="font-normal">
                    {quoteData?.delivery_address ? (
                      <>
                        {quoteData?.delivery_address?.city},{' '}
                        {quoteData?.delivery_address?.state},{' '}
                        {quoteData?.delivery_address?.country},{' '}
                        {quoteData?.delivery_address?.zip}
                      </>
                    ) : (
                      quoteData?.delivery_to?.split('|')?.[1]?.trim()
                    )}
                  </span>
                )}
              </label>
            </div>
            <div className="mb-3">
              <label className="w-full block text-black font-semibold mb-2">
                Cargo Details
              </label>
              {quoteData?.cargos?.map((cg) => {
                return (
                  <li className="font-semibold">
                    {cg.quantity} - {cg?.package_type?.[0]?.display_name}{' '}
                    <span className="font-normal">
                      ({cg.length} in x {cg.width} in x {cg.height} in x{' '}
                      {cg.weight} lbs)
                    </span>
                  </li>
                );
              })}
            </div>
            <div className="mb-3">
              <label className="w-full block text-black font-semibold mb-2">
                Carrier Rate -{' '}
                <span className="font-normal">
                  {getCarrierPrice(quoteData)}
                </span>
              </label>
            </div>
          </div>
        )}
        <div className="flex flex-wrap">
          <div className="mb-8 w-full px-3">
            <div className="relative h-full border-navy-500 ">
              <p className=" text-xl font-semibold">Disclaimer!</p>
              <p>
                {`This confirmation governs the movement of the above-referenced
                  freight as of the date specified and hearby amends, is
                  incorporated by reference and becomes a part of that certain
                  Transportation Contract by and between 'Broker' and 'Contract
                  Carrier'. Carrier agrees to sign the confirmation and return
                  it to Broker via FAX and Carrier shall be conclusively
                  presumed to have agreed to the rates set forth herein. By its
                  signature below carrier further represents and warrants that
                  said mutually agreed upon rates are reasonable and
                  compensatory, that the freight would not have been tendered to
                  CARRIER at higher rates, and that no shipment handled under
                  such rates will subsequently be subject to a later claim for
                  undercharges. FACSIMILE SIGNATURES ARE LEGAL AND THEREFORE
                  BINDING. It is agreed that you and your driver are responsible
                  for all shortages, damages, and late delivery fees assessed to
                  us due to failure to deliver when due.`}
              </p>
              <p className=" font-bold pt-2">
                IF YOU SHOULD CANCEL THIS SHIPMENT AFTER ACCEPTING IT FOR ANY
                REASON YOU WILL BE SUBJECT TO $150 TRUCK CANCELLATION FEE.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CarrierAddSignaturePage;

import React, { useEffect, useState, memo } from 'react';
import { ConfigProvider, Select } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  invoiceStatusOptions,
  INVOICE_STATUS
} from '../../constants/invoice.constants';
import QuoteDetailsCard from '../../components/QuoteDetailsCard';
import {
  getAllActiveQuotesService,
  getAllCompletedQuotesService,
  getAllEnrouteQuotesService,
  getAllPendingQuotesService,
  getAllRecentQuotes
} from '../../services/quote.service';
import { LTL_FREIGHT } from '../../constants/service_type.constants';

import { VENDORS } from '../../constants/carrier.constants';
import { getOpenInvoices } from '../../services/invoice.service';
import useSendInvoice from '../../customHooks/useSendInvoice';
import useGenerateInvoice from '../../customHooks/useGenerateInvoice';

const QuotesListingPage = memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleSendInvoice = useSendInvoice();
  const handleGenerateInvoice = useGenerateInvoice();

  const [page, setPage] = useState(1);
  const [isEnd, setIsEnd] = useState(false);
  const [title, setTitle] = useState('');
  const [quotes, setQuotes] = useState([]);
  const [loadType, setLoadType] = useState('');
  const [isAPICalled, setisAPICalled] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(invoiceStatusOptions[0]);
  const [selectedQuotes, setSelectedQuotes] = useState([]);

  const { UNPAID, SENT } = INVOICE_STATUS;

  async function fetchRecentQuotes(isReset, pageNo) {
    try {
      const apiResponse = await getAllRecentQuotes(
        encodeURIComponent(pageNo),
        encodeURIComponent(10)
      );
      const response = apiResponse.data.quotes;
      if (!response.length) setIsEnd(true);

      const finalRes = isReset ? response : [...quotes, ...response];

      if (apiResponse.data.flag) {
        const carriers = VENDORS.map((vendor) => vendor.name);
        const quoteArr = [];
        finalRes.forEach((quote) => {
          if (quote.service_type !== LTL_FREIGHT) {
            if (quote.estimate) quoteArr.push(quote);
          } else {
            const rates = [];
            carriers.forEach((carrier) => {
              const rate = quote[carrier]?.estimate;
              if (rate && rate !== 0) rates.push(rate);
            });
            const quoteData = { ...quote };
            const min = Math.min(...rates);

            quoteData.minRate = min === Infinity ? 0 : min;
            if (quoteData.minRate) {
              quoteArr.push(quoteData);
            }
          }
        });
        setQuotes(quoteArr);
        setisAPICalled(true);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  async function fetchAvailableLoads(isReset, pageNo) {
    try {
      const response = await getAllActiveQuotesService(
        encodeURIComponent(pageNo),
        encodeURIComponent(10)
      );
      if (!response?.data?.quotes?.length) setIsEnd(true);

      if (response.data.flag) {
        const finalRes = isReset
          ? response.data.quotes
          : [...quotes, ...response.data.quotes];
        setQuotes(finalRes);
        setisAPICalled(true);
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  async function fetchPendingLoads(isReset, pageNo) {
    try {
      const response = await getAllPendingQuotesService(
        encodeURIComponent(pageNo),
        encodeURIComponent(10)
      );
      if (!response?.data?.quotes?.length) setIsEnd(true);

      if (response.data.flag) {
        const finalRes = isReset
          ? response.data.quotes
          : [...quotes, ...response.data.quotes];
        setQuotes(finalRes);
        setisAPICalled(true);
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  async function fetchEnrouteLoads(isReset, pageNo) {
    try {
      const response = await getAllEnrouteQuotesService(
        encodeURIComponent(pageNo),
        encodeURIComponent(10)
      );
      if (!response?.data?.quotes?.length) setIsEnd(true);

      if (response.data.flag) {
        const finalRes = isReset
          ? response.data.quotes
          : [...quotes, ...response.data.quotes];
        setQuotes(finalRes);
        setisAPICalled(true);
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  async function fetchCompletedLoads(isReset, pageNo) {
    try {
      const response = await getAllCompletedQuotesService(
        encodeURIComponent(pageNo),
        encodeURIComponent(10)
      );
      if (!response?.data?.quotes?.length) setIsEnd(true);

      if (response.data.flag) {
        const finalRes = isReset
          ? response.data.quotes
          : [...quotes, ...response.data.quotes];
        setQuotes(finalRes);
        setisAPICalled(true);
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  const fetchOpenInvoices = async (isReset, pageNo) => {
    try {
      const response = await getOpenInvoices(
        encodeURIComponent(pageNo),
        encodeURIComponent(10),
        currentStatus?.value
      );

      if (response.data.flag) {
        const finalRes = isReset
          ? response.data.data
          : [...quotes, ...response.data.data];
        if (finalRes?.length < response?.data?.totalCount) {
          setIsEnd(false);
        } else {
          setIsEnd(true);
        }
        setQuotes(finalRes);
        setisAPICalled(true);
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const handleBulkGenerateInvoice = async () => {
    if (selectedQuotes.length) {
      try {
        const selectedQuotesIds = selectedQuotes.map((item) => item.quoteId);
        await handleGenerateInvoice(selectedQuotesIds);
        fetchOpenInvoices(true, 1);
      } catch (error) {
        console.log({ error });
        toast.error(error.message ?? 'Something went wrong!');
      }
      setSelectedQuotes([]);
    } else {
      toast.error('Nothing Selected!');
    }
  };

  const handleBulkSend = async () => {
    if (selectedQuotes.length) {
      try {
        const selectedQuotesIds = selectedQuotes.map((item) => item.quoteId);
        await handleSendInvoice(selectedQuotesIds);
        fetchOpenInvoices(true, 1);
        setSelectedQuotes([]);
      } catch (error) {
        console.log({ error });
        toast.error(error.message ?? 'Something went wrong!');
      }
    } else {
      toast.error('Nothing Selected!');
    }
  };

  const handleBulkPayment = () => {
    if (selectedQuotes.length === 1) {
      navigate(`/salesRep/open-invoices/payment/${selectedQuotes[0].quoteId}`, {
        state: { totalAmountString: selectedQuotes[0].amount, selectedQuotes }
      });
    } else if (selectedQuotes.length > 1) {
      const totalAmount = selectedQuotes.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue.amount),
        0
      );
      const totalAmountString = totalAmount.toFixed(2);
      navigate('/salesRep/open-invoices/bulkPayment', {
        state: { totalAmountString, selectedQuotes }
      });
    } else {
      toast.error('Nothing Selected!');
    }
  };

  const handleLoadMore = () => {
    switch (location.pathname) {
      case '/salesRep/recentQuotes':
        fetchRecentQuotes(false, page + 1);
        break;

      case '/salesRep/active-shipment':
        fetchAvailableLoads(false, page + 1);
        break;

      case '/salesRep/pending-quotes':
        fetchPendingLoads(false, page + 1);
        break;

      case '/salesRep/enroute-quotes':
        fetchEnrouteLoads(false, page + 1);
        break;

      case '/salesRep/completed-quotes':
        fetchCompletedLoads(false, page + 1);
        break;
      case '/salesRep/open-invoices':
        fetchOpenInvoices(false, page + 1);
        break;

      default:
        break;
    }
    setPage((prevPage) => prevPage + 1);
  };

  const handleStatusChange = (_, obj) => {
    setCurrentStatus(obj);
    setSelectedQuotes([]);
  };

  useEffect(() => {
    switch (location.pathname) {
      case '/salesRep/recentQuotes':
        setTitle('Recent Quotes');
        fetchRecentQuotes(true, 1);
        setPage(1);
        setLoadType('salesRep-recent-quotes');
        break;

      case '/salesRep/active-shipment':
        setTitle('Assigned Shipment');
        fetchAvailableLoads(true, 1);
        setLoadType('salesRep-active-quotes');
        break;

      case '/salesRep/pending-quotes':
        setTitle('Pending');
        fetchPendingLoads(true, 1);
        setPage(1);
        setLoadType('salesRep-pending-quotes');
        break;

      case '/salesRep/enroute-quotes':
        setTitle('Enroute');
        fetchEnrouteLoads(true, 1);
        setLoadType('salesRep-enroute-quotes');
        break;

      case '/salesRep/completed-quotes':
        setTitle('Delivered');
        fetchCompletedLoads(true, 1);
        setLoadType('salesRep-completed-quotes');
        break;
      case '/salesRep/open-invoices':
        setPage(1);
        setTitle('Invoice');
        fetchOpenInvoices(true, 1);
        setLoadType('salesRep-open-invoices');
        break;

      default:
        break;
    }
  }, [location.pathname, currentStatus]);

  return (
    <section>
      <div className="py-20 bg-gray-50 radius-for-skewed">
        <div className="container mx-auto px-4">
          <div className="mb-6  py-4 relative">
            <div className="w-full flex flex-row justify-between items-end">
              <h3 className="text-3xl font-bold inline-block text-navy-500">
                {title}
              </h3>
              {loadType === 'salesRep-open-invoices' && (
                <div className="flex flex-col gap-4">
                  <span className="text-lg font-bold inline-block text-navy-500">
                    Status
                  </span>
                  <ConfigProvider
                    theme={{
                      components: {
                        Select: {
                          optionActiveBg: '#DEEBFF',
                          optionFontSize: 13
                        }
                      }
                    }}
                  >
                    <Select
                      value={currentStatus}
                      onChange={handleStatusChange}
                      style={{
                        width: 200
                      }}
                      defaultActiveFirstOption
                      placeholder="Search to Select"
                      options={invoiceStatusOptions}
                    />
                  </ConfigProvider>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col justify-center items-center gap-4">
            {isAPICalled && quotes.length > 0
              ? quotes.map((quote) => (
                  <QuoteDetailsCard
                    key={quote.id}
                    quote={quote}
                    loadType={loadType}
                    fetchOpenInvoices={fetchOpenInvoices}
                    selectedQuotes={selectedQuotes}
                    setSelectedQuotes={setSelectedQuotes}
                  />
                ))
              : 'No loads to display'}
          </div>
          {quotes.length > 0 &&
            (!isEnd ? (
              <button
                type="submit"
                className="bg-navy-500 text-white px-10 py-2 text-sm font-semibold float-right mt-4"
                onClick={handleLoadMore}
              >
                Load More
              </button>
            ) : (
              <p className="text-center mt-10">No more data</p>
            ))}
          {loadType === 'salesRep-open-invoices' &&
            quotes.length > 0 &&
            currentStatus?.value === UNPAID && (
              <div className="flex justify-center gap-5 w-full mt-10">
                <button
                  type="button"
                  className="bg-navy-500 text-white px-8 py-2 text-lg"
                  onClick={handleBulkGenerateInvoice}
                >
                  Generate Invoice
                </button>
                <button
                  type="button"
                  onClick={handleBulkPayment}
                  className="bg-navy-500 text-white px-10 py-2"
                >
                  Mark as Paid
                </button>
              </div>
            )}
          {loadType === 'salesRep-open-invoices' &&
            quotes.length > 0 &&
            currentStatus?.value === SENT && (
              <div className="flex flex-row justify-center gap-5 w-full mt-10">
                <button
                  type="button"
                  className="bg-navy-500 text-white px-10 py-2"
                  onClick={handleBulkSend}
                >
                  Resend
                </button>
                <button
                  type="button"
                  onClick={handleBulkPayment}
                  className="bg-navy-500 text-white px-10 py-2"
                >
                  Mark as Paid
                </button>
              </div>
            )}
        </div>
      </div>
    </section>
  );
});

export default QuotesListingPage;

import React from 'react';
import { Outlet } from 'react-router-dom';

import Footer from '../components/Footer';
import ProtectedHeader from '../components/ProtectedHeader';

const LayoutProtected = () => {
  return (
    <>
      <ProtectedHeader />
      <body className="antialiased bg-body text-body font-body overflow-x-hidden">
        <Outlet />
        <Footer />
      </body>
    </>
  );
};

export default LayoutProtected;

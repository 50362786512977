import React, { useContext, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { GlobalFormContext } from '../../../contexts/QuickQuoteContext';
import { LTL_FREIGHT } from '../../../constants/service_type.constants';
import {
  calculateDensity,
  getDefaultFreightValue
} from '../../../utils/quote.util';

const ServiceTypeCard = ({ servicetypeItem }) => {
  const { label, description } = servicetypeItem;
  const { serviceType, setServiceType, cargos, setCargos } =
    useContext(GlobalFormContext);

  const handleOnChange = () => {
    setServiceType(servicetypeItem.value);
  };

  useEffect(() => {
    if (cargos.length) {
      const updatedCargos = cargos.map((cargo) => {
        if (serviceType === LTL_FREIGHT) {
          if (!cargo.freight_class) {
            const currDensity = calculateDensity(cargo).density;
            const defaultFreightClass = getDefaultFreightValue(currDensity);
            return _.assign({}, cargo, {
              freight_class: defaultFreightClass.value
            });
          }
        }
        if (serviceType !== LTL_FREIGHT && cargo.freight_class) {
          return _.omit(cargo, ['freight_class']);
        }
        return cargo;
      });
      setCargos(updatedCargos);
    }
  }, [serviceType]);

  return (
    <div className="flex flex-row items-center gap-2 bg-navy-20 px-2 py-1 h-full">
      <input
        type="radio"
        name="service-type"
        className="quickQuote-radio mb-6"
        checked={serviceType === servicetypeItem.value}
        onChange={handleOnChange}
      />
      <div className="flex flex-col items-start">
        <span className="text-base font-semibold">{label}</span>
        <span className="text-gray-500">{description}</span>
      </div>
    </div>
  );
};

export default ServiceTypeCard;

ServiceTypeCard.propTypes = {
  servicetypeItem: PropTypes.object.isRequired
};

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
// import { trackingService } from '../services/quote.service';
import toast from 'react-hot-toast';
import { TRACK_STATUS } from '../constants/quote.constants';
import { ReactComponent as LeftArrowIcon } from '../assets/images/left_arrow.svg';
import { guestTrackingDataService } from '../services/quote.service';
import { getAllExternalNotesService } from '../services/notes.service';
import { LTL_FREIGHT } from '../constants/service_type.constants';

const GuestTrackingPage = () => {
  const navigate = useNavigate();
  //   const { quoteId } = useParams();

  const [quote, setQuote] = useState(null);
  const [trackingNumber, setTrackingNumber] = useState('');
  const [pickupData, setPickupData] = useState([]);
  const [deliveryData, setDeliveryData] = useState([]);
  const [pickupDate, setPickupDate] = useState('');
  const [navStatus, setNavStatus] = useState('details');
  const [externalNotes, setExternalNotes] = useState([]);

  useEffect(() => {}, [navStatus]);
  const onBackArrowClick = () => {
    navigate(-1);
  };

  const trackShipment = async (e) => {
    e.preventDefault();
    try {
      const response = await guestTrackingDataService(trackingNumber);

      const quoteData = response?.data?.quote;
      setQuote(quoteData);
      setTrackingNumber('');

      if (quoteData.service_type === LTL_FREIGHT) {
        setPickupDate(
          quoteData?.carrierPickupDate ||
            quoteData?.tracking_details?.pickedAt ||
            quoteData[quoteData.carrier_type]?.pickupDate
        );
      } else {
        setPickupDate(
          quoteData?.carrierPickupDate ||
            quoteData?.tracking_details?.pickupDate ||
            ''
        );
      }
      setPickupData(
        quoteData.isMultipleQuote ? quoteData.pickupInfo : [quoteData]
      );
      setDeliveryData(
        quoteData.isMultipleQuote ? quoteData.deliveryInfo : [quoteData]
      );

      const getExternalNotesResponse = await getAllExternalNotesService(
        quoteData?.id
      );
      const externalNotesData = getExternalNotesResponse?.data?.externalNotes;
      setExternalNotes(externalNotesData?.reverse());
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };
  return (
    <section className="py-10">
      <div className="container mx-auto">
        <div className="mb-8 w-full px-3 min-h-full">
          <div className="mb-6 relative text-navy-500">
            <div
              tabIndex="-1"
              role="button"
              onKeyPress={() => {}}
              onClick={onBackArrowClick}
              className="flex items-center"
            >
              <LeftArrowIcon className="w-6 h-6 flex items-center" />
              <h3 className="text-2xl ml-4 font-bold flex items-center">
                Shipment Tracking
              </h3>
            </div>
          </div>
          <div className="flex items-center mb-6">
            {/* <div className="flex space-x-4 outline-none"> */}
            <form
              onSubmit={trackShipment}
              className="flex space-x-4 outline-none"
            >
              <input
                type="text"
                className="block w-full px-4 py-2 bg-white border rounded-md focus:border-navy-500 focus:ring-navy-500 focus:outline-none focus:ring focus:ring-opacity-40"
                placeholder="Enter tracking number"
                onChange={(e) => setTrackingNumber(e.target.value)}
                value={trackingNumber}
              />
              <button
                className="px-4 text-white bg-navy-500 border-l rounded hover:bg-navy-600 active:bg-navy-700"
                type="submit"
                disabled={!trackingNumber}
              >
                Track
              </button>
            </form>
            {/* </div> */}
          </div>
          {quote ? (
            <div className="text-black md:flex flex-wrap">
              <div className="bg-gray-100 h-96 border-2 w-full md:w-1/2 inline-block tracking-wide">
                <div className="w-full m-2">
                  <p>
                    {`
                      NET#
                      ${quote?.internalTrackingNumber}`}
                  </p>
                </div>
                <hr />
                <div className="w-full p-4 mb-4">
                  <p className="text-center text-xl font-bold text-navy-500">
                    {
                      TRACK_STATUS[
                        quote?.tracking_details?.shipmentStatus?.statusCd
                      ]
                    }
                  </p>
                  <div className="p-10">
                    <div className="flex items-center">
                      {Object.keys(TRACK_STATUS).map((step, index) => {
                        return (
                          <>
                            <div className="flex items-center text-black relative">
                              <span className="flex h-4 w-4">
                                {Number(step) ===
                                quote?.tracking_details?.shipmentStatus
                                  ?.statusCd ? (
                                  <>
                                    {' '}
                                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-navy-500" />
                                    <span className="relative inline-flex rounded-full h-4 w-4 border-2 bg-navy-500 border-navy-500" />
                                  </>
                                ) : (
                                  <span className="relative inline-flex rounded-full h-4 w-4 border-2 border-navy-500" />
                                )}
                              </span>
                              <div className="absolute top-0 -ml-14 text-center mt-6 w-32 text-sm whitespace-normal font-medium">
                                {Number(step) !== 2
                                  ? TRACK_STATUS[step]
                                  : `${TRACK_STATUS[step]} ${pickupDate}`}
                                {Number(step) === 5 && quote.bidStatus === 3 ? (
                                  <span className="block">
                                    {quote?.tracking_details?.deliveredAt ??
                                      moment(quote?.deliveryDateTime).format(
                                        'MM/DD/YYYY'
                                      )}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                            {index !== 4 ? (
                              <div className="flex-auto border-t-2 border-navy-500 opacity-70" />
                            ) : (
                              ''
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="flex justify-between py-4 mx-4 text-center">
                  <div className="">
                    {quote.bidStatus === 3 ? (
                      <>
                        <p>Delivered At:</p>
                        <p className="font-medium">
                          {quote?.tracking_details?.deliveredAt ??
                            moment(quote?.deliveryDateTime).format(
                              'MM/DD/YYYY'
                            )}
                        </p>
                      </>
                    ) : (
                      <>
                        <p>Delivery ETA:</p>
                        <p className="font-medium">
                          {quote?.tracking_details?.estimatedDelivery ??
                            quote[quote.carrier_type]?.transitDate}
                        </p>
                      </>
                    )}
                  </div>
                  {quote.bidStatus === 3 ? (
                    <div>
                      <p>Delivered By:</p>
                      <p className="font-medium">
                        {quote?.tracking_details?.driverName ||
                          quote.driverName}
                      </p>
                    </div>
                  ) : null}
                  <div>
                    <p>Last Updated:</p>
                    <p className="font-medium">
                      {moment(quote.updatedAt).format('MM/DD/YYYY h:mm A')}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="p-4 mx-8 text-center">
                  <p>{quote?.tracking_details?.shipmentStatus?.description}</p>
                </div>
              </div>
              <div className="bg-gray-100 border-2 w-full md:w-1/2 inline-block">
                <div className="w-full m-2">
                  <p>Additional Information</p>
                </div>
                <hr />
                <div className="w-full">
                  <nav>
                    <div className="max-w-6xl mx-auto">
                      <div className="flex justify-between">
                        <div className="items-center space-x-4 text-navy-500 text-sm inline-flex">
                          <button
                            type="button"
                            onClick={() => setNavStatus('details')}
                            className={`p-2 text-navy-500 border-navy-500 ${
                              navStatus === 'details' ? 'border-b-2' : ''
                            }`}
                          >
                            Details
                          </button>
                          <button
                            type="button"
                            onClick={() => setNavStatus('history')}
                            className={`p-2 text-navy-500 border-navy-500 ${
                              navStatus === 'history' ? 'border-b-2' : ''
                            }`}
                          >
                            History
                          </button>
                          <button
                            type="button"
                            onClick={() => setNavStatus('external_notes')}
                            className={`p-2 text-navy-500 border-navy-500 ${
                              navStatus === 'external_notes' ? 'border-b-2' : ''
                            }`}
                          >
                            Notes
                          </button>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
                <hr />
                {navStatus === 'details' && (
                  <div className="w-full py-2 h-90 overflow-scroll">
                    <div className="m-2 py-2 text-sm">
                      <p className="mb-2 font-medium">Shipper</p>
                      {pickupData?.map((pick) => {
                        return (
                          <div className="my-2">
                            <p>{`${pick?.pickup_address?.city}, ${pick?.pickup_address?.state} ${pick?.pickup_zip_code}`}</p>
                          </div>
                        );
                      })}
                    </div>
                    <hr />
                    <div className="m-2 py-2 text-sm">
                      <p className="mb-2 font-medium">Consignee</p>
                      {deliveryData?.map((del) => {
                        return (
                          <div className="my-2">
                            <p>{`${del?.delivery_address?.city}, ${del?.delivery_address?.state} ${del?.delivery_zip_code}`}</p>
                          </div>
                        );
                      })}
                    </div>
                    <hr />
                    <div className="m-2 py-2 text-sm">
                      <p className="mb-2 font-medium">Shipment Details</p>
                      {quote?.cargos?.map((cargo) => {
                        return (
                          <p>{`${cargo.quantity} Pc/s weighing ${cargo.weight} Lbs`}</p>
                        );
                      })}
                      <p>{`Pickup Date: ${pickupDate}`}</p>
                    </div>
                  </div>
                )}
                {navStatus === 'external_notes' && (
                  <div>
                    <div className="max-h-72 overflow-scroll">
                      {externalNotes?.map((externalNote) => {
                        return (
                          <>
                            <div className="p-2">
                              <p>{`${moment(externalNote.createdAt).format(
                                'MM/DD/YYYY hh:mm A'
                              )}`}</p>
                              <p className="text-lg font-medium">
                                {externalNote.note}
                              </p>
                            </div>
                            <hr />
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}
                {navStatus === 'history' && (
                  <div className="py-2">
                    {quote?.tracking_details?.cities?.map((city) => {
                      return (
                        <>
                          <div className="m-2">
                            <p>{`${city.traceAt} (Central Standard Time)`}</p>
                            <p className="text-lg font-medium">{`${city.cityName}, ${city.stateCd}`}</p>
                          </div>
                          <hr />
                        </>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </section>
  );
};

export default GuestTrackingPage;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import toast from 'react-hot-toast';

import GoogleMapComponent from '../../../components/GoogleMapComponent/GoogleMapComponent';
import { getLatitudeLongitude } from '../../../services/geo.service';

const RenderGoogleMap = ({ pickupZipCode, deliveryZipCode, isZipError }) => {
  const [isGoogleAPICalled, setGoogleAPICalled] = useState(false);
  const [shouldMapLoad, setShouldMapLoad] = useState(false);

  const [pickupPoints, setPickupData] = useState([
    {
      lat: 0,
      lng: 0
    }
  ]);

  const [deliveryPoints, setDeliveryData] = useState([
    {
      lat: 0,
      lng: 0
    }
  ]);

  async function fetchCoordinatesForMap() {
    try {
      // const [pickupResponse, deliveryResponse] = await Promise.all([
      //   getLatitudeLongitude(pickupZipCode),
      //   getLatitudeLongitude(deliveryZipCode),
      // ]);

      const pickupResponse = await Promise.all(
        pickupZipCode?.map((pick) => getLatitudeLongitude(pick))
      );

      const deliveryResponse = await Promise.all(
        deliveryZipCode?.map((del) => getLatitudeLongitude(del))
      );
      // console.log(pickupResponse, deliveryResponse);
      if (!pickupResponse || !deliveryResponse) {
        toast.error(
          'Please verify that you have entered valid pickup and delivery Zip codes!'
        );
        isZipError(true);
        setShouldMapLoad(false);
      } else {
        isZipError(false);
        setShouldMapLoad(true);
      }

      setPickupData(pickupResponse);
      setDeliveryData(deliveryResponse);
      setGoogleAPICalled(true);
    } catch (error) {
      console.log(error);
      setShouldMapLoad(false);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    fetchCoordinatesForMap();
  }, [pickupZipCode, deliveryZipCode]);

  return (
    <div className="mb-8 w-full lg:w-1/2 px-3 -mt-4">
      {isGoogleAPICalled && shouldMapLoad ? (
        <GoogleMapComponent
          pickupLatLong={pickupPoints}
          deliveryLatLong={deliveryPoints}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default RenderGoogleMap;

RenderGoogleMap.propTypes = {
  pickupZipCode: PropTypes.array.isRequired,
  deliveryZipCode: PropTypes.array.isRequired,
  isZipError: PropTypes.func.isRequired
};

import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import { PAYMENT_MODES } from '../../constants/invoice.constants';

const PaymentForm = ({
  paymentMode,
  amount,
  setAmount,
  chequeNumber,
  setChequeNumber,
  transactionDate,
  setTransactionDate,
  comment,
  setComment,
  validate,
  isSinglePaymentMode
}) => {
  const handleDateChange = (date, dateString) => {
    setTransactionDate({ date, dateString });
  };
  return (
    <div className="flex flex-col w-full gap-3">
      <div className="flex flex-col justify-center items-start gap-1">
        <span>
          <span className="text-red-600 text-base">*</span>
          Enter Amount
        </span>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Enter Amount"
          className={`border border-gray-300 h-10 p-3 w-full focus:outline-none ${!isSinglePaymentMode && 'cursor-not-allowed'}`}
          disabled={!isSinglePaymentMode}
        />
        {!amount && validate && (
          <span className="text-red-600 text-md pl-1">Amount Required</span>
        )}
      </div>
      {paymentMode === PAYMENT_MODES.CHECK && (
        <div className="flex flex-col justify-center items-start">
          <span>
            {' '}
            <span className="text-red-600 text-base">*</span>Enter Your Check
            Number
          </span>
          <input
            type="number"
            value={chequeNumber}
            onChange={(e) => setChequeNumber(e.target.value)}
            placeholder="Enter Check Number"
            className="border border-gray-300 h-10 p-3 w-full focus:outline-none"
          />
          {!chequeNumber && validate && (
            <span className="text-red-600 text-md pl-1">
              Check Number Required
            </span>
          )}
        </div>
      )}
      <div className="flex flex-col justify-center items-start">
        <span>
          {' '}
          <span className="text-red-600 text-base">*</span>Transaction Date
        </span>
        <div className="border border-gray-300 w-full">
          <DatePicker
            value={transactionDate?.date}
            onChange={handleDateChange}
            variant="borderless"
            className="w-full h-10"
            format="MM/DD/YYYY"
          />
        </div>
        {!transactionDate?.dateString && validate && (
          <span className="text-red-600 text-md pl-1">
            Transaction Date Required
          </span>
        )}
      </div>
      <div className="flex flex-col justify-center items-start focus:outline-none">
        <span>Comment</span>
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          name="payment-comment"
          cols="10"
          rows="3"
          placeholder="Write your comment here"
          className="border border-gray-300 p-3 w-full focus:outline-none"
        />
      </div>
    </div>
  );
};

export default PaymentForm;

PaymentForm.propTypes = {
  paymentMode: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  setAmount: PropTypes.func.isRequired,
  chequeNumber: PropTypes.string.isRequired,
  setChequeNumber: PropTypes.func.isRequired,
  transactionDate: PropTypes.string.isRequired,
  setTransactionDate: PropTypes.func.isRequired,
  comment: PropTypes.string.isRequired,
  setComment: PropTypes.func.isRequired,
  validate: PropTypes.bool.isRequired,
  isSinglePaymentMode: PropTypes.bool.isRequired
};

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { IoIosSquare } from 'react-icons/io';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import {
  dropdownOptions,
  dropDownStyles
} from '../../constants/dashboard.constants';
import { useAuthContext } from '../../contexts/auth.context';
import { dashboardService } from '../../services/salesrep.service';
import Shipments from '../../components/Shipments';
import { shipperProfileService } from '../../services/shipper.service';

const ShipperDashboard = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const navigate = useNavigate();
  const { authUser } = useAuthContext();

  const [currentOption, setCurrentOption] = useState({
    value: 30,
    label: 'Last 30 days'
  });

  const [chartData, setChartData] = useState({});
  const [isAPICalled, setisAPICalled] = useState(false);
  const [user, setUser] = useState();

  async function fetchDashboardData(duration) {
    try {
      const response = await dashboardService(null, duration);
      // completedTotal,
      const { bidStatuses, recentQuotes } = response.data;

      const userId = authUser?.user?.id;

      const userResponse = await shipperProfileService(userId);

      setUser(userResponse?.data);

      setChartData({
        labels: Array(5).fill(''),
        datasets: [
          {
            label: 'Number of Quotes',
            data: [
              recentQuotes,
              bidStatuses.bidAdded,
              bidStatuses.bidAccepted,
              bidStatuses.bidEnroute,
              bidStatuses.bidCompleted
            ],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)'
              // 'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)'
              // 'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 0
          }
        ]
      });
      setisAPICalled(true);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  const handleChange = (selectedOption) => {
    setCurrentOption(selectedOption);
  };

  const fetchData = async () => {
    const link = localStorage.getItem('link');
    if (link) {
      localStorage.removeItem('link');
      return navigate(link);
    }

    try {
      await fetchDashboardData(currentOption?.value);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchData();

    return () => {
      setChartData();
    };
  }, [currentOption]);

  const indicators = [
    {
      colour: 'rgba(255, 99, 132, 0.4)',
      text: 'Recent'
    },
    {
      colour: 'rgba(54, 162, 235, 0.4)',
      text: 'Pending'
    },
    {
      colour: 'rgba(255, 206, 86, 0.4)',
      text: 'Assigned'
    },
    {
      colour: 'rgba(75, 192, 192, 0.4)',
      text: 'En-route'
    },
    {
      colour: 'rgba(153, 102, 255, 0.4)',
      text: 'Delivered'
    }
  ];

  const barOptions = {
    scales: {
      y: {
        ticks: {
          beginAtZero: true
        }
      }
    },
    // maintainAspectRatio: false,
    legend: {
      position: 'bottom',
      display: true,
      onClick: (e) => {
        if (e.event) {
          e.event.preventDefault();
        }
      },
      labels: {
        fontColor: 'rgb(35, 31, 32)',
        usePointStyle: true,
        boxWidth: 9,
        fontSize: 16
      }
    },
    // maintainAspectRatio: false,
    responsive: true,
    cutoutPercentage: 50
  };
  return (
    <div className="flex flex-col py-10 bg-gray-50">
      <div className="container px-4 mx-auto">
        <div className="w-full inline-block">
          <div className="flex flex-col">
            <div className="font-bold text-lg">Billing</div>
            <div className="flex flex-row gap-12 py-6">
              <div className="flex flex-col">
                <span className=" text-gray-600 mb-2">Credit Limit:</span>
                <span className="font-semibold">
                  {user?.companyId?.creditLimit.toFixed(2)}
                </span>
              </div>
              <div className="flex flex-col">
                <span className=" text-gray-600 mb-2">Open balance:</span>
                <span className="font-semibold">
                  {user?.companyId?.openBalance.toFixed(2)}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="text-gray-600 mb-2">Terms:</span>
                <span className="font-semibold">
                  {user?.companyId?.paymentTerms}
                </span>
              </div>
            </div>
          </div>
          <hr className="border-b-1" />
          <div className="flex flex-row py-2 my-2 justify-between">
            <h3 className="text-3xl font-bold mb-2 text-navy-500">
              This month&apos;s stats
            </h3>
            <div className="w-48">
              <Select
                value={currentOption}
                onChange={handleChange}
                options={dropdownOptions}
                isSearchable={false}
                styles={dropDownStyles}
              />
            </div>
          </div>
          {isAPICalled && chartData && (
            <div className="flex flex-row gap-6 pt-2 pb-0 px-3 border border-gray-300 h-60 items-center">
              <Bar data={chartData} options={barOptions} />
              <div class="grid grid-cols-3 ">
                {indicators.map(({ colour, text }) => (
                  <div class="flex flex-row gap-2 items-center py-2 my-2 mx-8">
                    <IoIosSquare
                      size={25}
                      style={{
                        color: colour
                      }}
                    />
                    <span>{text}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <Shipments />
      </div>
    </div>
  );
};

export default ShipperDashboard;

import API from './api.services';
import API_ENDPOINTS from '../constants/api-endpoints.constants';

const addNoteService = (body) => {
  return API.post(API_ENDPOINTS.NOTES.AddNote, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const getAllInternalNotesService = (quoteId) => {
  return API.get(`${API_ENDPOINTS.NOTES.GetAllInternalNotes}`, {
    params: {
      token: localStorage.getItem('token'),
      quoteId
    }
  });
};

const getAllExternalNotesService = (quoteId) => {
  return API.get(`${API_ENDPOINTS.NOTES.GetAllExternalNotes}`, {
    params: {
      quoteId
    }
  });
};

export {
  addNoteService,
  getAllInternalNotesService,
  getAllExternalNotesService
};

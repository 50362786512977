import React from 'react';

import ProtectedHeader from '../components/ProtectedHeader';

const CarrierThankYouPage = () => {
  return (
    <>
      <ProtectedHeader />
      <section>
        <div className="py-40 bg-gray-50">
          <div className="container mx-auto px-4">
            <div className="mb-6  p-4 relative pl-0 text-center">
              <h2 className="text-3xl font-bold ">Congratulations!</h2>
              <p className="text-gray-500 text-2xl">
                Your onboarding process is complete! You can close this window.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CarrierThankYouPage;

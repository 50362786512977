import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import BasicDetailsForm from './BasicDetailsForm';
import { ReactComponent as RightArrowIcon } from '../../../assets/images/right_arrow.svg';
import { shipperRegisterService } from '../../../services/shipper.service';

const ShipperSignupPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const referralCode = searchParams.get('referralCode');
  const navigate = useNavigate();
  const step = 1;
  const [compiledForm, setCompiledForm] = useState({});

  const validationSchema = [
    // validation for step1
    yup.object({
      name: yup.string().required('Shipper name is required'),
      email: yup
        .string()
        .required('Email is required!')
        .email('Please enter a valid email.'),
      phone: yup
        .number()
        .typeError('Mobile number must be a number')
        .integer('Mobile number must be an integer')
        .positive('Mobile number must be a positive number')
        .required('Mobile number is required')
        .min(1000000000, 'Mobile number must be at least 10 digits')
        .max(9999999999, 'Mobile number can be at most 10 digits')
        .test('is-num', 'Mobile number must be numeric', (value) => {
          return /^[0-9]*$/.test(value.toString());
        }),
      password: yup.string().required('Password is required'),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password')], 'Please make sure your passwords match!')
    }),
    // validation for step2
    yup.object({
      company: yup.string().required('Company name is required'),
      address1: yup.string().required('Address Line 1 is required'),
      address2: yup.string(),
      city: yup.string().required('City is required'),
      state: yup.string().required('State is required'),
      country: yup.string().required('Country is required').default('US'),
      zipcode: yup.string().required('Zip code is required')
    })
  ];

  const currentValidationSchema = validationSchema[step - 1];

  const methods = useForm({
    resolver: yupResolver(currentValidationSchema),
    shouldUnregister: false,
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const {
    handleSubmit,
    watch,
    formState: { isValid },
    trigger
  } = methods;
  const form = watch();

  const nextStep = async () => {
    let isStepValid;
    switch (step) {
      case 1: {
        isStepValid = await trigger(
          ['name', 'email', 'phone', 'password', 'confirmPassword'],
          {
            shouldFocus: true
          }
        );
        break;
      }
      default:
        break;
    }

    if (isStepValid || isValid) {
      switch (step) {
        case 1: {
          setCompiledForm({ ...compiledForm, one: form });
          break;
        }
        default:
          break;
      }
    }
  };

  const GetStepContent = (formContent) => {
    switch (step) {
      case 1:
        return <BasicDetailsForm {...formContent} />;

      default:
        break;
    }
  };

  const onSubmitUser = async () => {
    try {
      const body = {
        name: form.name,
        email: form.email,
        password: form.password,
        phone: form.phone,
        referralCode
      };

      const response = await shipperRegisterService({
        ...body,
        version: process.env.REACT_APP_APP_VERSION
      });
      if (response.data.flag) {
        toast.success(response.data.message);
        navigate('/login');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <section className="py-10 lg:py-20">
      <div className="container mx-auto">
        <div className=" mx-auto">
          <div className="mb-6 lg:mb-10 text-left relative">
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmitUser)}>
                {GetStepContent(compiledForm)}
                <div className="text-left p-4 relative">
                  <button
                    type="submit"
                    onClick={nextStep}
                    className="border-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-4 
                   bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700border text-white
                 text-sm font-bold transition duration-200 text-left"
                  >
                    Sign Up
                    <RightArrowIcon className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-6 font-bold" />
                  </button>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShipperSignupPage;

const dropdownOptions = [
  { value: 30, label: 'Last 30 days' },
  { value: 60, label: 'Last 60 days' },
  { value: 90, label: 'Last 90 days' }
];

const dropDownStyles = {
  control: (baseStyles) => ({
    ...baseStyles,
    width: '100%',
    borderColor: 'inherit',
    background: '#F6F6F6',
    border: '1px inherit',
    borderRadius: 0,
    boxShadow: 'none',
    '&:hover': {
      border: '1px inherit'
    },
    color: 'black'
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    fontWeight: 'bold', // Make selected item bold,
    paddingLeft: '12px'
  }),
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    fontWeight: 'bold', // Make arrow icon bold,
    paddingRight: '12px'
  }),
  menu: (base) => ({
    ...base,
    // kill the gap
    marginTop: 0
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  })
};

const rolesOptions = [
  {
    value: '664b48faf70ecc1c4b90912e',
    label: 'General'
  },
  {
    value: '664b48faf70ecc1c4b90912a',
    label: 'Accounting'
  },
  {
    value: '664b48faf70ecc1c4b90912b',
    label: 'Main'
  },
  {
    value: '664b48faf70ecc1c4b90912c',
    label: 'Transportation'
  },
  {
    value: '664b48faf70ecc1c4b90912d',
    label: 'Billing'
  }
];

export { dropdownOptions, dropDownStyles, rolesOptions };

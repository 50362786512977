import React from 'react';

import { usePromiseTracker } from 'react-promise-tracker';
import { ThreeDots } from 'react-loader-spinner';

const Spinner = () => {
  const { promiseInProgress } = usePromiseTracker();
  const type = new URLSearchParams(window.location.search)?.get('type');

  return (
    promiseInProgress && (
      <div className="spinner h-screen w-screen fixed top-0 bg-black bg-opacity-50 flex text-center overflow-hidden overscroll-none flex-col justify-center">
        <ThreeDots
          height="100"
          width="100"
          color="#FFF"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperClass="mx-auto"
        />
        {type === '5805d83787ae495159586545' ? (
          <p className="text-blue-50 font-bold text-xl">
            {' '}
            Be Patient, Great LTL rates are just around the corner
          </p>
        ) : null}
      </div>
    )
  );
};

export default Spinner;

import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import Pagination from '../Pagination';

import {
  getAllCustomerRepsBySalesRep,
  updateCustomerRepService
} from '../../services/customerRep.service';

import { ReactComponent as SearchIcon } from '../../assets/images/search_icon.svg';

const CustomerRepTableView = () => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [searchTerm, setSearchTerm] = useState('');
  const [query, setQuery] = useState('');

  const [isAPICalled, setisAPICalled] = useState(false);

  const [users, setUsers] = useState([]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  async function fetchAllUsers() {
    try {
      const response = await getAllCustomerRepsBySalesRep(
        recordsPerPage,
        currentPage,
        encodeURIComponent(query)
      );
      setUsers(response.data.users);
      setTotalCount(response.data.totalUsers);
      setisAPICalled(true);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    fetchAllUsers();
  }, [currentPage, query]);

  const search = (queryString) => {
    setQuery(queryString);
    setCurrentPage(1);
  };

  const onViewCustomerRep = (customerRepId) => {
    navigate(`edit/${customerRepId}`);
  };

  const onAddCustomerRep = () => {
    navigate('add');
  };
  const changeUserStatus = async (user) => {
    try {
      const response = await updateCustomerRepService(user.id, {
        status: !user.status
      });
      if (response.status === 200) {
        toast.success('Customer Details updated successfully!');
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    } finally {
      fetchAllUsers();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      search(event.target.value);
    }
  };

  return (
    <div className="w-full">
      <div className="mb-6 p-4 pl-0 w-full md:w-9/12 inline-block">
        <h3 className="text-3xl text-navy-500 font-bold inline-block ">
          Associated Customer Representatives
        </h3>
      </div>
      <div className="mb-6 p-4 pr-0 w-full md:w-3/12 inline-block text-right items-center">
        <div className="relative text-gray-600">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <SearchIcon />
          </span>
          <input
            className="appearance-none block w-full border border-navy-400 py-3 pl-10 px-4 mb-3 leading-tight focus:outline-none"
            type="search"
            placeholder="Search ..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
      <div className="my-4">
        <button
          type="button"
          className="border-2
                             bg-navy-500  border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white hover:border-navy-600 active:border-navy-700
                             text-sm font-bold transition duration-200 text-left relative px-4 py-2"
          onClick={onAddCustomerRep}
        >
          Add Customer Representative
        </button>
      </div>
      <div className="w-full inline-block overflow-x-scroll">
        <table className="table-auto w-full text-left">
          <thead className="text-gray-400 mb-2">
            <tr>
              <th className="font-light pb-2 pl-2">First Name</th>
              <th className="font-light pb-2 pl-2">Last Name</th>
              <th className="font-light pb-2">Email</th>
              <th className="font-light pb-2">Status</th>
              <th className="font-light pb-2">Action</th>
            </tr>
          </thead>
          <tbody className="border border-gray-300 p-2">
            {isAPICalled ? (
              <>
                {users.map((user) => {
                  return (
                    <tr key={user.id} className="border-b border-gray-300">
                      <td className="py-4 pl-3">{user.person}</td>
                      <td className="py-4 pl-3">{user.last_name}</td>
                      <td className="py-4">{user.email}</td>
                      <td className="py-4">
                        <div
                          className={`flex items-center justify-center rounded-4xl w-32 p-1 ${
                            user.status
                              ? 'btn-container--on'
                              : 'btn-container--off'
                          }`}
                        >
                          <span className="mr-1 ml-0">
                            {user.status ? 'Active' : 'Inactive'}
                          </span>
                          <div
                            className={`btn-switch ${
                              user.status ? 'btn-switch--on' : ''
                            }`}
                            onClick={() => {
                              changeUserStatus(user);
                            }}
                            onKeyPress={() => {}}
                            role="button"
                            tabIndex={0}
                          >
                            <div
                              className={`btn-switch-circle ${
                                user.status ? 'btn-switch-circle--on' : ''
                              }`}
                              onClick={() => {
                                changeUserStatus(user);
                              }}
                              onKeyPress={() => {}}
                              role="button"
                              tabIndex={0}
                            />
                          </div>
                        </div>
                      </td>
                      <td className="py-4">
                        <button
                          type="button"
                          onClick={() => {
                            onViewCustomerRep(user.id);
                          }}
                          className="border-2
                             bg-navy-500  border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white hover:border-navy-600 active:border-navy-700
                             text-sm font-bold transition duration-200 text-left relative px-4 py-2"
                        >
                          View / Edit
                        </button>
                      </td>
                    </tr>
                  );
                })}

                {!totalCount && (
                  <tr className="border-none p-3">
                    <td colSpan="3">
                      <h3 className="text-center p-3">No users found!</h3>
                    </td>
                  </tr>
                )}
              </>
            ) : (
              ''
            )}
          </tbody>
        </table>
      </div>
      {totalCount > 0 && (
        <div className="px-4 py-3 flex items-end justify-end border-gray-200 sm:px-6">
          <Pagination
            activePage={currentPage}
            perPage={recordsPerPage}
            total={totalCount}
            handlePageChange={(page) => handlePageChange(page)}
          />
        </div>
      )}
    </div>
  );
};

export default CustomerRepTableView;

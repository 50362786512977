import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../contexts/auth.context';

import { ReactComponent as LeftArrowIcon } from '../../assets/images/left_arrow.svg';
import { loadAddressService } from '../../services/quote.service';
import {
  deleteCargoService,
  deleteLocationService,
  getSavedCargosService
} from '../../services/shipper.service';
import PickupDeliveryProfile from '../../components/PickupDeliveryProfile';
import CargoProfile from '../../components/CargoProfile';
import Roles from '../../constants/roles.constants';

const accordianList = [
  { title: 'Pickup Addresses', type: 'Pickup' },
  { title: 'Delivery Addresses', type: 'Delivery' },
  { title: 'Saved Cargos', type: 'Cargo' }
];

const ShipperProfilePage = () => {
  const { authUser } = useAuthContext();
  const { customerId } = useParams();

  const navigate = useNavigate();

  const [userAddress, setUserAddress] = useState([]);
  const [savedCargos, setSavedCargos] = useState([]);
  const [accordionOpen, setAccordionOpen] = useState(
    Array(accordianList.length).fill(false)
  );

  const userId =
    authUser?.user?.usertype === Roles.SHIPPER
      ? authUser?.user?.companyId?.id
      : customerId;

  async function loadAddresses() {
    try {
      const response = await loadAddressService(userId);
      setUserAddress(response.data.data);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  async function loadCargos() {
    try {
      const response = await getSavedCargosService(userId);
      setSavedCargos(response?.data?.data);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }

  useEffect(() => {
    loadAddresses();
    loadCargos();
  }, []);

  const toggleAccordion = (index) => {
    setAccordionOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const deleteLocation = async (id) => {
    try {
      const response = await deleteLocationService(id);
      toast.success(response?.data?.message);

      const newUserAddresses = userAddress.filter((item) => item.id !== id);

      setUserAddress(newUserAddresses);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const deleteCargo = async (id) => {
    try {
      const response = await deleteCargoService(id);
      toast.success(response?.data?.message);

      const updatedCargos = savedCargos.filter((item) => item.id !== id);

      setSavedCargos(updatedCargos);
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const onBackArrowClick = () => {
    navigate(-1);
  };

  const ProfileLists = (accord) => {
    switch (accord.type) {
      case 'Pickup':
      case 'Delivery':
        return (
          <PickupDeliveryProfile
            accord={accord}
            deleteLocation={deleteLocation}
            userAddress={userAddress}
            customerId={customerId}
          />
        );

      case 'Cargo':
        return (
          <CargoProfile
            customerId={customerId}
            deleteCargo={deleteCargo}
            savedCargos={savedCargos}
          />
        );

      default:
        break;
    }
  };

  return (
    <section className="py-20">
      <div className="container mx-auto">
        <div className="mx-auto">
          <div className="mb-8 w-full px-3">
            <div className="mb-6  p-4 relative pl-0 flex gap-3">
              <div
                tabIndex="-1"
                role="button"
                onKeyPress={() => {}}
                onClick={onBackArrowClick}
                className="flex items-center"
              >
                <LeftArrowIcon className="left-0 flex items-center w-6 h-6 " />
              </div>
              <h3 className="text-3xl font-bold text-navy-500">
                Customer Details
              </h3>
            </div>
            {accordianList.map((accord, index) => {
              return (
                <div className="w-full my-4">
                  <button
                    type="button"
                    className="w-full flex items-center bg-gray-100 hover:bg-blue-200 py-3 px-4 text-left focus:outline-none"
                    onClick={() => toggleAccordion(index)}
                  >
                    <span className="font-extrabold flex-grow text-xl">
                      {accord.title}
                    </span>
                    <svg
                      className={`w-5 h-5 ml-auto transform transition-transform duration-300 ${
                        accordionOpen[index] ? 'rotate-180' : ''
                      }`}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                  {accordionOpen[index] && ProfileLists(accord)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShipperProfilePage;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  getCarrierPrice,
  getServiceTypeName,
  getSummaryPath
} from '../../utils/quote.util';
import { BID_TYPE } from '../../constants/bids.constants';
import QuoteIcon from '../../components/QuoteIcon';

const DashboardCard = ({ quote }) => {
  const navigate = useNavigate();
  const { OPEN_BID } = BID_TYPE;

  const [summaryPath, setSummaryPath] = useState('');

  const pickupZipCode = quote?.isMultipleQuote
    ? quote?.pickupInfo?.length &&
      quote?.pickupInfo?.map((pick) => pick.pickup_zip_code)?.join(', ')
    : quote.pickup_zip_code;

  const deliveryZipCode = quote?.isMultipleQuote
    ? quote?.deliveryInfo?.length &&
      quote?.deliveryInfo?.map((del) => del.delivery_zip_code)?.join(', ')
    : quote.delivery_zip_code;

  const serviceType = getServiceTypeName(quote?.service_type);

  useEffect(() => {
    if (quote) {
      const summaryPathName = getSummaryPath(quote);
      setSummaryPath(summaryPathName);
    }
  }, [quote]);
  return (
    <div className="flex flex-row bg-gray-100 border border-gray-100 shadow p-4 w-full h-64 sm:h-52 md:h-32">
      <QuoteIcon />
      <div className="flex flex-col md:flex-row justify-between w-full">
        <div className="flex flex-col gap-3">
          <div className="flex flex-row gap-8">
            <div className="flex flex-col">
              <span className="text-gray-500">From:</span>
              <span className="font-semibold">{pickupZipCode}</span>
            </div>
            <div className="flex flex-col">
              <span className="text-gray-500">To:</span>
              <span className="font-semibold">{deliveryZipCode}</span>
            </div>
            <div className="flex flex-col">
              <span className="text-gray-500">Service Type:</span>
              <span className="font-semibold">{serviceType}</span>
            </div>
          </div>
          {quote?.bidType === OPEN_BID ? (
            <span className="text-lg font-bold text-green-700">Open Bid</span>
          ) : (
            <span className="text-lg font-bold">
              Budget: {getCarrierPrice(quote)}
            </span>
          )}
        </div>

        <div className="flex flex-col sm:flex-row gap-2 sm:gap-20">
          <div className="mt-0 sm:mt-9">
            {quote?.internalTrackingNumber && (
              <span> #{quote.internalTrackingNumber}</span>
            )}
          </div>
          <button
            type="button"
            className="bg-navy-500 h-10 w-24 mt-1 sm:mt-6 text-white text-lg rounded-lg"
            onClick={() => navigate(summaryPath)}
          >
            View
          </button>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;

DashboardCard.propTypes = {
  quote: PropTypes.object.isRequired
};

import React, { memo } from 'react';

const RightArrowIcon = memo(() => {
  return (
    <svg
      className="w-10 h-10 pointer-events-none px-2 inline-block font-bold"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14 5l7 7m0 0l-7 7m7-7H3"
      />
    </svg>
  );
});

export default RightArrowIcon;

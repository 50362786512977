import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';

import ForgetPassword from '../components/ForgetPassword/ForgetPassword';

import {
  userLogin,
  forgotPasswordService,
  salesRepLogin
} from '../services/auth.service';
import { useAuthContext } from '../contexts/auth.context';
import { forgotPasswordSalesRep } from '../services/salesrep.service';

const LoginPage = () => {
  const { setAuthUser } = useAuthContext();

  const location = useLocation();

  const [showModal, setshowModal] = useState(false);

  const toggleModal = () => setshowModal((prevModal) => !prevModal);

  const [isSalesRepLogin, setisSalesRepLogin] = useState(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .required('Email is required!')
      .email('Please enter a valid email.'),
    password: yup.string().required('Password is required!')
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onLogin = async (data) => {
    try {
      if (isSalesRepLogin) {
        const response = await salesRepLogin({
          ...data,
          version: process.env.REACT_APP_APP_VERSION
        });
        if (response.status === 200 && response.data.flag) {
          const { user, token, usertype } = response.data;
          user.usertype = usertype;
          setAuthUser(user, token);

          toast.success(response.data.message);
        }
      } else {
        const response = await userLogin({
          ...data,
          platform: 'web',
          version: process.env.REACT_APP_APP_VERSION
        });
        if (response.status === 200 && response.data.flag) {
          const { user, token, usertype } = response.data;
          user.usertype = usertype;
          setAuthUser(user, token);

          toast.success(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const onSubmitEmail = async (email) => {
    try {
      if (email) {
        toggleModal();
        if (isSalesRepLogin) {
          const response = await forgotPasswordSalesRep({ email });
          if (response.status === 200 && response.data.flag) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        } else {
          const response = await forgotPasswordService({ email });
          if (response.status === 200 && response.data.flag) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
        }
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  useEffect(() => {
    if (location.pathname.startsWith('/sign-in')) {
      // fetchQuoteDetails();
      setisSalesRepLogin(true);
    } else {
      // fetchQuoteBidDetails();
      setisSalesRepLogin(false);
    }
  }, [location.pathname]);

  return (
    <div className="">
      <section className="py-10 lg:py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-md mx-auto">
            <div className="mb-6 lg:mb-10 text-left">
              <div className="mb-6  p-4">
                <h3 className="text-2xl font-bold ">
                  {isSalesRepLogin
                    ? 'Sales Representative/ Customer Representative Login'
                    : 'Login'}
                </h3>
              </div>
              <form action="" onSubmit={handleSubmit(onLogin)}>
                <div className="mb-3  p-4">
                  <label
                    className="w-full block text-black text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    Enter your email
                  </label>
                  <input
                    {...register('email')}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                    type="email"
                    id="email"
                  />
                  {errors.email && (
                    <span className="text-md text-red-700">
                      {errors.email.message}
                    </span>
                  )}
                </div>
                <div className="mb-6 p-4 bg-white rounded">
                  <label
                    className="w-full block text-black text-sm font-bold mb-2"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <input
                    {...register('password')}
                    className="leading-tight shadow appearance-none w-full text-xs border-gray-200 border-solid border bg-white py-3 px-2"
                    type="password"
                  />
                  {errors.password && (
                    <span className="text-md text-red-700">
                      {errors.password.message}
                    </span>
                  )}
                </div>
                <div className="text-left p-4 relative">
                  <button
                    // href="carrier-available-load.html"
                    type="submit"
                    disabled={isSubmitting}
                    className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-4 
                    bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700
                    text-sm font-bold  transition duration-200 text-left"
                  >
                    {isSalesRepLogin
                      ? 'Sales Representative/ Customer Representative Login'
                      : 'Login'}
                    <svg
                      className="w-10 h-10 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 mr-4 mt-6 font-bold"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </button>
                </div>
              </form>
              <div className="text-left p-4">
                <button
                  type="button"
                  className="order-1 xl:order-0 mr-12 inline-block pl-4 mb-2 w-full py-2 bg-transparent
                   bg-white text-navy-500 hover:bg-navy-50 hover:text-navy-500 active:text-navy-600 
                   border border-navy-500 hover:border-navy-600
                    text-sm font-bold transition duration-200 text-center"
                  onClick={toggleModal}
                >
                  Forgot Password?
                </button>
                {showModal && (
                  <ForgetPassword
                    closeModal={toggleModal}
                    sendEmail={onSubmitEmail}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginPage;

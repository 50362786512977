import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import {
  createCustomerRepService,
  customerRepProfileService,
  updateCustomerRepService
} from '../../services/customerRep.service';

import { ReactComponent as LeftArrowIcon } from '../../assets/images/left_arrow.svg';

const CustomerRepAddPage = () => {
  const navigate = useNavigate();
  const { userId } = useParams();

  const [isEditMode, setEditMode] = useState(false);

  const validationSchema = yup.object({
    person: yup.string().required('CustomerRep name is required'),
    last_name: yup.string().required('CustomerRep last name is required'),
    email: yup
      .string()
      .required('Email is required!')
      .email('Please enter a valid email.')
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const onBackArrowClick = () => {
    navigate(-1);
  };

  const onSubmitForm = async (data) => {
    try {
      if (isValid) {
        if (isEditMode) {
          const response = await updateCustomerRepService(userId, data);
          if (response.status === 200) {
            toast.success('Customer Representative Updated successfully!');
            navigate(-1);
          }
        } else {
          const response = await createCustomerRepService(data);

          if (response.status === 201) {
            toast.success('Customer Representative Created successfully!');
            navigate(-1);
          }
        }
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };
  const changeMode = () => setEditMode(true);

  async function fetchDetails() {
    try {
      const response = await customerRepProfileService(userId);

      const user = response.data;
      changeMode();
      reset({
        person: user.person,
        last_name: user.last_name,
        email: user.email
      });
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }
  useEffect(() => {
    if (userId) {
      fetchDetails();
    }
  }, []);

  return (
    <section>
      <div className="py-20 bg-gray-50 radius-for-skewed">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap -mx-4">
            <div className="mb-8 w-full px-3">
              <div className="mb-6 relative text-navy-500">
                <div
                  tabIndex="-1"
                  role="button"
                  onKeyPress={() => {}}
                  onClick={onBackArrowClick}
                  className="flex items-center"
                >
                  <LeftArrowIcon className="w-6 h-6 left-0 flex items-center " />
                  <p className="ml-4">Back to the list</p>
                </div>
                <h3 className="text-3xl font-bold text-left mb-4">
                  Customer Representative Details
                </h3>
              </div>

              <form onSubmit={handleSubmit(onSubmitForm)}>
                <div className="w-full md:w-1/2 px-3 mb-6 pb-6 md:pr-12">
                  <div className="w-full md:w-9/12">
                    <label className="block tracking-wide mb-2">
                      First Name
                    </label>
                    <input
                      {...register('person')}
                      className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none 
                            disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="text"
                    />
                    {errors.name && (
                      <span className="text-md text-red-700">
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                  <div className="w-full md:w-9/12">
                    <label className="block tracking-wide mb-2">
                      Last Name
                    </label>
                    <input
                      {...register('last_name')}
                      className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none 
                            disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="text"
                    />
                    {errors.name && (
                      <span className="text-md text-red-700">
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                  <div className="w-full md:w-9/12">
                    <label className="block tracking-wide mb-2">Email</label>
                    <input
                      {...register('email')}
                      className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none 
                            disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none"
                      type="email"
                      disabled={isEditMode}
                    />
                    {errors.email && (
                      <span className="text-md text-red-700">
                        {errors.email.message}
                      </span>
                    )}
                  </div>

                  <div className="text-left w-full md:w-9/12 pt-4">
                    <button
                      type="submit"
                      className="mr-12 inline-block pl-4 mb-2 w-full py-4 bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                    hover:border-navy-600 active:border-navy-700 text-xl font-bold transition duration-75 text-left relative"
                    >
                      {isEditMode ? 'Update' : 'Add'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomerRepAddPage;

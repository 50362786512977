import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as fileDownload from 'js-file-download';
import { generateBOLService } from '../../services/quote.service';
import { ReactComponent as RightArrow } from '../../assets/images/right_arrow.svg';

const BOLButton = ({ quoteId }) => {
  async function generateBOL() {
    try {
      const response = await generateBOLService({ quoteId });
      fileDownload(response.data, `${quoteId}.pdf`);
    } catch (error) {
      console.log({ error });
      toast.error('Something went wrong!');
    }
  }
  return (
    <button
      type="button"
      onClick={generateBOL}
      className="flex flex-row justify-between items-center py-3 px-4 w-full
                        bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                        hover:border-navy-600 active:border-navy-700
                          text-sm font-bold transition duration-200 text-left"
    >
      Generate BOL
      <RightArrow className="w-5 h-5 font-bold" />
    </button>
  );
};

export default BOLButton;

BOLButton.propTypes = {
  quoteId: PropTypes.string.isRequired
};

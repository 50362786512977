import React, { useState, useEffect, useRef, useMemo } from 'react';
import { AutoComplete, ConfigProvider } from 'antd';
import { debounce } from 'lodash';
import toast from 'react-hot-toast';
import { IconContext } from 'react-icons';
import { IoMdSearch, IoIosCloseCircle } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import SuggestionLabel from '../SuggestionLabel';
import '../../styles/custom.css';
import { getSummaryPath } from '../../utils/quote.util';
import { searchQuotes } from '../../services/quote.service';
import { useAuthContext } from '../../contexts/auth.context';

const GlobalSearchBar = ({
  isMobile,
  showInputInMobile,
  setShowInputInMobile
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [autoCompleteWidth, setAutoCompleteWidth] = useState();
  const [allQuotes, setAllQuotes] = useState([]);

  const autoRef = useRef();
  const navigate = useNavigate();
  const { authUser } = useAuthContext();

  const fetchAvailableLoads = async () => {
    try {
      const response = await searchQuotes(
        {
          internalTrackingNumber: searchValue
        },
        authUser
      );
      if (response.data.flag) {
        // Creating a new suggestions array based on the response
        const newSuggestions = response.data.data.map((quote) => {
          const { id, internalTrackingNumber } = quote;
          return {
            value: internalTrackingNumber,
            label: <SuggestionLabel quote={quote} key={id} />
          };
        });

        setAllQuotes(response?.data?.data);
        setSuggestions(newSuggestions);
      }
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const debouncedFetch = debounce(fetchAvailableLoads, 300);

  useEffect(() => {
    const isValidNumber = /^[0-9]+$/.test(searchValue);

    if (searchValue.length >= 3 && isValidNumber) {
      debouncedFetch();
    }
    return () => debouncedFetch.cancel();
  }, [searchValue]);

  useEffect(() => {
    if (autoRef.current) {
      setAutoCompleteWidth(autoRef.current.clientWidth);
    }
  }, [windowWidth]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleOnChange = (data) => {
    setSearchValue(data);
  };

  const handleClear = (e) => {
    if (isMobile) {
      if (!searchValue) {
        setShowInputInMobile(!showInputInMobile);
      } else {
        setSearchValue('');
        setSearchValue('');
        setSuggestions([]);
      }
      return;
    }

    if (searchValue) {
      setSearchValue('');
      setSuggestions([]);
    }
    e.stopPropagation();
  };

  const onSelect = (loadNumber) => {
    if (isMobile && showInputInMobile) {
      setShowInputInMobile(false);
    }
    const selectedQuote = allQuotes.find(
      (item) => item?.internalTrackingNumber === loadNumber
    );
    const summaryPath = getSummaryPath(selectedQuote);
    navigate(summaryPath);
    setSearchValue('');
    setSuggestions([]);
  };

  const onDropDownChange = (open) => {
    if (open) {
      setAutoCompleteWidth(autoRef.current.clientWidth);
    }
  };

  useEffect(() => {
    if (isMobile && !showInputInMobile && searchValue) {
      setSearchValue('');
    }
  }, [isMobile, showInputInMobile, searchValue]);

  const handleOverlayClick = (e) => {
    // Check if the click target is not the input element
    if (!e.target.matches('.container')) {
      setShowInputInMobile(false);
    }
  };

  const iconContextValue = useMemo(() => {
    return {
      color: searchValue ? '#1d4690' : 'black',
      size: '1.4em',
      className: 'cursor-pointer'
    };
  }, [searchValue]);

  return (
    <div className="w-full h-full flex items-center justify-center relative">
      {isMobile && showInputInMobile && (
        <div
          className="fixed inset-0 bg-gray-600 opacity-50 z-40"
          onClick={handleOverlayClick}
          role="presentation"
        />
      )}
      <div
        className={`container flex flex-row ${
          isMobile && !showInputInMobile ? 'justify-end' : 'justify-center'
        } `}
        ref={autoRef}
      >
        {(!isMobile || showInputInMobile) && (
          <ConfigProvider
            theme={{
              token: {
                motion: false,
                controlHeight: 44
              },
              components: {
                Select: {
                  optionActiveBg: '#DEEBFF',
                  optionFontSize: 13
                }
              }
            }}
          >
            <AutoComplete
              variant="borderless"
              value={searchValue}
              onSelect={onSelect}
              options={suggestions}
              placeholder="Enter load#"
              defaultActiveFirstOption
              onChange={handleOnChange}
              className={`bg-white ${isMobile && 'z-50'} ${
                isMobile ? 'w-full' : 'w-48'
              }`}
              popupMatchSelectWidth={
                isMobile && showInputInMobile ? autoCompleteWidth || true : 500
              }
              popupClassName="autocomplete-popup rounded-none p-2 ml-4"
              onDropdownVisibleChange={onDropDownChange}
            />
          </ConfigProvider>
        )}
        <IconContext.Provider value={iconContextValue}>
          <button
            className={`bg-white p-2 ${isMobile && 'z-50'}`}
            type="button"
            onClick={handleClear}
          >
            {searchValue ? <IoIosCloseCircle /> : <IoMdSearch />}
          </button>
        </IconContext.Provider>
      </div>
    </div>
  );
};

GlobalSearchBar.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  showInputInMobile: PropTypes.bool.isRequired,
  setShowInputInMobile: PropTypes.func.isRequired
};

export default GlobalSearchBar;

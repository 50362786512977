import API from './api.services';
import API_ENDPOINTS from '../constants/api-endpoints.constants';

const createIntentForPaymentService = (quoteId, serviceType) => {
  return API.post(
    API_ENDPOINTS.PAYMENT.IntentForPaymet,
    { quoteId, service_type: serviceType },
    {
      params: {
        token: localStorage.getItem('token')
      }
    }
  );
};

const createPaymentIntentService = (bidId) => {
  return API.post(
    API_ENDPOINTS.PAYMENT.CreatePaymentIntent,
    { bidId },
    {
      params: {
        token: localStorage.getItem('token')
      }
    }
  );
};

export { createIntentForPaymentService, createPaymentIntentService };

import API from './api.services';
import API_ENDPOINTS from '../constants/api-endpoints.constants';

const userLogin = (body) => {
  return API.post(API_ENDPOINTS.USERS.Login, body, {
    params: {
      platform: 'web'
    }
  });
};

const salesRepLogin = (body) => {
  return API.post(API_ENDPOINTS.SALES_REP.Login, body, {});
};

const userLogout = (body) => {
  return API.post(API_ENDPOINTS.USERS.Logout, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const salesRepLogout = (body) => {
  return API.post(API_ENDPOINTS.SALES_REP.Logout, body, {
    params: {
      token: localStorage.getItem('token')
    }
  });
};

const forgotPasswordService = (body) => {
  return API.post(API_ENDPOINTS.USERS.ForgotPassword, body, {});
};

export {
  userLogin,
  userLogout,
  forgotPasswordService,
  salesRepLogin,
  salesRepLogout
};

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GiCargoCrate } from 'react-icons/gi';
import { calculateDensity } from '../../../utils/quote.util';
import { GlobalFormContext } from '../../../contexts/QuickQuoteContext';

const QuickQuoteCargoTile = ({ cargo, handleEditCargo }) => {
  const { cargos, setCargos } = useContext(GlobalFormContext);

  const [computedDensity, setComputedDensity] = useState(null);

  const handleDelete = () => {
    const updatedCargos = cargos.filter((item) => item.id !== cargo.id);
    setCargos(updatedCargos);
  };

  useEffect(() => {
    setComputedDensity(calculateDensity(cargo));
  }, [cargo]);
  return (
    <div className="flex flex-row w-full h-14 px-6 py-2 text-white bg-white border border-navy-300 justify-around rounded-2xl">
      <div className="flex flex-row gap-10 text-navy-500 items-center font-bold">
        <GiCargoCrate className=" h-7 w-7" />

        <span className="text-base">{`1-${cargo.cargoType.label}`}</span>
        <span className="text-base">{`${cargo.length} in x ${cargo.width} in x ${cargo.height} in x ${cargo.weight} lbs`}</span>
        {cargo.length && cargo.width && cargo.height && (
          <>
            <span className="text-base">
              {`Density: ${computedDensity?.density} lb/ft`}
              <sup>3</sup>
            </span>
            <span className="text-base">
              {`Cubic FT: ${computedDensity?.cubitFeet} ft`}
              <sup>3</sup>
            </span>
          </>
        )}
      </div>
      <div className="flex flex-row gap-6 items-center">
        <button
          type="button"
          className="bg-navy-500 px-4 h-8 rounded-lg"
          onClick={() => handleEditCargo(cargo)}
        >
          Edit
        </button>
        <button
          type="button"
          className="bg-navy-500 px-4 h-8 rounded-lg"
          onClick={handleDelete}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default QuickQuoteCargoTile;

QuickQuoteCargoTile.propTypes = {
  cargo: PropTypes.object.isRequired,
  handleEditCargo: PropTypes.func.isRequired
};

import React, { memo } from 'react';

const QuoteIcon = memo(() => {
  return (
    <span className="mb-4 lg:mb-0 mr-6 inline-block p-3 md:p-5  bg-navy-50 border border-navy-50">
      <svg
        className="h-8 w-8 md:w-12 md:h-12 text-navy-500"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
        />
      </svg>
    </span>
  );
});

export default QuoteIcon;

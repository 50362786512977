import React, { memo } from 'react';

const DummyImage = memo(() => {
  return (
    <span className="mb-4 lg:mb-0 mr-6 inline-block p-3 md:p-5  bg-navy-50 border border-navy-50">
      <svg
        className="h-8 w-8 md:w-12 md:h-12"
        xmlns="http://www.w3.org/2000/svg"
        width="27"
        height="27"
        viewBox="0 0 27 27"
      >
        <path
          id="Icon_material-image"
          data-name="Icon material-image"
          d="M31.5,28.5V7.5a3.009,3.009,0,0,0-3-3H7.5a3.009,3.009,0,0,0-3,3v21a3.009,3.009,0,0,0,3,3h21A3.009,3.009,0,0,0,31.5,28.5ZM12.75,20.25l3.75,4.515L21.75,18l6.75,9H7.5Z"
          transform="translate(-4.5 -4.5)"
          fill="#1d4690"
        />
      </svg>
    </span>
  );
});

export default DummyImage;
